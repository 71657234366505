<script setup lang="ts">
import { useUsersStore } from "@/stores/users-store";
import { useCabinetsStore } from "@/stores/cabinets-store";
import { defineProps, reactive } from "vue";
import type { CabinetUI } from "@/stores/types/cabinets-types";
import { useToast } from "vue-toastification";

const props = defineProps<{
  idTranslator: string;
  close: () => void;
}>();

const toast = useToast();

const usersStore = useUsersStore();

const cabinetsStore = useCabinetsStore();

const assignCabinetState = reactive<{
  state: boolean;
  loading: boolean;
  cabinets: CabinetUI[];
  _cabinets: CabinetUI[];
}>({
  state: false,
  loading: false,
  _cabinets: [],
  get cabinets(): CabinetUI[] {
    return this._cabinets;
  },
  set cabinets(data: CabinetUI[]) {
    this._cabinets = data;
  },
});

const openModal = (): void => {
  assignCabinetState.state = true;
  assignCabinetState.cabinets = mapCabinets();
};

const hideModal = (): void => {
  assignCabinetState.state = false;
  assignCabinetState.cabinets = [];
  props.close();
};

const onCabinetSelect = (index: number): void => {
  assignCabinetState.cabinets[index].selected =
    !assignCabinetState.cabinets[index].selected;
};

const isAssignBtnDisabled = (): boolean => {
  return (
    !assignCabinetState.cabinets.some((cabinet) => !!cabinet.selected) ||
    assignCabinetState.loading
  );
};

const mapCabinets = (): CabinetUI[] => {
  return cabinetsStore.allAvailable(props.idTranslator).map((cabinet) => {
    const tuProfiles = cabinet.idTUs
      .map((id) => {
        return usersStore.getOperatorByOriginalId(id);
      })
      .filter((tu) => !!tu);
    return {
      ...cabinet,
      tuProfiles,
      selected: false,
    } as CabinetUI;
  });
};

const getSelectedCabinets = (): CabinetUI[] => {
  return assignCabinetState.cabinets.filter((cabinet) => !!cabinet.selected);
};

const assignCabinet = async (): Promise<void> => {
  assignCabinetState.loading = true;
  try {
    const cabinets = getSelectedCabinets();
    for (const cabinet of cabinets) {
      await cabinetsStore.assignCabinet(props.idTranslator, cabinet._id);
    }
    toast.success("Cabinet was assigned");
  } catch (e) {
    toast.error("Something went wrong during assigning cabinet");
  } finally {
    assignCabinetState.loading = false;
    hideModal();
  }
};

openModal();
</script>
<template>
  <v-card title="ASSIGN CABINET" class="assign-cabinet__content">
    <v-card-content>
      <div class="assign-cabinet">
        <template v-if="assignCabinetState.cabinets.length">
          <div
            :class="{
              'assign-cabinet__item': true,
              'assign-cabinet__item--selected': cabinet.selected,
            }"
            v-for="(cabinet, index) in assignCabinetState.cabinets"
            :key="cabinet._id"
          >
            <div class="assign-cabinet__action-row">
              <div style="font-weight: bold">{{ cabinet.name }}</div>
              <div @click="onCabinetSelect(index)">
                <v-checkbox
                  color="primary"
                  class="assign-cabinet__check-box"
                  hide-details="auto"
                  v-model="cabinet.selected"
                ></v-checkbox>
              </div>
            </div>
            <v-divider />
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-title>
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      column-gap: var(--indent-m);
                    "
                  >
                    <h4>{{ cabinet.idTUs.length }} Profiles</h4>
                  </div>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  <table
                    class="assign-cabinet__table"
                    v-if="cabinet.tuProfiles.length"
                  >
                    <tr>
                      <th>ID</th>
                      <th>Avatar</th>
                      <th>Name</th>
                      <th>Age</th>
                    </tr>
                    <tr
                      class="row"
                      v-for="tu in cabinet.tuProfiles"
                      :key="tu.idTU"
                    >
                      <td>{{ tu.idTU }}</td>
                      <td>
                        <div class="avatar-wrapper">
                          <v-avatar>
                            <img :alt="tu?.name" :src="tu?.avatar" />
                          </v-avatar>
                        </div>
                      </td>
                      <td>{{ tu?.name }}</td>
                      <td>{{ tu?.age }}</td>
                    </tr>
                  </table>
                  <template v-else-if="!cabinet.idTUs.length">
                    <div class="empty-container__wrapper">
                      <div class="empty-container__item">
                        No available profiles found for this cabinet
                      </div>
                    </div>
                  </template>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </template>
        <template v-if="!assignCabinetState.cabinets.length">
          <div class="empty-container__wrapper">
            <div class="empty-container__item">No available cabinets found</div>
          </div>
        </template>
      </div>
    </v-card-content>
    <v-card-actions class="assign-cabinet__btn-row">
      <v-btn @click="hideModal()">Cancel</v-btn>
      <v-btn
        color="primary"
        @click="assignCabinet"
        :disabled="isAssignBtnDisabled()"
      >
        Assign Cabinet
        <v-progress-circular
          v-if="assignCabinetState.loading"
          indeterminate
        ></v-progress-circular>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<style scoped lang="scss">
.assign-cabinet {
  padding: 0 20px;
  overflow-y: auto;
  max-height: 80vh;

  &__content {
    margin-bottom: 52px;
  }

  &__check-box {
    cursor: pointer;
  }

  &__table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      text-align: left;
      min-height: 48px;
      font-size: 0.875rem;
      padding: 5px 10px;
      font-weight: normal;
      height: 48px;
    }

    tr.row {
      border-bottom: thin solid
        rgba(var(--v-border-color), var(--v-border-opacity));
    }

    tr.row:hover {
      background: rgba(var(--v-border-color), var(--v-hover-opacity));
    }
  }

  &__item {
    margin-bottom: 10px;
    display: flex;
    border-radius: 3px;
    background: #fff;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #e8eaf5;

    &--selected {
      border: 2px solid #5c81dc;
    }
  }

  &__action-row {
    padding: 0 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__btn-row {
    display: flex;
    justify-content: end;
    z-index: 9999;
    bottom: 0;
    background: #ffffff;
    width: 100%;
  }

  &__check-box {
    font-size: 20px;
  }

  .avatar-wrapper {
    display: table;
    position: relative;
    .external-icon {
      position: absolute;
      bottom: -5px;
      right: -10px;
    }
  }
}
</style>
