<script lang="ts" setup>
import { defineProps, reactive } from "vue";
import type { Cabinet } from "@/stores/types/cabinets-types";
import CabinetForm from "@/components/Cabinet/CabinetForm.vue";
import AddOperators from "@/components/Operators/AddOperators.vue";
import { useEditCabinet } from "@/components/Cabinet/edit-cabinet";
import { useDeleteCabinet } from "@/components/Cabinet/delete-cabinet";
import { useUsersStore } from "@/stores/users-store";
import type { Translator } from "@/stores/types/users-types";
import type { UseModalReturnType } from "vue-final-modal";
import DeleteUnit from "@/components/Operators/DeleteUnit.vue";
import ModalService from "@/core/services/ModalService";
import { useUserProfileStore } from "@/stores/user-profile-store";

const { openEditModal, hideEditModal, editCabinet } = useEditCabinet();

const { openDeleteModal, hideDeleteModal, deleteCabinet } = useDeleteCabinet();

const usersStore = useUsersStore();

const isAdminRole = useUserProfileStore().isAdminRole;

const props = defineProps<{
  cabinet: Cabinet;
}>();

const state = reactive<{
  cabinet: Cabinet;
  operators: Translator[];
}>({
  get cabinet(): Cabinet {
    return props.cabinet;
  },
  get operators(): Translator[] {
    return usersStore.getTranslatorsByIds(props.cabinet.idOperators);
  },
});

const getCabinetOwnersName = (): string => {
  const { operators } = state;

  if (operators.length) {
    return operators
      .map((operator) => `${operator.firstName} ${operator.lastName}`)
      .filter((name) => !!name)
      .join(",");
  }
  return "--";
};

const getCabinetHeadName = (): string => {
  const { idCreator } = state.cabinet;

  return usersStore.getManagerById(idCreator).label;
};

const cabinetAction = () => {
  return [
    { label: "Actions", action: "action" },
    { label: "Add profiles", action: "add" },
    { label: "Edit cabinet", action: "edit" },
    { label: "Delete cabinet", action: "delete" },
  ];
};

let editCabinetModal: UseModalReturnType<any>;

function openEditCabinetModal() {
  openEditModal(state.cabinet._id);
  editCabinetModal = ModalService.init(CabinetForm, {
    name: state.cabinet.name,
    async submit(name: string) {
      return editCabinet(name).then(() => closeEditCabinetModal());
    },
    cancel() {
      close();
      closeEditCabinetModal();
    },
  });
  editCabinetModal.open();
}

function closeEditCabinetModal() {
  hideEditModal();
  editCabinetModal.close();
}

const deleteCabinetModal = ModalService.init(DeleteUnit, {
  style: "width: 600px",
  deleteStateThing: {
    type: "cabinet",
    name: state.cabinet.name,
    loading: false,
  },
  async deleteUnit() {
    await deleteCabinet().then(() => closeDeleteCabinetModal());
  },
  hideDeleteModal() {
    closeDeleteCabinetModal();
  },
});

function openDeleteCabinetModal(id: string) {
  openDeleteModal(id);
  deleteCabinetModal.open();
}

function closeDeleteCabinetModal() {
  hideDeleteModal();
  deleteCabinetModal.close();
}

const assignOperatorsModal = ModalService.init(AddOperators, {
  style: "width: 700px",
  cabinetId: state.cabinet._id,
  close() {
    closeAssignOperatorsModal();
  },
});

function closeAssignOperatorsModal() {
  assignOperatorsModal.close();
}

function selectAction(event: string) {
  switch (event) {
    case "add":
      assignOperatorsModal.open();
      return;
    case "edit":
      openEditCabinetModal();
      return;
    case "delete":
      openDeleteCabinetModal(state.cabinet._id);
      return;
    default:
      return;
  }
}
</script>
<template>
  <div class="cabinet">
    <div>
      <div class="cabinet__title">
        <h3 class="cabinet__name text-h6">Cabinet</h3>
        <span>{{ state?.cabinet.name }}</span>
      </div>
      <div style="margin-top: 5px" v-if="isAdminRole">
        <span class="cabinet__info-label"> Head: </span>
        {{ getCabinetHeadName() }}
      </div>
      <div style="margin-top: 5px">
        <span class="cabinet__info-label"> Owners: </span>
        {{ getCabinetOwnersName() }}
      </div>
    </div>
    <div class="cabinet__info d-flex">
      <div>
        <span class="cabinet__info-label">Profiles:</span>
        {{ state?.cabinet.idTUs.length }}
      </div>
    </div>
    <div class="cabinet__actions">
      <v-select
        v-model="cabinetAction()[0]"
        item-title="label"
        item-value="action"
        :items="cabinetAction()"
        variant="outlined"
        density="compact"
        hide-details
        hide-selected
        @update:model-value="selectAction($event)"
      >
      </v-select>
    </div>
  </div>
</template>

<style scoped lang="scss">
.cabinet {
  display: flex;
  align-items: center;
  column-gap: var(--indent-m);

  &__title {
    display: flex;
    align-items: baseline;

    h3 {
      margin-right: 10px;
    }
  }

  &__info {
    margin-inline-start: auto;
    display: flex;
    column-gap: var(--indent-m);
  }

  &__info-label {
    color: #b3b3b3;
  }

  &__actions {
    margin-inline-start: var(--indent-xl);
    display: flex;
    column-gap: var(--indent-m);
  }
}
</style>
