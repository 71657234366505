import { Http } from "@/core/api";
import type { Cabinet } from "@/stores/types/cabinets-types";

class ApiCabinet {
  getCabinets(): Promise<Cabinet[]> {
    return Http.get<Cabinet[]>("/cabinet/all");
  }

  createCabinet(name: string): Promise<Cabinet> {
    return Http.post<Cabinet>("/cabinet/create", { name });
  }

  editCabinet(name: string, id: string): Promise<boolean> {
    return Http.post<boolean>("/cabinet/edit", { name, id });
  }

  deleteCabinet(id: string): Promise<boolean> {
    return Http.post<boolean>("/cabinet/remove", { id });
  }

  assignTU(idTU: number, pass: string, id: string): Promise<boolean | string> {
    const data = {
      tu: { idTU, ...(pass && { pass }) },
      id,
    };
    return Http.post<boolean>("/cabinet/tu/assign", data);
  }

  unAssignTU(idTU: number, id: string): Promise<boolean> {
    return Http.post<boolean>("/cabinet/tu/remove", { idTU, id });
  }

  assignCabinet(idOperator: string, id: string): Promise<boolean> {
    return Http.post<boolean>("/cabinet/operator/assign", { idOperator, id });
  }

  unAssignCabinet(idOperator: string, id: string): Promise<boolean> {
    return Http.post<boolean>("/cabinet/operator/remove", { idOperator, id });
  }
}

export default new ApiCabinet();
