import { reactive } from "vue";
import type { WorkShiftCrossing } from "@/stores/types/work-shift-types";

export function useCrossingOperators() {
  const crossingOperatorsState = reactive<{
    state: boolean;
    crossingOperators: WorkShiftCrossing[];
  }>({
    state: false,
    crossingOperators: [],
  });

  const hideCrossingOperatorsModal = (): void => {
    crossingOperatorsState.state = false;
    crossingOperatorsState.crossingOperators = [];
  };

  const openCrossingOperatorsModal = (
    crossingOperators: WorkShiftCrossing[]
  ): void => {
    crossingOperatorsState.state = true;
    crossingOperatorsState.crossingOperators = crossingOperators;
  };

  return {
    crossingOperatorsState,
    hideCrossingOperatorsModal,
    openCrossingOperatorsModal,
  };
}
