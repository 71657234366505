<script setup lang="ts">
import type { WorkShiftCrossing } from "@/stores/types/work-shift-types";
import { reactive } from "vue";
import { useUsersStore } from "@/stores/users-store";

const props = defineProps<{
  data: WorkShiftCrossing[];
  isAssign: boolean;
  cancel: () => void;
}>();

const usersStore = useUsersStore();

const state = reactive<{
  crossingOperators: { operatorName: string; idTU: number }[];
}>({
  get crossingOperators(): { operatorName: string; idTU: number }[] {
    return props.data.flatMap(({ idCrossingOperator, idTUs }) => {
      const { firstName, lastName } =
        usersStore.getTranslatorById(idCrossingOperator);

      return idTUs.map((idTU) => ({
        operatorName: `${firstName} ${lastName}`,
        idTU,
      }));
    });
  },
});

function getModalTitle(): string {
  const action = props.isAssign ? "Assign" : "Edit";

  return `${action} Work Shift Error Message`;
}

function getModalFooterText(): string {
  if (props.isAssign) {
    return "Please choose another work shift or edit the current one.";
  }
  return "Please select other working hours or replace this working shift with another one.";
}
</script>
<template>
  <v-card>
    <v-card-title class="crossing-operators">{{
      getModalTitle()
    }}</v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col>
            These working hours have already been assigned to the next
            profile(s) and Operator(s):
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <table class="crossing-operators__table" v-if="props.data.length">
              <tr>
                <th>Profile</th>
                <th>Operator</th>
              </tr>
              <tr
                class="row"
                v-for="(item, index) in state.crossingOperators"
                :key="index"
              >
                <td>{{ item.idTU }}</td>
                <td>
                  {{ item.operatorName }}
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            {{ getModalFooterText() }}
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions class="crossing-operators__actions">
      <v-btn @click="props.cancel()">OK</v-btn>
    </v-card-actions>
  </v-card>
</template>

<style lang="scss" scoped>
.v-card-title.crossing-operators {
  text-align: center;
  background: rgba(var(--v-theme-error));
  color: #ffffff;
}

.crossing-operators {
  &__actions {
    display: flex;
    justify-content: end;
    width: 100%;
  }

  &__table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      text-align: left;
      min-height: 48px;
      font-size: 0.875rem;
      padding: 5px 10px;
      font-weight: normal;
      height: 48px;
    }

    tr.row {
      border-bottom: thin solid
        rgba(var(--v-border-color), var(--v-border-opacity));
    }

    tr.row:hover {
      background: rgba(var(--v-border-color), var(--v-hover-opacity));
    }
  }
}
</style>
