import { Http } from "@/core/api";
import type { RefreshToken } from "@/core/RefreshTokenService";
import type { LoginData } from "@/core/types/auth";
import type { BaseCredentials } from "@/core";

class ApiAuth {
  checkAuth(): Promise<any> {
    return Http.get("/auth/check");
  }

  getNewToken(): Promise<RefreshToken> {
    return Http.post<RefreshToken>("/auth/refresh-token");
  }

  authByCredentialsAdmin(credentials: BaseCredentials): Promise<LoginData> {
    return Http.post<LoginData>("/auth/login", credentials);
  }

  authByCredentialsManager(credentials: BaseCredentials): Promise<LoginData> {
    return Http.post<LoginData>("/manager/login", credentials);
  }

  authByToken(token: string): Promise<LoginData> {
    return Http.post<LoginData>("/auth/by-token", { token });
  }
}

export default new ApiAuth();
