import { defineStore } from "pinia";
import { localStorageGet, setToLocalStorage } from "@/core";
import type { LoginData } from "@/core";
import { ApiRefCode } from "@/shared/api";

type UserProfileStore = {
  email: string;
  referralCode: number;
  userId: string;
  role: string;
  idAgency: number;
  firstName: string;
  lastName: string;
};

export const useUserProfileStore = defineStore({
  id: "user-profile",
  state: () => {
    const profile = localStorageGet<UserProfileStore>({
      key: "user-profile",
      defaultValue: {
        email: "",
        referralCode: 0,
        userId: "",
        role: "",
        idAgency: 0,
        firstName: "",
        lastName: "",
      },
    });

    return { ...profile };
  },
  getters: {
    isAdminRole: (state) => state.role === "admin",
  },
  actions: {
    setProfile(data: LoginData): void {
      this.email = data.info.email;
      this.referralCode = data.info.referralCode;
      this.userId = data.info.userId;
      this.role = data.role;
      this.idAgency = data.info.data.idAgency;
      this.firstName = data.info.firstName;
      this.lastName = data.info.lastName;

      setToLocalStorage({ key: "user-profile", value: this.$state });
    },
    async checkRefCode(): Promise<void> {
      await ApiRefCode.check(this.referralCode as number);
    },
    async generateRefCode(): Promise<void> {
      const { referralCode } = await ApiRefCode.create(this.userId);
      this.referralCode = referralCode;

      setToLocalStorage({ key: "user-profile", value: this.$state });
    },
  },
});
