<script setup lang="ts">
import { defineProps, ref } from "vue";

const props = defineProps<{
  buttonLabel: string;
  text: string;
  close: () => void;
  submit: () => void;
}>();

const loading = ref(false);

function closeModal() {
  props.close();
}

function agree() {
  loading.value = true;
  props.submit();
}
</script>
<script lang="ts">
export default {};
</script>

<template>
  <v-card title="Are you sure?" :text="text">
    <v-card-actions style="justify-content: flex-end">
      <v-btn @click="closeModal()">Cancel</v-btn>
      <v-btn @click="agree()" color="error" :disabled="loading">
        <v-progress-circular v-if="loading" indeterminate></v-progress-circular>
        <span v-else>{{ props.buttonLabel }}</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped lang="scss"></style>
