import { reactive } from "vue";
import { useToast } from "vue-toastification";
import { useWorkShiftStore } from "@/stores/work-shift-store";

export function useUnAssignWorkShift() {
  const toast = useToast();

  const unAssignWorkShiftState = reactive<{
    state: boolean;
    workShiftId: string | null;
    operatorId: string | null;
    loading: boolean;
  }>({
    state: false,
    workShiftId: null,
    operatorId: null,
    loading: false,
  });

  const hideUnAssignWorkShiftModal = (): void => {
    unAssignWorkShiftState.workShiftId = null;
    unAssignWorkShiftState.operatorId = null;
    unAssignWorkShiftState.state = false;
  };

  const openUnAssignWorkShiftModal = (
    workShiftId: string,
    operatorId: string
  ): void => {
    unAssignWorkShiftState.workShiftId = workShiftId;
    unAssignWorkShiftState.operatorId = operatorId;
    unAssignWorkShiftState.state = true;
  };

  const unAssignWorkShift = async (): Promise<void> => {
    unAssignWorkShiftState.loading = true;
    try {
      const { workShiftId, operatorId } = unAssignWorkShiftState;
      await useWorkShiftStore().unAssignWorkShift(
        operatorId as string,
        workShiftId as string
      );
      toast.success("Work shift was unassigned");
    } catch (e) {
      toast.error("Something went wrong during unassigning work shift");
    } finally {
      hideUnAssignWorkShiftModal();
      unAssignWorkShiftState.loading = false;
    }
  };

  return {
    unAssignWorkShiftState,
    unAssignWorkShift,
    hideUnAssignWorkShiftModal,
    openUnAssignWorkShiftModal,
  };
}
