import { FRESHCHAT_CONFIGURATION } from "../constants/freshchat-options";

class SupportChatService {
  init(): void {
    const scriptElem = document.createElement("script");

    scriptElem.async = true;
    scriptElem.src = "https://chatterly-help.freshchat.com/js/widget.js";
    scriptElem.onload = this.initFreshChat.bind(this);
    document.head.appendChild(scriptElem);
  }

  initFreshChat(): void {
    const freshChatWidget = (window as any).fcWidget;

    try {
      const userEmail = JSON.parse(localStorage.getItem("login_data") || "")
        ?.info?.email;
      const restoreId = localStorage.getItem("freshchatId") || null;

      freshChatWidget.init({
        token: FRESHCHAT_CONFIGURATION.token,
        host: FRESHCHAT_CONFIGURATION.host,
        externalId: userEmail,
        restoredId: restoreId,
      });

      freshChatWidget.user.setProperties({
        email: userEmail,
      });

      freshChatWidget.user.get((resp) => {
        const status = resp && resp.status;

        if (status !== 200) {
          freshChatWidget.user.setProperties({
            email: userEmail,
          });
        }
      });

      freshChatWidget.on("user:created", (resp) => {
        const status = resp && resp.status;
        const data = resp && resp.data;

        if (status === 200) {
          if (data.restoreId) {
            localStorage.setItem("freshchatId", data.restoreId);
          }
        }
      });
    } catch (error) {
      freshChatWidget.init({
        token: FRESHCHAT_CONFIGURATION.token,
        host: FRESHCHAT_CONFIGURATION.host,
      });
    }
  }
}

export default new SupportChatService();
