<script setup lang="ts">
import PageHeader from "@/components/PageHeader.vue";
import type { UserRole } from "@/stores/types/users-types";
import AnalystService from "@/core/services/AnalystService";
import {
  ANALYTICS_EVENT_MODAL_ACTION,
  ANALYTICS_EVENT_ROLE_NAME,
} from "@/core/enums/amplitude-event.enum";
import ModalService from "@/core/services/ModalService";
import UserForm from "@/components/UserForm/UserForm.vue";
import type { UserFormState } from "@/components/UserForm/user-form-types";
import type { UseModalReturnType } from "vue-final-modal";
import RefCode from "@/components/RefCode/RefCode.vue";
import { useUsersStore } from "@/stores/users-store";
import { useUserProfileStore } from "@/stores/user-profile-store";
import PageContent from "@/components/PageContent.vue";
import OperatorsSearch from "@/components/Operators/OperatorsSearch.vue";
import ManagersListForAdmin from "@/components/ManagersListForAdmin.vue";

const usersStore = useUsersStore();

const userProfileStore = useUserProfileStore();

usersStore.index();

const refCodeModal = ModalService.init(RefCode, {
  refCode: userProfileStore.referralCode,
  submit() {
    generateRefCode();
  },
});
let userModal: UseModalReturnType<any>;
function openUserModal(role: UserRole): void {
  AnalystService.pushProfileModalEvent({
    modal:
      role === "manager"
        ? ANALYTICS_EVENT_ROLE_NAME.MANAGER
        : ANALYTICS_EVENT_ROLE_NAME.OPERATOR,
    action: ANALYTICS_EVENT_MODAL_ACTION.OPEN,
  });
  userModal = ModalService.init(UserForm, {
    role,
    cancelEdit() {
      cancelUserModal(role);
    },
    submit(data: UserFormState): Promise<void> {
      return createUser(data);
    },
    openRefCodeModal() {
      openRefCodeModal();
    },
  });
  userModal.open();
}
function cancelUserModal(role: UserRole): void {
  AnalystService.pushProfileModalEvent({
    modal:
      role === "manager"
        ? ANALYTICS_EVENT_ROLE_NAME.MANAGER
        : ANALYTICS_EVENT_ROLE_NAME.OPERATOR,
    action: ANALYTICS_EVENT_MODAL_ACTION.CANCEL,
  });
  userModal.close();
}

async function createUser(data: UserFormState): Promise<void> {
  await usersStore.createManager(data);
  await userModal.close();
}

function openRefCodeModal(): void {
  userModal.close();
  refCodeModal.open();
}

async function generateRefCode(): Promise<void> {
  await userProfileStore.generateRefCode();
  await refCodeModal.close();
}
</script>
<script lang="ts">
export default {};
</script>

<template>
  <page-header>
    <h1 class="text-h5">Managers</h1>
    <template #actions>
      <v-btn @click="openUserModal('manager')" color="primary"
        >Create Manager
      </v-btn>
    </template>
  </page-header>
  <page-content class="content">
    <operators-search></operators-search>
  </page-content>
  <page-content>
    <managers-list-for-admin
      :managers="usersStore.managersTree"
      :loading="usersStore.loading"
    >
    </managers-list-for-admin>
  </page-content>
</template>

<style scoped lang="scss">
.content {
  background-color: transparent;
  padding: 0;
  margin-top: 16px;
  min-height: auto;
}
</style>
