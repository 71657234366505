import { useUsersStore } from "@/stores/users-store";
import type { UnwrapRef } from "vue";
import { reactive } from "vue";
import { useToast } from "vue-toastification";
import type { Manager, Translator } from "@/stores/types/users-types";

export function useCreateRefCode<T extends Translator | Manager>() {
  const usersStore = useUsersStore();
  const toast = useToast();

  const createRefCodeState = reactive<{
    state: boolean;
    user?: T;
    loading: boolean;
  }>({
    state: false,
    user: undefined,
    loading: false,
  });

  const hideCreateRefCodeModal = (): void => {
    createRefCodeState.state = false;
    createRefCodeState.user = undefined;
  };

  const handleErrorNotification = () => {
    toast.error("Something went wrong");
  };
  const handleSuccessNotification = () => {
    toast.success(`Ref code was updated`);
  };

  return {
    createRefCodeState,
    hideCreateRefCodeModal,
    createRefCode: async (): Promise<void> => {
      createRefCodeState.loading = true;
      try {
        if (!createRefCodeState.user) {
          throw new Error("Manager is not defined");
        }
        await usersStore.updateReferralCode(createRefCodeState.user);
        handleSuccessNotification();
      } catch (error) {
        handleErrorNotification();
      }
      createRefCodeState.loading = false;
      hideCreateRefCodeModal();
    },
    openCreateRefCodeModalModal: (user?: T): void => {
      if (user) {
        createRefCodeState.user = user as UnwrapRef<T>;
        createRefCodeState.state = true;
      }
    },
  };
}
