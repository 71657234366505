import type { RouteRecordRaw } from "vue-router";
import Login from "@/pages/Login.vue";
import StructurePage from "@/pages/StructurePage.vue";
import OperatorsPage from "@/pages/OperatorsPage.vue";

export const SPA_ROUTES: RouteRecordRaw[] = [
  {
    path: "/",
    redirect: () => {
      return "structure";
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: () => {
      return "structure";
    },
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/structure",
    component: StructurePage,
  },
  {
    path: "/operators",
    component: OperatorsPage,
  },
];
