<script lang="ts" setup>
import type { DeleteState } from "@/components/Operators/types";
import type { Manager, Operator, Translator } from "@/stores/types/users-types";

const props = defineProps<{
  hideDeleteModal: CallableFunction;
  deleteUnit: CallableFunction;
  deleteStateUser?:
    | DeleteState<Operator>
    | DeleteState<Manager>
    | DeleteState<Translator>;
  deleteStateThing?: {
    type: string;
    name: string;
    loading: boolean;
  };
}>();

function getSubtitleLabelName(): string {
  if (props.deleteStateThing) {
    return props.deleteStateThing.name;
  }
  return (props.deleteStateUser?.user as Operator)?.name
    ? (props.deleteStateUser?.user as Operator)?.name
    : (props.deleteStateUser?.user as Translator | Manager)?.label;
}

function getTitleLabelName(): string {
  if (props.deleteStateThing) {
    return `this ${props.deleteStateThing.type}`;
  }
  if ((props.deleteStateUser?.user as Operator)?.name) {
    return "member";
  } else if ((props.deleteStateUser?.user as Manager)?.role === "manager") {
    return (props.deleteStateUser?.user as Manager)?.role;
  }
  return "operator";
}
</script>
<template>
  <v-card
    :title="`Do you want delete ${getTitleLabelName()}?`"
    :subtitle="`${getSubtitleLabelName()}  will be deleted`"
  >
    <v-card-actions style="justify-content: flex-end">
      <v-btn @click="hideDeleteModal()">Cancel</v-btn>
      <v-btn
        @click="deleteUnit()"
        color="error"
        :disabled="
          props.deleteStateUser?.loading ||
          props.deleteStateThing?.loading ||
          false
        "
        >Delete
        <v-progress-circular
          v-if="
            props.deleteStateUser?.loading || props.deleteStateThing?.loading
          "
          indeterminate
        ></v-progress-circular>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
