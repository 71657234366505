import Bugsnag, { type NotifiableError } from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import type { AxiosError } from "axios";

import type { LoginData } from "@/core/types";

class ErrorTrackingService {
  init() {
    Bugsnag.start({
      // @ts-expect-error CONST
      apiKey: BUGSNAG_API_KEY,
      plugins: [new BugsnagPluginVue()],
      // @ts-expect-error WEBPACK CONST
      appVersion: APP_VERSION,
      // @ts-expect-error WEBPACK CONST
      releaseStage: RELEASE_VERSION,
      onError: function (event) {
        event.errors[0].stacktrace = event.errors[0].stacktrace.map(function (
          frame
        ) {
          frame.file = frame.file.replace(
            /chrome-extension:/g,
            "chrome_extension:"
          );
          frame.file = frame.file.replace(/moz-extension:/g, "moz_extension:");
          frame.file = frame.file.replace(
            /safari-extension:/g,
            "safari_extension:"
          );
          frame.file = frame.file.replace(
            /safari-web-extension:/g,
            "safari_web_extension:"
          );
          return frame;
        });

        return (
          location.pathname.includes("users") &&
          !(
            event?.errors[0]?.errorMessage.endsWith("status code 401") ||
            event?.errors[0]?.errorMessage.endsWith("status code 400")
          )
        );
      },
    });
    this.setAgencyMetadata();
  }

  setAgencyMetadata() {
    try {
      Bugsnag.addMetadata("agency_info", {
        idAgency: (
          JSON.parse(localStorage.getItem("login_data") || "{}") as LoginData
        )?.info?.data?.idAgency,
      });
    } catch (error) {
      console.error("Error when setting bugsnag metadata: ", error);
    }
  }

  notify(data: AxiosError | string | Error) {
    let parsedData: NotifiableError | undefined;

    if ((data as AxiosError).isAxiosError) {
      const error = (data as AxiosError).toJSON();

      parsedData = new Error((error as { message: string }).message);
    }
    Bugsnag.notify(parsedData || data);
  }
}

export default new ErrorTrackingService();
