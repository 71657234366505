import { useUsersStore } from "@/stores/users-store";

export function externalOperatorFormRules() {
  const usersStore = useUsersStore();
  return {
    email: [
      (v?: string) => !!v || "Email is required",
      (v: string) => /.+@.+/.test(v) || "Email is not valid",
      (v: string) => {
        return (
          !usersStore.all.find((user) => user.email === v.trim()) ||
          "This email is already used"
        );
      },
    ],
    password: [
      (v?: string) => !!v || "Password is required",
      (v: string) =>
        v.length >= 6 || "Password length must be more than 6 chars",
    ],
  };
}
