<script setup lang="ts">
import { useUnAssignWorkShift } from "@/components/WorkShift/unassign-work-shift";
import { defineProps } from "vue";

const props = defineProps<{
  workShiftName?: string;
  translatorName: string;
  workShiftId: string;
  operatorId: string;
  close: () => void;
}>();

const {
  openUnAssignWorkShiftModal,
  hideUnAssignWorkShiftModal,
  unAssignWorkShift,
  unAssignWorkShiftState,
} = useUnAssignWorkShift();

function closeModal() {
  hideUnAssignWorkShiftModal();
  props.close();
}
openUnAssignWorkShiftModal(props.workShiftId, props.operatorId);
</script>

<template>
  <v-card
    title="Do you want to unassign this work shift?"
    :subtitle="`${props.workShiftName} will be unassigned from ${props.translatorName} operator`"
  >
    <v-card-actions style="justify-content: flex-end">
      <v-btn @click="closeModal()">Cancel</v-btn>
      <v-btn
        @click="unAssignWorkShift().then(() => props.close())"
        color="error"
        :disabled="unAssignWorkShiftState.loading"
        >Unassign Work Shift
        <v-progress-circular
          v-if="unAssignWorkShiftState.loading"
          indeterminate
        ></v-progress-circular>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped lang="scss"></style>
