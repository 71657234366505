export enum AMPLITUDE_EVENT_NAME {
  EXT_USER_LOGIN = "EXT_USER_LOGIN",
  EXT_EXTERNAL_PROFILE_ADDED = "EXT_EXTERNAL_PROFILE_ADDED",
  EXT_LOAD_HOME_PAGE = "EXT_LOAD_HOME_PAGE",
  EXT_TU_EVENT = "EXT_TU_EVENT",
  EXT_CABINET_MODAL_ACTION = "EXT_CABINET_MODAL_ACTION",
  EXT_CABINET_ACTIONS = "EXT_CABINET_ACTIONS",
  EXT_WORK_SHIFTS_MODAL_ACTION = "EXT_WORK_SHIFTS_MODAL_ACTION",
  EXT_WORK_SHIFTS_ACTION = "EXT_WORK_SHIFTS_ACTION",
  EXT_WORK_SHIFTS_CROSSING_ERROR = "EXT_WORK_SHIFTS_CROSSING_ERROR ",
  EXT_PROFILE_MODAL_ACTION = "EXT_PROFILE_MODAL_ACTION",
  EXT_PROFILE_ACTIONS = "EXT_PROFILE_ACTIONS",
  EXT_FORCE_LOGOUT = "EXT_FORCE_LOGOUT",
  EXT_REF_CODE_DEACTIVATE = "EXT_REF_CODE_DEACTIVATE",
}

export enum CABINET_ACTIONS_NAME {
  CREATE = "create",
  EDIT = "edit",
  DELETE = "delete",
  ASSIGN = "assign",
  REASSIGN = "reassign",
  UNASSIGN = "unassign",
}

export enum ANALYTICS_EVENT_TU_ACTION {
  ADD_TO_CABINET = "addToCabinet ",
  REMOVE_FROM_CABINET = "removeFromCabinet",
  ADD_TO_OPERATOR = "addToOperator",
  REMOVE_FROM_OPERATOR = "removeFromOperator",
}

export enum EVENT_CABINET_MODAL_ACTION {
  OPEN = "open",
  CANCEL = "cancel",
}

export enum ANALYTICS_EVENT_MODAL_ACTION {
  OPEN = "open",
  CANCEL = "cancel",
}

export enum WORK_SHIFT_EVENT_NAME {
  CREATE = "create",
  EDIT = "edit",
  DELETE = "delete",
  ASSIGN = "assign",
  REASSIGN = "reassign",
  UNASSIGN = "unassign",
}

export enum WORK_SHIFTS_ERROR_ACTION {
  EDIT = "edit",
  ASSIGN = "assign",
}

export enum ANALYTICS_EVENT_ROLE_NAME {
  MANAGER = "manager",
  OPERATOR = "operator",
}

export enum ANALYTICS_EVENT_PROFILE_ACTION {
  EDIT = "edit",
  CREATE = "create",
  DELETE = "delete",
  ASSIGN = "assign",
  REASSIGN = "reassign",
  CHANGE_REF_CODE = "changeRefCode",
}
