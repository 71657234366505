<script setup lang="ts">
import { reactive } from "vue";
import type { Manager } from "@/stores/types/users-types";
import ManagersTUs from "@/components/ManagersTUs.vue";
import AssignExpandedPanel from "@/components/AssignExpandedPanel.vue";

const props = defineProps<{ managers: Manager[]; loading: boolean }>();

const managerState = reactive<{
  managers: Manager[];
  search: string;
}>({
  get managers(): Manager[] {
    if (this.search.length) {
      const searchedValue = this.search.toLowerCase();
      return props.managers.filter((manager) => {
        return (
          manager.firstName.toLowerCase().includes(searchedValue) ||
          manager.lastName.toLowerCase().includes(searchedValue) ||
          manager.childrenTU.some((id) => id.toString().includes(searchedValue))
        );
      });
    } else {
      return props.managers;
    }
  },
  search: "",
});
</script>
<script lang="ts">
export default {};
</script>

<template>
  <v-text-field
    :disabled="props.loading"
    label="Search manager name/ID profile"
    placeholder="Search manager name/ID profile"
    hide-details="auto"
    v-model="managerState.search"
    type="text"
  >
  </v-text-field>
  <div
    class="manager"
    v-for="manager in managerState.managers"
    :key="manager._id"
  >
    <managers-t-us :id-manager="manager._id"> </managers-t-us>
    <v-divider />
    <assign-expanded-panel :source="manager"></assign-expanded-panel>
  </div>
</template>

<style scoped lang="scss">
.manager {
  min-height: 80px;
  display: flex;
  padding: var(--indent-m);
  border-radius: 6px;
  margin: 0 0 var(--indent-l);
  background: #fff;
  flex-direction: column;
  justify-content: center;
  row-gap: var(--indent-m);
  border: 1px solid #e8eaf5;
}
</style>
