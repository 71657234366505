import { defineStore } from "pinia";

export const useCurrentPageStore = defineStore({
  id: "currentPage",
  state: () => {
    return { currentPage: "/", currentAppPage: "" };
  },
  getters: {
    page: (state) => state.currentPage,
    appPage: (state) => state.currentAppPage,
    isStructurePage: (state) => state.currentPage.includes("/structure"),
    isManagersPage: (state) => state.currentPage.includes("/managers"),
    isOperatorsPage: (state) => state.currentPage.includes("/operators"),
    isExtensionPage: (state) =>
      state.currentPage.includes("/structure") ||
      state.currentPage.includes("/managers") ||
      state.currentPage.includes("/operators"),
  },
  actions: {
    set(page: string) {
      this.currentPage = page;
    },
    setAppPage(page: string) {
      this.currentAppPage = page;
    },
  },
});
