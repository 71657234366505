import { reactive } from "vue";
import type { UnwrapRef } from "vue";
import type { UserFormState } from "@/components/UserForm/user-form-types";
import type { Manager, Translator } from "@/stores/types/users-types";
import { useUsersStore } from "@/stores/users-store";

export function useEditUser<T extends Translator | Manager>() {
  const usersStore = useUsersStore();
  const editState = reactive<{ state: boolean; user?: T }>({
    state: false,
    user: undefined,
  });

  const hideEditModal = (): void => {
    editState.state = false;
    editState.user = undefined;
  };

  return {
    editState,
    hideEditModal,
    updateManager: async (data: UserFormState): Promise<void> => {
      if (!editState.user) {
        throw new Error("Manager is not defined");
      }
      await usersStore.updateManager(editState.user as Manager, data);
      hideEditModal();
      await usersStore.indexManagers();
    },
    updateTranslator: async (data: UserFormState): Promise<void> => {
      if (!editState.user) {
        throw new Error("Manager is not defined");
      }
      await usersStore.updateTranslator(editState.user as Translator, data);
      hideEditModal();
      await usersStore.indexTranslators();
    },
    openEditModal: (user?: T): void => {
      if (user) {
        editState.user = user as UnwrapRef<T>;
        editState.state = true;
      }
    },
  };
}
