import { reactive } from "vue";
import { useUsersStore } from "@/stores/users-store";
import { useToast } from "vue-toastification";
import {
  getAssignOperatorErrorMessage,
  getChangePassErrorMessage,
} from "@/core";
import type { Operator } from "@/stores/types/users-types";
import type { AxiosError } from "axios";
import type { ServerError } from "@/core";
import { useCabinetsStore } from "@/stores/cabinets-store";
import { TU_TYPE_ENUM } from "@/core/enums/tu-type.enum";
import { useUserProfileStore } from "@/stores/user-profile-store";

export type OperatorExtended = {
  operator: Operator;
  idTU: number;
  password: string;
  selected: boolean;
  showPass: boolean;
  invalidPassword: boolean;
  isPasswordValidated: boolean;
};

export function useAddProfiles() {
  const tab = reactive<{ state: "unassigned" | "external" }>({
    state: "unassigned",
  });

  const toast = useToast();

  const addProfilesState = reactive<{
    state: boolean;
    loading: boolean;
    search: string;
    searchByIdTU: string;
    searchByNameTU: string;
    _allOperators: Operator[];
    allOperators: Operator[];
    operatorsIds: {
      alreadySelect: OperatorExtended[];
      unselect: Operator["idTU"][];
    };
    externalOperatorsIds: {
      alreadySelect: OperatorExtended[];
      unselect: Operator["idTU"][];
    };
    modifiedOperators: Set<OperatorExtended>;
    selectedOperatorsCount: string;
    isAddButtonEnabled: boolean;
    isAssigningFailed: boolean;
    isSelectedOperatorsValid: boolean;
    selectedOperators: OperatorExtended[];
  }>({
    state: false,
    loading: false,
    search: "",
    searchByIdTU: "",
    searchByNameTU: "",
    _allOperators: [],
    isAssigningFailed: false,
    modifiedOperators: new Set(),
    get allOperators() {
      return this._allOperators;
    },
    get operatorsIds(): {
      alreadySelect: OperatorExtended[];
      unselect: Operator["idTU"][];
    } {
      const alreadySelect = addProfilesState.selectedOperators
        .filter(({ operator }) => operator.tuType !== TU_TYPE_ENUM.EXTERNAL)
        .filter(({ operator }) => {
          const searchedValueName = this.searchByNameTU.toLowerCase();
          const searchedValueId = this.searchByIdTU.toLowerCase();
          if (searchedValueName && searchedValueId) {
            return (
              operator.name.toLowerCase().includes(searchedValueName) &&
              operator.idTU.toString().toLowerCase().includes(searchedValueId)
            );
          } else if (searchedValueName && !searchedValueId) {
            return operator.name.toLowerCase().includes(searchedValueName);
          } else {
            return operator.idTU
              .toString()
              .toLowerCase()
              .includes(searchedValueId);
          }
        });
      const unselect = addProfilesState._allOperators
        .filter(({ tuType }) => tuType !== TU_TYPE_ENUM.EXTERNAL)
        .filter(
          ({ idTU }) =>
            !alreadySelect.some((selectedItem) => selectedItem.idTU === idTU)
        )
        .filter(({ idTU, name }): boolean => {
          const searchedValueName = this.searchByNameTU.toLowerCase();
          const searchedValueId = this.searchByIdTU.toLowerCase();
          if (searchedValueName && searchedValueId) {
            return (
              name.toLowerCase().includes(searchedValueName) &&
              idTU.toString().toLowerCase().includes(searchedValueId)
            );
          } else if (searchedValueName && !searchedValueId) {
            return name.toLowerCase().includes(searchedValueName);
          } else {
            return idTU.toString().toLowerCase().includes(searchedValueId);
          }
        })
        .map(({ idTU }) => idTU);
      return { alreadySelect, unselect };
    },
    get externalOperatorsIds(): {
      alreadySelect: OperatorExtended[];
      unselect: Operator["idTU"][];
    } {
      const alreadySelect = addProfilesState.selectedOperators
        .filter(({ operator }) => operator.tuType === TU_TYPE_ENUM.EXTERNAL)
        .filter(({ operator }) => {
          const searchedValue = this.search.toLowerCase();
          return (
            operator.name.toLowerCase().includes(searchedValue) ||
            operator.idTU.toString().toLowerCase().includes(searchedValue)
          );
        });
      const unselect = addProfilesState._allOperators
        .filter(({ tuType }) => tuType === TU_TYPE_ENUM.EXTERNAL)
        .filter(
          ({ idTU }) =>
            !alreadySelect.some((selectedItem) => selectedItem.idTU === idTU)
        )
        .filter(({ idTU, name }): boolean => {
          const searchedValue = this.search.toLowerCase();
          return (
            name.toLowerCase().includes(searchedValue) ||
            idTU.toString().toLowerCase().includes(searchedValue)
          );
        })
        .map(({ idTU }) => idTU);
      return { alreadySelect, unselect };
    },
    set allOperators(value: Operator[]) {
      this._allOperators = value;
    },
    get selectedOperatorsCount(): string {
      const count = this.selectedOperators.length;
      return count ? `${count}` : "";
    },
    get isSelectedOperatorsValid(): boolean {
      return (
        !!this.selectedOperators.length &&
        checkOperatorWithRequiredPass() &&
        checkOperatorWithOptionalPass()
      );
    },
    get isAddButtonEnabled(): boolean {
      return this.isSelectedOperatorsValid && !this.loading;
    },
    get selectedOperators(): OperatorExtended[] {
      return [...addProfilesState.modifiedOperators].filter(
        ({ selected }) => selected
      );
    },
  });

  const checkOperatorWithRequiredPass = (): boolean => {
    return addProfilesState.selectedOperators
      .filter(({ operator: { tuType } }) => tuType !== TU_TYPE_ENUM.EXTERNAL)
      .filter(({ operator: { _id } }) => !isOperatorHasPassword(_id))
      .filter((operator) => !isOperatorHasPassword(operator.operator._id))
      .every(
        ({ password, invalidPassword, isPasswordValidated }) =>
          !!password && !invalidPassword && isPasswordValidated
      );
  };

  const checkOperatorWithOptionalPass = (): boolean => {
    return addProfilesState.selectedOperators
      .filter(({ operator: { tuType } }) => tuType !== TU_TYPE_ENUM.EXTERNAL)
      .filter(({ operator: { _id } }) => isOperatorHasPassword(_id))
      .filter((operator) => !isOperatorHasPassword(operator.operator._id))
      .every(
        ({ password, invalidPassword, isPasswordValidated }) =>
          !password || (!invalidPassword && isPasswordValidated)
      );
  };

  const onOperatorChanged = (data: OperatorExtended) => {
    addProfilesState.modifiedOperators.add(data);
  };

  const hideAddProfilesModal = (): void => {
    addProfilesState.state = false;
  };
  const openAddProfilesModal = (parentId: string, cabinetId: string): void => {
    setDefaultModalState();

    const originalSource = parentId
      ? useUsersStore().getUserById(parentId)?.childrenTU
      : useCabinetsStore().getCabinetById(cabinetId)?.idTUs;

    if (!originalSource) return;

    addProfilesState.allOperators = [...useUsersStore().allOperators].filter(
      (item) => !originalSource.includes(item.idTU)
    ) as Operator[];

    addProfilesState.state = true;
  };

  const addProfiles = (parentId: string, cabinetId: string) => {
    addProfilesState.loading = true;
    return recursionCallAddProfile(0, parentId, cabinetId);
  };

  async function recursionCallAddProfile(
    i = 0,
    parentId: string,
    cabinetId: string
  ): Promise<void> {
    const { operator, password } = addProfilesState.selectedOperators[i];

    try {
      await addProfile(operator, password, parentId, cabinetId);
    } catch (e) {
      addProfilesState.isAssigningFailed = true;
    }

    if (i !== addProfilesState.selectedOperators.length - 1) {
      recursionCallAddProfile(++i, parentId, cabinetId);
    } else {
      if (!addProfilesState.isAssigningFailed) {
        toast.success("Selected profiles were added");
      }
      hideAddProfilesModal();
      if (isCheckOperatorsPasswordRequired()) {
        await useUsersStore().checkOperatorsPassword();
      }
    }
  }

  async function addProfile(
    operator: Operator,
    password: string,
    parentId: string,
    cabinetId: string
  ): Promise<void> {
    try {
      if (parentId) {
        await useUsersStore().assignOperator({ operator, parentId });
      } else {
        await useCabinetsStore().assignCabinetTU(operator, password, cabinetId);
      }
      if (password && useUserProfileStore().isAdminRole) {
        await changeProfilePass(operator, password);
      }
    } catch (e) {
      const message = getAssignOperatorErrorMessage(operator);
      toast.error(message);
      throw e;
    }
  }

  async function changeProfilePass(
    operator: Operator,
    pass: string
  ): Promise<void> {
    try {
      await useUsersStore().changeOperatorPassword({ id: operator._id, pass });
    } catch (e) {
      const message = getChangePassErrorMessage(
        operator,
        pass,
        e as AxiosError<ServerError>
      );
      toast.error(message);
    }
  }

  const isCheckOperatorsPasswordRequired = (): boolean => {
    return addProfilesState.selectedOperators.some(
      (o) => !isOperatorHasPassword(o.operator._id)
    );
  };

  const isOperatorHasPassword = (id: string): boolean => {
    return useUsersStore().checkOperatorPasswordById(id);
  };

  const setDefaultModalState = (): void => {
    addProfilesState.loading = false;
    addProfilesState.isAssigningFailed = false;
    addProfilesState.modifiedOperators.clear();
    addProfilesState.search = "";
    addProfilesState.searchByIdTU = "";
    addProfilesState.searchByNameTU = "";
    tab.state = "unassigned";
  };

  return {
    addProfilesState,
    tab,
    hideAddProfilesModal,
    openAddProfilesModal,
    addProfiles,
    onOperatorChanged,
  };
}
