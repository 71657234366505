type VoidOrPromiseCallback =
  | ((body?: string, url?: string) => void)
  | ((body?: string, url?: string) => Promise<void>);
type Listeners = { url: string; action: VoidOrPromiseCallback };
const listeners: Listeners[] = [];
const send = window.XMLHttpRequest.prototype.send;
const open = window.XMLHttpRequest.prototype.open;
declare global {
  interface XMLHttpRequest {
    url: string;
  }
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.XMLHttpRequest.prototype.open = function (
  method: string,
  url: string | URL,
  async: boolean,
  username: undefined | null | string,
  password: undefined | null | string
): void {
  this.url = typeof url === "string" ? url : url.toString();
  open.apply(this, [method, url, async, username, password]);
};

window.XMLHttpRequest.prototype.send = function (body?: string): void {
  listeners.find((el) => this.url.includes(el.url))?.action(body, this.url);
  send.apply(this, [body]);
};

export const listenRequest = (url: string, action: VoidOrPromiseCallback) => {
  listeners.push({ url, action });
};
