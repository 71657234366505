<script setup lang="ts">
import { useUsersStore } from "@/stores/users-store";
import { reactive } from "vue";
import type { Operator } from "@/stores/types/users-types";
import OperatorsContainer from "@/components/Operators/OperatorsContainer.vue";
import ExternalOperatorForm from "@/components/Operators/ExternalOperatorForm.vue";
import type { BaseCredentials } from "@/core";
import { useUserProfileStore } from "@/stores/user-profile-store";
import ModalService from "@/core/services/ModalService";

const usersStore = useUsersStore();

const usersStateExternal = reactive<{
  external: Operator["idTU"][];
  searchExternal: string;
}>({
  get external(): Operator["idTU"][] {
    return usersStore.operators.external
      .filter(({ idTU, name }: Operator): boolean => {
        const searchedValue = this.searchExternal.toLowerCase();
        return (
          name.toLowerCase().includes(searchedValue) ||
          idTU.toString().toLowerCase().includes(searchedValue)
        );
      })
      .map(({ idTU }) => idTU);
  },
  searchExternal: "",
});

const userStateUnassigned = reactive<{
  unassigned: Operator["idTU"][];
}>({
  get unassigned(): Operator["idTU"][] {
    if (isAdminRole) {
      return usersStore.operators.unassigned.map(({ idTU }) => idTU);
    } else {
      return usersStore.operators.unassigned
        .filter(({ idTU, name }: Operator): boolean => {
          const searchedValueID = searchUnassigned.searchByIdTU.toLowerCase();
          const searchedValueName =
            searchUnassigned.searchByNameTU.toLowerCase();
          return (
            name.toLowerCase().includes(searchedValueName) &&
            idTU.toString().toLowerCase().includes(searchedValueID)
          );
        })
        .map(({ idTU }) => idTU);
    }
  },
});

const userStateAll = reactive<{
  all: Operator["idTU"][];
  searchAll: string;
}>({
  get all(): Operator["idTU"][] {
    return usersStore.allOperators
      .filter(({ idTU, name }: Operator): boolean => {
        const searchedValue = this.searchAll.toLowerCase();
        return (
          name.toLowerCase().includes(searchedValue) ||
          idTU.toString().toLowerCase().includes(searchedValue)
        );
      })
      .map(({ idTU }) => idTU);
  },
  searchAll: "",
});

const searchUnassigned = reactive<{
  searchByIdTU: string;
  searchByNameTU: string;
}>({
  searchByIdTU: "",
  searchByNameTU: "",
});

const tab = reactive<{ state: string }>({
  state: "unassigned",
});

const loadMoreProfile = reactive<{ state: boolean }>({
  state: false,
});

const isAdminRole = useUserProfileStore().isAdminRole;

const externalOperatorAddModal = ModalService.init(ExternalOperatorForm, {
  async submit(data: BaseCredentials) {
    await createExternalOperator(data);
  },
  close() {
    hideExternalOperatorModal();
  },
});
let timer: number | undefined;

function hideExternalOperatorModal(): void {
  externalOperatorAddModal.close();
}
async function searchTU() {
  if (isAdminRole) {
    clearTimeout(timer);
    timer = setTimeout(async () => {
      loadMoreProfile.state = true;
      await usersStore.loadUnassignedTUsWithNewSearch(
        searchUnassigned.searchByIdTU,
        searchUnassigned.searchByNameTU
      );
      loadMoreProfile.state = false;
    }, 300);
  }
}

async function createExternalOperator(data: BaseCredentials): Promise<void> {
  await usersStore.createExternalOperator(data);
  hideExternalOperatorModal();
}

async function scrollEndInOperatorSearch(): Promise<void> {
  if (loadMoreProfile.state || usersStore.allTULoaded || !isAdminRole) return;
  loadMoreProfile.state = true;
  await usersStore.loadAdditionalUnassignedTUs(
    searchUnassigned.searchByIdTU,
    searchUnassigned.searchByNameTU
  );
  loadMoreProfile.state = false;
}
</script>
<template>
  <div style="margin-bottom: var(--indent-m)">
    <v-card>
      <v-text-field
        v-if="tab.state === 'external'"
        :disabled="usersStore.operators.loading"
        label="search by ID/Name"
        placeholder="search by ID/Name"
        v-model="usersStateExternal.searchExternal"
        class="profiles-search-input"
        hide-details="auto"
        type="text"
      >
      </v-text-field>
      <div v-if="tab.state === 'unassigned'" class="unassigned-search">
        <v-text-field
          :disabled="usersStore.operators.loading"
          label="search by ID"
          placeholder="search by ID"
          v-model="searchUnassigned.searchByIdTU"
          @input="searchTU"
          class="profiles-search-input"
          hide-details="auto"
          type="text"
        >
        </v-text-field>
        <div class="divider"></div>
        <v-text-field
          :disabled="usersStore.operators.loading"
          label="search by name"
          placeholder="search by name"
          v-model="searchUnassigned.searchByNameTU"
          @input="searchTU"
          class="profiles-search-input"
          hide-details="auto"
          type="text"
        >
        </v-text-field>
      </div>
      <v-text-field
        v-if="tab.state === 'all'"
        :disabled="usersStore.operators.loading"
        label="search by ID/Name"
        placeholder="search by ID/Name"
        v-model="userStateAll.searchAll"
        class="profiles-search-input"
        hide-details="auto"
        type="text"
      >
      </v-text-field>
      <v-tabs v-model="tab.state">
        <v-tab value="unassigned">Unassigned profiles</v-tab>
        <v-tab value="external"
          >External profiles
          <v-icon
            size="20px"
            class="external-icon"
            color="primary"
            icon="mdi-cloud-upload"
          ></v-icon>
        </v-tab>
        <v-tab v-if="!isAdminRole" value="all">All profiles</v-tab>
      </v-tabs>
      <v-window v-model="tab.state">
        <v-window-item value="unassigned">
          <div class="profiles-wrapper">
            <operators-container
              :parent-label="'Unassigned'"
              :operator-ids="userStateUnassigned.unassigned"
              :search="true"
              @scroll-end="scrollEndInOperatorSearch"
            ></operators-container>
            <v-progress-linear
              v-if="loadMoreProfile.state"
              class="search-progress-line mt-3"
              color="deep-purple accent-4"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </div>
        </v-window-item>
        <v-window-item value="external">
          <div v-if="isAdminRole" class="add-external-profile">
            <v-btn
              @click="() => externalOperatorAddModal.open()"
              color="primary"
              >Add Profile
            </v-btn>
          </div>
          <div class="profiles-wrapper">
            <operators-container
              :parent-label="'External'"
              :operator-ids="usersStateExternal.external"
              :search="true"
            ></operators-container>
          </div>
        </v-window-item>
        <v-window-item value="all">
          <div class="profiles-wrapper">
            <operators-container
              :parent-label="'All'"
              :operator-ids="userStateAll.all"
              :search="true"
            ></operators-container>
          </div>
        </v-window-item>
      </v-window>
    </v-card>
  </div>
</template>
<style scoped lang="scss">
.external-icon {
  margin-left: 5px;
}

.add-external-profile {
  display: flex;
  justify-content: flex-end;
  padding: var(--indent-m) var(--indent-xl);
}
.unassigned-search {
  display: flex;
}
.divider {
  margin: 0 5px 0 5px;
}

.search-progress-line {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
