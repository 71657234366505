<script lang="ts" setup>
import { defineProps, reactive } from "vue";
import type { WorkShift } from "@/stores/types/work-shift-types";
import WorkShiftForm from "@/components/WorkShift/WorkShiftForm.vue";
import { useEditWorkShift } from "@/components/WorkShift/edit-work-shift";
import { useDeleteWorkShift } from "@/components/WorkShift/delete-work-shift";
import type { UseModalReturnType } from "vue-final-modal";
import type { WorkShiftBase } from "@/stores/types/work-shift-types";
import DeleteUnit from "@/components/Operators/DeleteUnit.vue";
import ModalService from "@/core/services/ModalService";

const props = defineProps<{
  workShift: WorkShift;
}>();

const { openEditModal, hideEditModal, editWorkShift } = useEditWorkShift();

const { openDeleteModal, hideDeleteModal, deleteWorkShift } =
  useDeleteWorkShift();

const state = reactive<{
  workShift: WorkShift;
}>({
  get workShift(): WorkShift {
    return props.workShift;
  },
});

const getTimeInformation = (): string => {
  return `${state.workShift.startTime} - ${state.workShift.endTime}, ${state.workShift.timeZone}`;
};

let editWorkShiftModal: UseModalReturnType<any>;

function closeEditWorkShiftModal() {
  hideEditModal();
  editWorkShiftModal.close();
}

function openEditWorkShitModal(id: string) {
  openEditModal(id);
  editWorkShiftModal = ModalService.init(WorkShiftForm, {
    state: state.workShift,
    cancel() {
      closeEditWorkShiftModal();
    },
    async submit(data: WorkShiftBase) {
      await editWorkShift(data).then(() => editWorkShiftModal.close());
    },
  });
  editWorkShiftModal.open();
}

const deleteWorkShiftModal = ModalService.init(DeleteUnit, {
  style: "width: 600px",
  deleteStateThing: {
    type: "work shift",
    name: state.workShift.name,
    loading: false,
  },
  async deleteUnit() {
    await deleteWorkShift().then(() => closeDeleteWorkShiftModal());
  },
  hideDeleteModal() {
    closeDeleteWorkShiftModal();
  },
});

const workShiftAction = () => {
  return [
    { label: "Actions", action: "action" },
    { label: "Edit work shift", action: "edit" },
    { label: "Delete work shift", action: "delete" },
  ];
};

function openDeleteWorkShiftModal(id: string) {
  openDeleteModal(id);
  deleteWorkShiftModal.open();
}

function closeDeleteWorkShiftModal() {
  hideDeleteModal();
  deleteWorkShiftModal.close();
}

function selectAction(event: string) {
  switch (event) {
    case "edit":
      openEditWorkShitModal(state.workShift._id);
      return;
    case "delete":
      openDeleteWorkShiftModal(state.workShift._id);
      return;
    default:
      return;
  }
}
</script>
<template>
  <div class="work-shift">
    <div class="work-shift__title">
      <h3 class="work-shift__name text-h6">Work Shift</h3>
      <span>{{ state?.workShift.name }}</span>
    </div>
    <div class="work-shift__info">
      <div class="work-shift__info-label">{{ getTimeInformation() }}</div>
    </div>
    <div class="work-shift__actions">
      <v-select
        v-model="workShiftAction()[0]"
        item-title="label"
        item-value="action"
        :items="workShiftAction()"
        variant="outlined"
        density="compact"
        hide-details
        hide-selected
        @update:model-value="selectAction($event)"
      >
      </v-select>
    </div>
  </div>
</template>

<style scoped lang="scss">
.work-shift {
  display: flex;
  align-items: center;
  column-gap: var(--indent-m);

  &__title {
    display: flex;
    align-items: baseline;

    h3 {
      margin-right: 10px;
    }
  }

  &__info {
    margin-inline-start: auto;
    display: flex;
    column-gap: var(--indent-m);
  }

  &__info-label {
    color: #b3b3b3;
  }

  &__actions {
    margin-inline-start: var(--indent-xl);
    display: flex;
    column-gap: var(--indent-m);
  }
}
</style>
