import type { TimeZone } from "@/components/WorkShift/work-shift-form-types";

export const TINE_ZONES: TimeZone[] = [
  {
    display: "UTC+00:00, Africa/Abidjan",
    value: "Africa/Abidjan",
  },
  {
    display: "UTC+00:00, Africa/Accra",
    value: "Africa/Accra",
  },
  {
    display: "UTC+03:00, Africa/Addis_Ababa",
    value: "Africa/Addis_Ababa",
  },
  {
    display: "UTC+01:00, Africa/Algiers",
    value: "Africa/Algiers",
  },
  {
    display: "UTC+03:00, Africa/Asmera",
    value: "Africa/Asmera",
  },
  {
    display: "UTC+00:00, Africa/Bamako",
    value: "Africa/Bamako",
  },
  {
    display: "UTC+01:00, Africa/Bangui",
    value: "Africa/Bangui",
  },
  {
    display: "UTC+00:00, Africa/Banjul",
    value: "Africa/Banjul",
  },
  {
    display: "UTC+00:00, Africa/Bissau",
    value: "Africa/Bissau",
  },
  {
    display: "UTC+02:00, Africa/Blantyre",
    value: "Africa/Blantyre",
  },
  {
    display: "UTC+01:00, Africa/Brazzaville",
    value: "Africa/Brazzaville",
  },
  {
    display: "UTC+02:00, Africa/Bujumbura",
    value: "Africa/Bujumbura",
  },
  {
    display: "UTC+03:00, Africa/Cairo",
    value: "Africa/Cairo",
  },
  {
    display: "UTC+01:00, Africa/Casablanca",
    value: "Africa/Casablanca",
  },
  {
    display: "UTC+02:00, Africa/Ceuta",
    value: "Africa/Ceuta",
  },
  {
    display: "UTC+00:00, Africa/Conakry",
    value: "Africa/Conakry",
  },
  {
    display: "UTC+00:00, Africa/Dakar",
    value: "Africa/Dakar",
  },
  {
    display: "UTC+03:00, Africa/Dar_es_Salaam",
    value: "Africa/Dar_es_Salaam",
  },
  {
    display: "UTC+03:00, Africa/Djibouti",
    value: "Africa/Djibouti",
  },
  {
    display: "UTC+01:00, Africa/Douala",
    value: "Africa/Douala",
  },
  {
    display: "UTC+01:00, Africa/El_Aaiun",
    value: "Africa/El_Aaiun",
  },
  {
    display: "UTC+00:00, Africa/Freetown",
    value: "Africa/Freetown",
  },
  {
    display: "UTC+02:00, Africa/Gaborone",
    value: "Africa/Gaborone",
  },
  {
    display: "UTC+02:00, Africa/Harare",
    value: "Africa/Harare",
  },
  {
    display: "UTC+02:00, Africa/Johannesburg",
    value: "Africa/Johannesburg",
  },
  {
    display: "UTC+02:00, Africa/Juba",
    value: "Africa/Juba",
  },
  {
    display: "UTC+03:00, Africa/Kampala",
    value: "Africa/Kampala",
  },
  {
    display: "UTC+02:00, Africa/Khartoum",
    value: "Africa/Khartoum",
  },
  {
    display: "UTC+02:00, Africa/Kigali",
    value: "Africa/Kigali",
  },
  {
    display: "UTC+01:00, Africa/Kinshasa",
    value: "Africa/Kinshasa",
  },
  {
    display: "UTC+01:00, Africa/Lagos",
    value: "Africa/Lagos",
  },
  {
    display: "UTC+01:00, Africa/Libreville",
    value: "Africa/Libreville",
  },
  {
    display: "UTC+00:00, Africa/Lome",
    value: "Africa/Lome",
  },
  {
    display: "UTC+01:00, Africa/Luanda",
    value: "Africa/Luanda",
  },
  {
    display: "UTC+02:00, Africa/Lubumbashi",
    value: "Africa/Lubumbashi",
  },
  {
    display: "UTC+02:00, Africa/Lusaka",
    value: "Africa/Lusaka",
  },
  {
    display: "UTC+01:00, Africa/Malabo",
    value: "Africa/Malabo",
  },
  {
    display: "UTC+02:00, Africa/Maputo",
    value: "Africa/Maputo",
  },
  {
    display: "UTC+02:00, Africa/Maseru",
    value: "Africa/Maseru",
  },
  {
    display: "UTC+02:00, Africa/Mbabane",
    value: "Africa/Mbabane",
  },
  {
    display: "UTC+03:00, Africa/Mogadishu",
    value: "Africa/Mogadishu",
  },
  {
    display: "UTC+00:00, Africa/Monrovia",
    value: "Africa/Monrovia",
  },
  {
    display: "UTC+03:00, Africa/Nairobi",
    value: "Africa/Nairobi",
  },
  {
    display: "UTC+01:00, Africa/Ndjamena",
    value: "Africa/Ndjamena",
  },
  {
    display: "UTC+01:00, Africa/Niamey",
    value: "Africa/Niamey",
  },
  {
    display: "UTC+00:00, Africa/Nouakchott",
    value: "Africa/Nouakchott",
  },
  {
    display: "UTC+00:00, Africa/Ouagadougou",
    value: "Africa/Ouagadougou",
  },
  {
    display: "UTC+01:00, Africa/Porto-Novo",
    value: "Africa/Porto-Novo",
  },
  {
    display: "UTC+00:00, Africa/Sao_Tome",
    value: "Africa/Sao_Tome",
  },
  {
    display: "UTC+02:00, Africa/Tripoli",
    value: "Africa/Tripoli",
  },
  {
    display: "UTC+01:00, Africa/Tunis",
    value: "Africa/Tunis",
  },
  {
    display: "UTC+02:00, Africa/Windhoek",
    value: "Africa/Windhoek",
  },
  {
    display: "UTC-09:00, America/Adak",
    value: "America/Adak",
  },
  {
    display: "UTC-08:00, America/Anchorage",
    value: "America/Anchorage",
  },
  {
    display: "UTC-04:00, America/Anguilla",
    value: "America/Anguilla",
  },
  {
    display: "UTC-04:00, America/Antigua",
    value: "America/Antigua",
  },
  {
    display: "UTC-03:00, America/Araguaina",
    value: "America/Araguaina",
  },
  {
    display: "UTC-03:00, America/Argentina/La_Rioja",
    value: "America/Argentina/La_Rioja",
  },
  {
    display: "UTC-03:00, America/Argentina/Rio_Gallegos",
    value: "America/Argentina/Rio_Gallegos",
  },
  {
    display: "UTC-03:00, America/Argentina/Salta",
    value: "America/Argentina/Salta",
  },
  {
    display: "UTC-03:00, America/Argentina/San_Juan",
    value: "America/Argentina/San_Juan",
  },
  {
    display: "UTC-03:00, America/Argentina/San_Luis",
    value: "America/Argentina/San_Luis",
  },
  {
    display: "UTC-03:00, America/Argentina/Tucuman",
    value: "America/Argentina/Tucuman",
  },
  {
    display: "UTC-03:00, America/Argentina/Ushuaia",
    value: "America/Argentina/Ushuaia",
  },
  {
    display: "UTC-04:00, America/Aruba",
    value: "America/Aruba",
  },
  {
    display: "UTC-04:00, America/Asuncion",
    value: "America/Asuncion",
  },
  {
    display: "UTC-03:00, America/Bahia",
    value: "America/Bahia",
  },
  {
    display: "UTC-06:00, America/Bahia_Banderas",
    value: "America/Bahia_Banderas",
  },
  {
    display: "UTC-04:00, America/Barbados",
    value: "America/Barbados",
  },
  {
    display: "UTC-03:00, America/Belem",
    value: "America/Belem",
  },
  {
    display: "UTC-06:00, America/Belize",
    value: "America/Belize",
  },
  {
    display: "UTC-04:00, America/Blanc-Sablon",
    value: "America/Blanc-Sablon",
  },
  {
    display: "UTC-04:00, America/Boa_Vista",
    value: "America/Boa_Vista",
  },
  {
    display: "UTC-05:00, America/Bogota",
    value: "America/Bogota",
  },
  {
    display: "UTC-06:00, America/Boise",
    value: "America/Boise",
  },
  {
    display: "UTC-03:00, America/Buenos_Aires",
    value: "America/Buenos_Aires",
  },
  {
    display: "UTC-06:00, America/Cambridge_Bay",
    value: "America/Cambridge_Bay",
  },
  {
    display: "UTC-04:00, America/Campo_Grande",
    value: "America/Campo_Grande",
  },
  {
    display: "UTC-05:00, America/Cancun",
    value: "America/Cancun",
  },
  {
    display: "UTC-04:00, America/Caracas",
    value: "America/Caracas",
  },
  {
    display: "UTC-03:00, America/Catamarca",
    value: "America/Catamarca",
  },
  {
    display: "UTC-03:00, America/Cayenne",
    value: "America/Cayenne",
  },
  {
    display: "UTC-05:00, America/Cayman",
    value: "America/Cayman",
  },
  {
    display: "UTC-05:00, America/Chicago",
    value: "America/Chicago",
  },
  {
    display: "UTC-06:00, America/Chihuahua",
    value: "America/Chihuahua",
  },
  {
    display: "UTC-06:00, America/Ciudad_Juarez",
    value: "America/Ciudad_Juarez",
  },
  {
    display: "UTC-05:00, America/Coral_Harbour",
    value: "America/Coral_Harbour",
  },
  {
    display: "UTC-03:00, America/Cordoba",
    value: "America/Cordoba",
  },
  {
    display: "UTC-06:00, America/Costa_Rica",
    value: "America/Costa_Rica",
  },
  {
    display: "UTC-07:00, America/Creston",
    value: "America/Creston",
  },
  {
    display: "UTC-04:00, America/Cuiaba",
    value: "America/Cuiaba",
  },
  {
    display: "UTC-04:00, America/Curacao",
    value: "America/Curacao",
  },
  {
    display: "UTC+00:00, America/Danmarkshavn",
    value: "America/Danmarkshavn",
  },
  {
    display: "UTC-07:00, America/Dawson",
    value: "America/Dawson",
  },
  {
    display: "UTC-07:00, America/Dawson_Creek",
    value: "America/Dawson_Creek",
  },
  {
    display: "UTC-06:00, America/Denver",
    value: "America/Denver",
  },
  {
    display: "UTC-04:00, America/Detroit",
    value: "America/Detroit",
  },
  {
    display: "UTC-04:00, America/Dominica",
    value: "America/Dominica",
  },
  {
    display: "UTC-06:00, America/Edmonton",
    value: "America/Edmonton",
  },
  {
    display: "UTC-05:00, America/Eirunepe",
    value: "America/Eirunepe",
  },
  {
    display: "UTC-06:00, America/El_Salvador",
    value: "America/El_Salvador",
  },
  {
    display: "UTC-07:00, America/Fort_Nelson",
    value: "America/Fort_Nelson",
  },
  {
    display: "UTC-03:00, America/Fortaleza",
    value: "America/Fortaleza",
  },
  {
    display: "UTC-03:00, America/Glace_Bay",
    value: "America/Glace_Bay",
  },
  {
    display: "UTC-02:00, America/Godthab",
    value: "America/Godthab",
  },
  {
    display: "UTC-03:00, America/Goose_Bay",
    value: "America/Goose_Bay",
  },
  {
    display: "UTC-04:00, America/Grand_Turk",
    value: "America/Grand_Turk",
  },
  {
    display: "UTC-04:00, America/Grenada",
    value: "America/Grenada",
  },
  {
    display: "UTC-04:00, America/Guadeloupe",
    value: "America/Guadeloupe",
  },
  {
    display: "UTC-06:00, America/Guatemala",
    value: "America/Guatemala",
  },
  {
    display: "UTC-05:00, America/Guayaquil",
    value: "America/Guayaquil",
  },
  {
    display: "UTC-04:00, America/Guyana",
    value: "America/Guyana",
  },
  {
    display: "UTC-03:00, America/Halifax",
    value: "America/Halifax",
  },
  {
    display: "UTC-04:00, America/Havana",
    value: "America/Havana",
  },
  {
    display: "UTC-07:00, America/Hermosillo",
    value: "America/Hermosillo",
  },
  {
    display: "UTC-05:00, America/Indiana/Knox",
    value: "America/Indiana/Knox",
  },
  {
    display: "UTC-04:00, America/Indiana/Marengo",
    value: "America/Indiana/Marengo",
  },
  {
    display: "UTC-04:00, America/Indiana/Petersburg",
    value: "America/Indiana/Petersburg",
  },
  {
    display: "UTC-05:00, America/Indiana/Tell_City",
    value: "America/Indiana/Tell_City",
  },
  {
    display: "UTC-04:00, America/Indiana/Vevay",
    value: "America/Indiana/Vevay",
  },
  {
    display: "UTC-04:00, America/Indiana/Vincennes",
    value: "America/Indiana/Vincennes",
  },
  {
    display: "UTC-04:00, America/Indiana/Winamac",
    value: "America/Indiana/Winamac",
  },
  {
    display: "UTC-04:00, America/Indianapolis",
    value: "America/Indianapolis",
  },
  {
    display: "UTC-06:00, America/Inuvik",
    value: "America/Inuvik",
  },
  {
    display: "UTC-04:00, America/Iqaluit",
    value: "America/Iqaluit",
  },
  {
    display: "UTC-05:00, America/Jamaica",
    value: "America/Jamaica",
  },
  {
    display: "UTC-03:00, America/Jujuy",
    value: "America/Jujuy",
  },
  {
    display: "UTC-08:00, America/Juneau",
    value: "America/Juneau",
  },
  {
    display: "UTC-04:00, America/Kentucky/Monticello",
    value: "America/Kentucky/Monticello",
  },
  {
    display: "UTC-04:00, America/Kralendijk",
    value: "America/Kralendijk",
  },
  {
    display: "UTC-04:00, America/La_Paz",
    value: "America/La_Paz",
  },
  {
    display: "UTC-05:00, America/Lima",
    value: "America/Lima",
  },
  {
    display: "UTC-07:00, America/Los_Angeles",
    value: "America/Los_Angeles",
  },
  {
    display: "UTC-04:00, America/Louisville",
    value: "America/Louisville",
  },
  {
    display: "UTC-04:00, America/Lower_Princes",
    value: "America/Lower_Princes",
  },
  {
    display: "UTC-03:00, America/Maceio",
    value: "America/Maceio",
  },
  {
    display: "UTC-06:00, America/Managua",
    value: "America/Managua",
  },
  {
    display: "UTC-04:00, America/Manaus",
    value: "America/Manaus",
  },
  {
    display: "UTC-04:00, America/Marigot",
    value: "America/Marigot",
  },
  {
    display: "UTC-04:00, America/Martinique",
    value: "America/Martinique",
  },
  {
    display: "UTC-05:00, America/Matamoros",
    value: "America/Matamoros",
  },
  {
    display: "UTC-07:00, America/Mazatlan",
    value: "America/Mazatlan",
  },
  {
    display: "UTC-03:00, America/Mendoza",
    value: "America/Mendoza",
  },
  {
    display: "UTC-05:00, America/Menominee",
    value: "America/Menominee",
  },
  {
    display: "UTC-06:00, America/Merida",
    value: "America/Merida",
  },
  {
    display: "UTC-08:00, America/Metlakatla",
    value: "America/Metlakatla",
  },
  {
    display: "UTC-06:00, America/Mexico_City",
    value: "America/Mexico_City",
  },
  {
    display: "UTC-02:00, America/Miquelon",
    value: "America/Miquelon",
  },
  {
    display: "UTC-03:00, America/Moncton",
    value: "America/Moncton",
  },
  {
    display: "UTC-06:00, America/Monterrey",
    value: "America/Monterrey",
  },
  {
    display: "UTC-03:00, America/Montevideo",
    value: "America/Montevideo",
  },
  {
    display: "UTC-04:00, America/Montreal",
    value: "America/Montreal",
  },
  {
    display: "UTC-04:00, America/Montserrat",
    value: "America/Montserrat",
  },
  {
    display: "UTC-04:00, America/Nassau",
    value: "America/Nassau",
  },
  {
    display: "UTC-04:00, America/New_York",
    value: "America/New_York",
  },
  {
    display: "UTC-04:00, America/Nipigon",
    value: "America/Nipigon",
  },
  {
    display: "UTC-08:00, America/Nome",
    value: "America/Nome",
  },
  {
    display: "UTC-02:00, America/Noronha",
    value: "America/Noronha",
  },
  {
    display: "UTC-05:00, America/North_Dakota/Beulah",
    value: "America/North_Dakota/Beulah",
  },
  {
    display: "UTC-05:00, America/North_Dakota/Center",
    value: "America/North_Dakota/Center",
  },
  {
    display: "UTC-05:00, America/North_Dakota/New_Salem",
    value: "America/North_Dakota/New_Salem",
  },
  {
    display: "UTC-05:00, America/Ojinaga",
    value: "America/Ojinaga",
  },
  {
    display: "UTC-05:00, America/Panama",
    value: "America/Panama",
  },
  {
    display: "UTC-04:00, America/Pangnirtung",
    value: "America/Pangnirtung",
  },
  {
    display: "UTC-03:00, America/Paramaribo",
    value: "America/Paramaribo",
  },
  {
    display: "UTC-07:00, America/Phoenix",
    value: "America/Phoenix",
  },
  {
    display: "UTC-04:00, America/Port-au-Prince",
    value: "America/Port-au-Prince",
  },
  {
    display: "UTC-04:00, America/Port_of_Spain",
    value: "America/Port_of_Spain",
  },
  {
    display: "UTC-04:00, America/Porto_Velho",
    value: "America/Porto_Velho",
  },
  {
    display: "UTC-04:00, America/Puerto_Rico",
    value: "America/Puerto_Rico",
  },
  {
    display: "UTC-03:00, America/Punta_Arenas",
    value: "America/Punta_Arenas",
  },
  {
    display: "UTC-05:00, America/Rainy_River",
    value: "America/Rainy_River",
  },
  {
    display: "UTC-05:00, America/Rankin_Inlet",
    value: "America/Rankin_Inlet",
  },
  {
    display: "UTC-03:00, America/Recife",
    value: "America/Recife",
  },
  {
    display: "UTC-06:00, America/Regina",
    value: "America/Regina",
  },
  {
    display: "UTC-05:00, America/Resolute",
    value: "America/Resolute",
  },
  {
    display: "UTC-05:00, America/Rio_Branco",
    value: "America/Rio_Branco",
  },
  {
    display: "UTC-07:00, America/Santa_Isabel",
    value: "America/Santa_Isabel",
  },
  {
    display: "UTC-03:00, America/Santarem",
    value: "America/Santarem",
  },
  {
    display: "UTC-04:00, America/Santiago",
    value: "America/Santiago",
  },
  {
    display: "UTC-04:00, America/Santo_Domingo",
    value: "America/Santo_Domingo",
  },
  {
    display: "UTC-03:00, America/Sao_Paulo",
    value: "America/Sao_Paulo",
  },
  {
    display: "UTC+00:00, America/Scoresbysund",
    value: "America/Scoresbysund",
  },
  {
    display: "UTC-08:00, America/Sitka",
    value: "America/Sitka",
  },
  {
    display: "UTC-04:00, America/St_Barthelemy",
    value: "America/St_Barthelemy",
  },
  {
    display: "UTC-02:30, America/St_Johns",
    value: "America/St_Johns",
  },
  {
    display: "UTC-04:00, America/St_Kitts",
    value: "America/St_Kitts",
  },
  {
    display: "UTC-04:00, America/St_Lucia",
    value: "America/St_Lucia",
  },
  {
    display: "UTC-04:00, America/St_Thomas",
    value: "America/St_Thomas",
  },
  {
    display: "UTC-04:00, America/St_Vincent",
    value: "America/St_Vincent",
  },
  {
    display: "UTC-06:00, America/Swift_Current",
    value: "America/Swift_Current",
  },
  {
    display: "UTC-06:00, America/Tegucigalpa",
    value: "America/Tegucigalpa",
  },
  {
    display: "UTC-03:00, America/Thule",
    value: "America/Thule",
  },
  {
    display: "UTC-04:00, America/Thunder_Bay",
    value: "America/Thunder_Bay",
  },
  {
    display: "UTC-07:00, America/Tijuana",
    value: "America/Tijuana",
  },
  {
    display: "UTC-04:00, America/Toronto",
    value: "America/Toronto",
  },
  {
    display: "UTC-04:00, America/Tortola",
    value: "America/Tortola",
  },
  {
    display: "UTC-07:00, America/Vancouver",
    value: "America/Vancouver",
  },
  {
    display: "UTC-07:00, America/Whitehorse",
    value: "America/Whitehorse",
  },
  {
    display: "UTC-05:00, America/Winnipeg",
    value: "America/Winnipeg",
  },
  {
    display: "UTC-08:00, America/Yakutat",
    value: "America/Yakutat",
  },
  {
    display: "UTC-06:00, America/Yellowknife",
    value: "America/Yellowknife",
  },
  {
    display: "UTC+11:00, Antarctica/Casey",
    value: "Antarctica/Casey",
  },
  {
    display: "UTC+07:00, Antarctica/Davis",
    value: "Antarctica/Davis",
  },
  {
    display: "UTC+10:00, Antarctica/DumontDUrville",
    value: "Antarctica/DumontDUrville",
  },
  {
    display: "UTC+10:00, Antarctica/Macquarie",
    value: "Antarctica/Macquarie",
  },
  {
    display: "UTC+05:00, Antarctica/Mawson",
    value: "Antarctica/Mawson",
  },
  {
    display: "UTC+12:00, Antarctica/McMurdo",
    value: "Antarctica/McMurdo",
  },
  {
    display: "UTC-03:00, Antarctica/Palmer",
    value: "Antarctica/Palmer",
  },
  {
    display: "UTC-03:00, Antarctica/Rothera",
    value: "Antarctica/Rothera",
  },
  {
    display: "UTC+03:00, Antarctica/Syowa",
    value: "Antarctica/Syowa",
  },
  {
    display: "UTC+02:00, Antarctica/Troll",
    value: "Antarctica/Troll",
  },
  {
    display: "UTC+06:00, Antarctica/Vostok",
    value: "Antarctica/Vostok",
  },
  {
    display: "UTC+02:00, Arctic/Longyearbyen",
    value: "Arctic/Longyearbyen",
  },
  {
    display: "UTC+03:00, Asia/Aden",
    value: "Asia/Aden",
  },
  {
    display: "UTC+06:00, Asia/Almaty",
    value: "Asia/Almaty",
  },
  {
    display: "UTC+03:00, Asia/Amman",
    value: "Asia/Amman",
  },
  {
    display: "UTC+12:00, Asia/Anadyr",
    value: "Asia/Anadyr",
  },
  {
    display: "UTC+05:00, Asia/Aqtau",
    value: "Asia/Aqtau",
  },
  {
    display: "UTC+05:00, Asia/Aqtobe",
    value: "Asia/Aqtobe",
  },
  {
    display: "UTC+05:00, Asia/Ashgabat",
    value: "Asia/Ashgabat",
  },
  {
    display: "UTC+05:00, Asia/Atyrau",
    value: "Asia/Atyrau",
  },
  {
    display: "UTC+03:00, Asia/Baghdad",
    value: "Asia/Baghdad",
  },
  {
    display: "UTC+03:00, Asia/Bahrain",
    value: "Asia/Bahrain",
  },
  {
    display: "UTC+04:00, Asia/Baku",
    value: "Asia/Baku",
  },
  {
    display: "UTC+07:00, Asia/Bangkok",
    value: "Asia/Bangkok",
  },
  {
    display: "UTC+07:00, Asia/Barnaul",
    value: "Asia/Barnaul",
  },
  {
    display: "UTC+03:00, Asia/Beirut",
    value: "Asia/Beirut",
  },
  {
    display: "UTC+06:00, Asia/Bishkek",
    value: "Asia/Bishkek",
  },
  {
    display: "UTC+08:00, Asia/Brunei",
    value: "Asia/Brunei",
  },
  {
    display: "UTC+05:30, Asia/Calcutta",
    value: "Asia/Calcutta",
  },
  {
    display: "UTC+09:00, Asia/Chita",
    value: "Asia/Chita",
  },
  {
    display: "UTC+08:00, Asia/Choibalsan",
    value: "Asia/Choibalsan",
  },
  {
    display: "UTC+05:30, Asia/Colombo",
    value: "Asia/Colombo",
  },
  {
    display: "UTC+03:00, Asia/Damascus",
    value: "Asia/Damascus",
  },
  {
    display: "UTC+06:00, Asia/Dhaka",
    value: "Asia/Dhaka",
  },
  {
    display: "UTC+09:00, Asia/Dili",
    value: "Asia/Dili",
  },
  {
    display: "UTC+04:00, Asia/Dubai",
    value: "Asia/Dubai",
  },
  {
    display: "UTC+05:00, Asia/Dushanbe",
    value: "Asia/Dushanbe",
  },
  {
    display: "UTC+03:00, Asia/Famagusta",
    value: "Asia/Famagusta",
  },
  {
    display: "UTC+03:00, Asia/Gaza",
    value: "Asia/Gaza",
  },
  {
    display: "UTC+03:00, Asia/Hebron",
    value: "Asia/Hebron",
  },
  {
    display: "UTC+08:00, Asia/Hong_Kong",
    value: "Asia/Hong_Kong",
  },
  {
    display: "UTC+07:00, Asia/Hovd",
    value: "Asia/Hovd",
  },
  {
    display: "UTC+08:00, Asia/Irkutsk",
    value: "Asia/Irkutsk",
  },
  {
    display: "UTC+07:00, Asia/Jakarta",
    value: "Asia/Jakarta",
  },
  {
    display: "UTC+09:00, Asia/Jayapura",
    value: "Asia/Jayapura",
  },
  {
    display: "UTC+03:00, Asia/Jerusalem",
    value: "Asia/Jerusalem",
  },
  {
    display: "UTC+04:30, Asia/Kabul",
    value: "Asia/Kabul",
  },
  {
    display: "UTC+12:00, Asia/Kamchatka",
    value: "Asia/Kamchatka",
  },
  {
    display: "UTC+05:00, Asia/Karachi",
    value: "Asia/Karachi",
  },
  {
    display: "UTC+05:45, Asia/Katmandu",
    value: "Asia/Katmandu",
  },
  {
    display: "UTC+09:00, Asia/Khandyga",
    value: "Asia/Khandyga",
  },
  {
    display: "UTC+07:00, Asia/Krasnoyarsk",
    value: "Asia/Krasnoyarsk",
  },
  {
    display: "UTC+08:00, Asia/Kuala_Lumpur",
    value: "Asia/Kuala_Lumpur",
  },
  {
    display: "UTC+08:00, Asia/Kuching",
    value: "Asia/Kuching",
  },
  {
    display: "UTC+03:00, Asia/Kuwait",
    value: "Asia/Kuwait",
  },
  {
    display: "UTC+08:00, Asia/Macau",
    value: "Asia/Macau",
  },
  {
    display: "UTC+11:00, Asia/Magadan",
    value: "Asia/Magadan",
  },
  {
    display: "UTC+08:00, Asia/Makassar",
    value: "Asia/Makassar",
  },
  {
    display: "UTC+08:00, Asia/Manila",
    value: "Asia/Manila",
  },
  {
    display: "UTC+04:00, Asia/Muscat",
    value: "Asia/Muscat",
  },
  {
    display: "UTC+03:00, Asia/Nicosia",
    value: "Asia/Nicosia",
  },
  {
    display: "UTC+07:00, Asia/Novokuznetsk",
    value: "Asia/Novokuznetsk",
  },
  {
    display: "UTC+07:00, Asia/Novosibirsk",
    value: "Asia/Novosibirsk",
  },
  {
    display: "UTC+06:00, Asia/Omsk",
    value: "Asia/Omsk",
  },
  {
    display: "UTC+05:00, Asia/Oral",
    value: "Asia/Oral",
  },
  {
    display: "UTC+07:00, Asia/Phnom_Penh",
    value: "Asia/Phnom_Penh",
  },
  {
    display: "UTC+07:00, Asia/Pontianak",
    value: "Asia/Pontianak",
  },
  {
    display: "UTC+09:00, Asia/Pyongyang",
    value: "Asia/Pyongyang",
  },
  {
    display: "UTC+03:00, Asia/Qatar",
    value: "Asia/Qatar",
  },
  {
    display: "UTC+06:00, Asia/Qostanay",
    value: "Asia/Qostanay",
  },
  {
    display: "UTC+05:00, Asia/Qyzylorda",
    value: "Asia/Qyzylorda",
  },
  {
    display: "UTC+06:30, Asia/Rangoon",
    value: "Asia/Rangoon",
  },
  {
    display: "UTC+03:00, Asia/Riyadh",
    value: "Asia/Riyadh",
  },
  {
    display: "UTC+07:00, Asia/Saigon",
    value: "Asia/Saigon",
  },
  {
    display: "UTC+11:00, Asia/Sakhalin",
    value: "Asia/Sakhalin",
  },
  {
    display: "UTC+05:00, Asia/Samarkand",
    value: "Asia/Samarkand",
  },
  {
    display: "UTC+09:00, Asia/Seoul",
    value: "Asia/Seoul",
  },
  {
    display: "UTC+08:00, Asia/Shanghai",
    value: "Asia/Shanghai",
  },
  {
    display: "UTC+08:00, Asia/Singapore",
    value: "Asia/Singapore",
  },
  {
    display: "UTC+11:00, Asia/Srednekolymsk",
    value: "Asia/Srednekolymsk",
  },
  {
    display: "UTC+08:00, Asia/Taipei",
    value: "Asia/Taipei",
  },
  {
    display: "UTC+05:00, Asia/Tashkent",
    value: "Asia/Tashkent",
  },
  {
    display: "UTC+04:00, Asia/Tbilisi",
    value: "Asia/Tbilisi",
  },
  {
    display: "UTC+03:30, Asia/Tehran",
    value: "Asia/Tehran",
  },
  {
    display: "UTC+06:00, Asia/Thimphu",
    value: "Asia/Thimphu",
  },
  {
    display: "UTC+09:00, Asia/Tokyo",
    value: "Asia/Tokyo",
  },
  {
    display: "UTC+07:00, Asia/Tomsk",
    value: "Asia/Tomsk",
  },
  {
    display: "UTC+08:00, Asia/Ulaanbaatar",
    value: "Asia/Ulaanbaatar",
  },
  {
    display: "UTC+06:00, Asia/Urumqi",
    value: "Asia/Urumqi",
  },
  {
    display: "UTC+10:00, Asia/Ust-Nera",
    value: "Asia/Ust-Nera",
  },
  {
    display: "UTC+07:00, Asia/Vientiane",
    value: "Asia/Vientiane",
  },
  {
    display: "UTC+10:00, Asia/Vladivostok",
    value: "Asia/Vladivostok",
  },
  {
    display: "UTC+09:00, Asia/Yakutsk",
    value: "Asia/Yakutsk",
  },
  {
    display: "UTC+05:00, Asia/Yekaterinburg",
    value: "Asia/Yekaterinburg",
  },
  {
    display: "UTC+04:00, Asia/Yerevan",
    value: "Asia/Yerevan",
  },
  {
    display: "UTC+00:00, Atlantic/Azores",
    value: "Atlantic/Azores",
  },
  {
    display: "UTC-03:00, Atlantic/Bermuda",
    value: "Atlantic/Bermuda",
  },
  {
    display: "UTC+01:00, Atlantic/Canary",
    value: "Atlantic/Canary",
  },
  {
    display: "UTC-01:00, Atlantic/Cape_Verde",
    value: "Atlantic/Cape_Verde",
  },
  {
    display: "UTC+01:00, Atlantic/Faeroe",
    value: "Atlantic/Faeroe",
  },
  {
    display: "UTC+01:00, Atlantic/Madeira",
    value: "Atlantic/Madeira",
  },
  {
    display: "UTC+00:00, Atlantic/Reykjavik",
    value: "Atlantic/Reykjavik",
  },
  {
    display: "UTC-02:00, Atlantic/South_Georgia",
    value: "Atlantic/South_Georgia",
  },
  {
    display: "UTC+00:00, Atlantic/St_Helena",
    value: "Atlantic/St_Helena",
  },
  {
    display: "UTC-03:00, Atlantic/Stanley",
    value: "Atlantic/Stanley",
  },
  {
    display: "UTC+09:30, Australia/Adelaide",
    value: "Australia/Adelaide",
  },
  {
    display: "UTC+10:00, Australia/Brisbane",
    value: "Australia/Brisbane",
  },
  {
    display: "UTC+09:30, Australia/Broken_Hill",
    value: "Australia/Broken_Hill",
  },
  {
    display: "UTC+10:00, Australia/Currie",
    value: "Australia/Currie",
  },
  {
    display: "UTC+09:30, Australia/Darwin",
    value: "Australia/Darwin",
  },
  {
    display: "UTC+08:45, Australia/Eucla",
    value: "Australia/Eucla",
  },
  {
    display: "UTC+10:00, Australia/Hobart",
    value: "Australia/Hobart",
  },
  {
    display: "UTC+10:00, Australia/Lindeman",
    value: "Australia/Lindeman",
  },
  {
    display: "UTC+10:30, Australia/Lord_Howe",
    value: "Australia/Lord_Howe",
  },
  {
    display: "UTC+10:00, Australia/Melbourne",
    value: "Australia/Melbourne",
  },
  {
    display: "UTC+08:00, Australia/Perth",
    value: "Australia/Perth",
  },
  {
    display: "UTC+10:00, Australia/Sydney",
    value: "Australia/Sydney",
  },
  {
    display: "UTC+02:00, Europe/Amsterdam",
    value: "Europe/Amsterdam",
  },
  {
    display: "UTC+02:00, Europe/Andorra",
    value: "Europe/Andorra",
  },
  {
    display: "UTC+04:00, Europe/Astrakhan",
    value: "Europe/Astrakhan",
  },
  {
    display: "UTC+03:00, Europe/Athens",
    value: "Europe/Athens",
  },
  {
    display: "UTC+02:00, Europe/Belgrade",
    value: "Europe/Belgrade",
  },
  {
    display: "UTC+02:00, Europe/Berlin",
    value: "Europe/Berlin",
  },
  {
    display: "UTC+02:00, Europe/Bratislava",
    value: "Europe/Bratislava",
  },
  {
    display: "UTC+02:00, Europe/Brussels",
    value: "Europe/Brussels",
  },
  {
    display: "UTC+03:00, Europe/Bucharest",
    value: "Europe/Bucharest",
  },
  {
    display: "UTC+02:00, Europe/Budapest",
    value: "Europe/Budapest",
  },
  {
    display: "UTC+02:00, Europe/Busingen",
    value: "Europe/Busingen",
  },
  {
    display: "UTC+03:00, Europe/Chisinau",
    value: "Europe/Chisinau",
  },
  {
    display: "UTC+02:00, Europe/Copenhagen",
    value: "Europe/Copenhagen",
  },
  {
    display: "UTC+01:00, Europe/Dublin",
    value: "Europe/Dublin",
  },
  {
    display: "UTC+02:00, Europe/Gibraltar",
    value: "Europe/Gibraltar",
  },
  {
    display: "UTC+01:00, Europe/Guernsey",
    value: "Europe/Guernsey",
  },
  {
    display: "UTC+03:00, Europe/Helsinki",
    value: "Europe/Helsinki",
  },
  {
    display: "UTC+01:00, Europe/Isle_of_Man",
    value: "Europe/Isle_of_Man",
  },
  {
    display: "UTC+03:00, Europe/Istanbul",
    value: "Europe/Istanbul",
  },
  {
    display: "UTC+01:00, Europe/Jersey",
    value: "Europe/Jersey",
  },
  {
    display: "UTC+02:00, Europe/Kaliningrad",
    value: "Europe/Kaliningrad",
  },
  {
    display: "UTC+03:00, Europe/Kiev",
    value: "Europe/Kiev",
  },
  {
    display: "UTC+03:00, Europe/Kirov",
    value: "Europe/Kirov",
  },
  {
    display: "UTC+01:00, Europe/Lisbon",
    value: "Europe/Lisbon",
  },
  {
    display: "UTC+02:00, Europe/Ljubljana",
    value: "Europe/Ljubljana",
  },
  {
    display: "UTC+01:00, Europe/London",
    value: "Europe/London",
  },
  {
    display: "UTC+02:00, Europe/Luxembourg",
    value: "Europe/Luxembourg",
  },
  {
    display: "UTC+02:00, Europe/Madrid",
    value: "Europe/Madrid",
  },
  {
    display: "UTC+02:00, Europe/Malta",
    value: "Europe/Malta",
  },
  {
    display: "UTC+03:00, Europe/Mariehamn",
    value: "Europe/Mariehamn",
  },
  {
    display: "UTC+03:00, Europe/Minsk",
    value: "Europe/Minsk",
  },
  {
    display: "UTC+02:00, Europe/Monaco",
    value: "Europe/Monaco",
  },
  {
    display: "UTC+03:00, Europe/Moscow",
    value: "Europe/Moscow",
  },
  {
    display: "UTC+02:00, Europe/Oslo",
    value: "Europe/Oslo",
  },
  {
    display: "UTC+02:00, Europe/Paris",
    value: "Europe/Paris",
  },
  {
    display: "UTC+02:00, Europe/Podgorica",
    value: "Europe/Podgorica",
  },
  {
    display: "UTC+02:00, Europe/Prague",
    value: "Europe/Prague",
  },
  {
    display: "UTC+03:00, Europe/Riga",
    value: "Europe/Riga",
  },
  {
    display: "UTC+02:00, Europe/Rome",
    value: "Europe/Rome",
  },
  {
    display: "UTC+04:00, Europe/Samara",
    value: "Europe/Samara",
  },
  {
    display: "UTC+02:00, Europe/San_Marino",
    value: "Europe/San_Marino",
  },
  {
    display: "UTC+02:00, Europe/Sarajevo",
    value: "Europe/Sarajevo",
  },
  {
    display: "UTC+04:00, Europe/Saratov",
    value: "Europe/Saratov",
  },
  {
    display: "UTC+03:00, Europe/Simferopol",
    value: "Europe/Simferopol",
  },
  {
    display: "UTC+02:00, Europe/Skopje",
    value: "Europe/Skopje",
  },
  {
    display: "UTC+03:00, Europe/Sofia",
    value: "Europe/Sofia",
  },
  {
    display: "UTC+02:00, Europe/Stockholm",
    value: "Europe/Stockholm",
  },
  {
    display: "UTC+03:00, Europe/Tallinn",
    value: "Europe/Tallinn",
  },
  {
    display: "UTC+02:00, Europe/Tirane",
    value: "Europe/Tirane",
  },
  {
    display: "UTC+04:00, Europe/Ulyanovsk",
    value: "Europe/Ulyanovsk",
  },
  {
    display: "UTC+03:00, Europe/Uzhgorod",
    value: "Europe/Uzhgorod",
  },
  {
    display: "UTC+02:00, Europe/Vaduz",
    value: "Europe/Vaduz",
  },
  {
    display: "UTC+02:00, Europe/Vatican",
    value: "Europe/Vatican",
  },
  {
    display: "UTC+02:00, Europe/Vienna",
    value: "Europe/Vienna",
  },
  {
    display: "UTC+03:00, Europe/Vilnius",
    value: "Europe/Vilnius",
  },
  {
    display: "UTC+03:00, Europe/Volgograd",
    value: "Europe/Volgograd",
  },
  {
    display: "UTC+02:00, Europe/Warsaw",
    value: "Europe/Warsaw",
  },
  {
    display: "UTC+02:00, Europe/Zagreb",
    value: "Europe/Zagreb",
  },
  {
    display: "UTC+03:00, Europe/Zaporozhye",
    value: "Europe/Zaporozhye",
  },
  {
    display: "UTC+02:00, Europe/Zurich",
    value: "Europe/Zurich",
  },
  {
    display: "UTC+03:00, Indian/Antananarivo",
    value: "Indian/Antananarivo",
  },
  {
    display: "UTC+06:00, Indian/Chagos",
    value: "Indian/Chagos",
  },
  {
    display: "UTC+07:00, Indian/Christmas",
    value: "Indian/Christmas",
  },
  {
    display: "UTC+06:30, Indian/Cocos",
    value: "Indian/Cocos",
  },
  {
    display: "UTC+03:00, Indian/Comoro",
    value: "Indian/Comoro",
  },
  {
    display: "UTC+05:00, Indian/Kerguelen",
    value: "Indian/Kerguelen",
  },
  {
    display: "UTC+04:00, Indian/Mahe",
    value: "Indian/Mahe",
  },
  {
    display: "UTC+05:00, Indian/Maldives",
    value: "Indian/Maldives",
  },
  {
    display: "UTC+04:00, Indian/Mauritius",
    value: "Indian/Mauritius",
  },
  {
    display: "UTC+03:00, Indian/Mayotte",
    value: "Indian/Mayotte",
  },
  {
    display: "UTC+04:00, Indian/Reunion",
    value: "Indian/Reunion",
  },
  {
    display: "UTC+13:00, Pacific/Apia",
    value: "Pacific/Apia",
  },
  {
    display: "UTC+12:00, Pacific/Auckland",
    value: "Pacific/Auckland",
  },
  {
    display: "UTC+11:00, Pacific/Bougainville",
    value: "Pacific/Bougainville",
  },
  {
    display: "UTC+12:45, Pacific/Chatham",
    value: "Pacific/Chatham",
  },
  {
    display: "UTC-06:00, Pacific/Easter",
    value: "Pacific/Easter",
  },
  {
    display: "UTC+11:00, Pacific/Efate",
    value: "Pacific/Efate",
  },
  {
    display: "UTC+13:00, Pacific/Enderbury",
    value: "Pacific/Enderbury",
  },
  {
    display: "UTC+13:00, Pacific/Fakaofo",
    value: "Pacific/Fakaofo",
  },
  {
    display: "UTC+12:00, Pacific/Fiji",
    value: "Pacific/Fiji",
  },
  {
    display: "UTC+12:00, Pacific/Funafuti",
    value: "Pacific/Funafuti",
  },
  {
    display: "UTC-06:00, Pacific/Galapagos",
    value: "Pacific/Galapagos",
  },
  {
    display: "UTC-09:00, Pacific/Gambier",
    value: "Pacific/Gambier",
  },
  {
    display: "UTC+11:00, Pacific/Guadalcanal",
    value: "Pacific/Guadalcanal",
  },
  {
    display: "UTC+10:00, Pacific/Guam",
    value: "Pacific/Guam",
  },
  {
    display: "UTC-10:00, Pacific/Honolulu",
    value: "Pacific/Honolulu",
  },
  {
    display: "UTC-10:00, Pacific/Johnston",
    value: "Pacific/Johnston",
  },
  {
    display: "UTC+14:00, Pacific/Kiritimati",
    value: "Pacific/Kiritimati",
  },
  {
    display: "UTC+11:00, Pacific/Kosrae",
    value: "Pacific/Kosrae",
  },
  {
    display: "UTC+12:00, Pacific/Kwajalein",
    value: "Pacific/Kwajalein",
  },
  {
    display: "UTC+12:00, Pacific/Majuro",
    value: "Pacific/Majuro",
  },
  {
    display: "UTC-09:30, Pacific/Marquesas",
    value: "Pacific/Marquesas",
  },
  {
    display: "UTC-11:00, Pacific/Midway",
    value: "Pacific/Midway",
  },
  {
    display: "UTC+12:00, Pacific/Nauru",
    value: "Pacific/Nauru",
  },
  {
    display: "UTC-11:00, Pacific/Niue",
    value: "Pacific/Niue",
  },
  {
    display: "UTC+11:00, Pacific/Norfolk",
    value: "Pacific/Norfolk",
  },
  {
    display: "UTC+11:00, Pacific/Noumea",
    value: "Pacific/Noumea",
  },
  {
    display: "UTC-11:00, Pacific/Pago_Pago",
    value: "Pacific/Pago_Pago",
  },
  {
    display: "UTC+09:00, Pacific/Palau",
    value: "Pacific/Palau",
  },
  {
    display: "UTC-08:00, Pacific/Pitcairn",
    value: "Pacific/Pitcairn",
  },
  {
    display: "UTC+11:00, Pacific/Ponape",
    value: "Pacific/Ponape",
  },
  {
    display: "UTC+10:00, Pacific/Port_Moresby",
    value: "Pacific/Port_Moresby",
  },
  {
    display: "UTC-10:00, Pacific/Rarotonga",
    value: "Pacific/Rarotonga",
  },
  {
    display: "UTC+10:00, Pacific/Saipan",
    value: "Pacific/Saipan",
  },
  {
    display: "UTC-10:00, Pacific/Tahiti",
    value: "Pacific/Tahiti",
  },
  {
    display: "UTC+12:00, Pacific/Tarawa",
    value: "Pacific/Tarawa",
  },
  {
    display: "UTC+13:00, Pacific/Tongatapu",
    value: "Pacific/Tongatapu",
  },
  {
    display: "UTC+10:00, Pacific/Truk",
    value: "Pacific/Truk",
  },
  {
    display: "UTC+12:00, Pacific/Wake",
    value: "Pacific/Wake",
  },
  {
    display: "UTC+12:00, Pacific/Wallis",
    value: "Pacific/Wallis",
  },
];
