<script setup lang="ts">
import type { Translator as TranslatorType } from "@/stores/types/users-types";

import Translator from "./Translator.vue";
import AssignExpandedPanel from "@/components/AssignExpandedPanel.vue";
import { useCabinetsStore } from "@/stores/cabinets-store";

const props = defineProps<{
  translators: TranslatorType[];
  operatorsTab?: boolean;
}>();
const cabinetsStore = useCabinetsStore();
</script>

<template>
  <div>
    <div
      class="translator"
      :class="{ 'translators-tab': operatorsTab }"
      v-for="translator of props.translators"
      :key="translator.email"
    >
      <translator :id-translator="translator._id"></translator>
      <template
        v-if="cabinetsStore.getCabinetsByOwnerId(translator._id).length"
      >
        <div
          v-for="cabinet of cabinetsStore.getCabinetsByOwnerId(translator._id)"
          :key="cabinet._id"
        >
          <v-divider />
          <div class="cabinet-item">
            <span class="cabinet-title"> Cabinet </span>
            <span> {{ cabinet.name }}</span>
          </div>
          <assign-expanded-panel :source="cabinet"></assign-expanded-panel>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.translators-tab {
  min-height: 80px;
  display: flex;
  padding: var(--indent-m);
  border-radius: 6px;
  margin: 0 0 var(--indent-l);
  background: #fff;
  flex-direction: column;
  justify-content: center;
  row-gap: var(--indent-m);
  border: 1px solid #e8eaf5;
}

.translator {
  margin-bottom: 16px;

  .header {
    display: flex;
  }

  .v-expansion-panels {
    margin-top: var(--indent-m);
  }

  .actions {
    margin-inline-start: var(--indent-xl);
    display: flex;
    column-gap: var(--indent-m);
  }

  .panel-wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: space-between;
  }

  .panel-title {
    column-gap: var(--indent-m);
  }

  .cabinet-item {
    padding-top: 16px;

    .cabinet-title {
      font-weight: bold;
    }
  }
}
</style>
