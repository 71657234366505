<script lang="ts" setup>
import { MENU_CONTAINER_ID } from "@/core/pages-manager";
import MenuItem from "@/components/MenuItem.vue";
import UsersIcon from "@/components/icons/UsersIcon.vue";
import { useAuthStore } from "@/stores/auth-store";
import AuthAlert from "@/components/AuthAlert.vue";
import UpdateAlert from "@/components/UpdateAlert.vue";
import { useAppStore } from "@/stores/app-store";
import { useCurrentPageStore } from "@/stores/current-page-store";
import { ModalsContainer } from "vue-final-modal";

const teleportSelector = `#${MENU_CONTAINER_ID}`;
const authStore = useAuthStore();
const appStore = useAppStore();
const pageStore = useCurrentPageStore();

appStore.getCodeVersion();
</script>

<template>
  <teleport :to="teleportSelector">
    <menu-item to="/structure">
      <users-icon></users-icon>
      Chatterly
    </menu-item>
  </teleport>
  <v-toolbar :color="'primary'" class="toolbar-padding">
    <div class="sub-navigation">
      <v-btn
        :variant="pageStore.isStructurePage ? 'outlined' : 'text'"
        :to="'/structure'"
      >
        Structure
      </v-btn>
      <v-btn
        :variant="pageStore.isManagersPage ? 'outlined' : 'text'"
        :to="'/managers'"
      >
        Managers
      </v-btn>
      <v-btn
        :variant="pageStore.isOperatorsPage ? 'outlined' : 'text'"
        :to="'/operators'"
      >
        Operators
      </v-btn>
    </div>
    <h1 class="text-h5" v-if="appStore.isDemoPointed">DEMO</h1>
  </v-toolbar>
  <div class="loader-wrapper" v-if="authStore.isLoading">
    <v-progress-circular size="50" :width="5" color="primary" indeterminate />
  </div>
  <UpdateAlert v-else-if="!appStore.isCodeCompatibility" />
  <router-view
    v-else-if="authStore.loggedIn && pageStore.isExtensionPage"
  ></router-view>
  <AuthAlert v-else-if="pageStore.isExtensionPage" />
  <ModalsContainer />
</template>

<style lang="scss">
.loader-wrapper {
  display: flex;
  justify-content: center;
  padding: 30%;
}

.toolbar-padding {
  padding: 0 16px 0 16px;
}

.sub-navigation {
  display: flex;
  gap: 16px;
  width: 100%;
}

button.v-btn:focus {
  outline: none;
}
</style>
