import { Http } from "@/core/api";
import type { UserFormState } from "@/components/UserForm/user-form-types";
import type { ResponseUser, UpdateUser } from "@/stores/types/users-types";

class ApiOperator {
  getOperators(): Promise<ResponseUser[]> {
    return Http.get<ResponseUser[]>("/operator/all");
  }

  createOperators(
    data: UserFormState
  ): Promise<{ _id: string; idParent: string }> {
    return Http.post<{ _id: string; idParent: string }>(
      "/operator/create",
      data
    );
  }

  updateOperator(id: string, data: UpdateUser): Promise<boolean> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { email, ...dataWithoutEmail } = data;

    return Http.post<boolean>("/operator/update", {
      id,
      ...dataWithoutEmail,
    });
  }

  deleteOperator(id: string): Promise<boolean> {
    return Http.post<boolean>("/operator/remove", { id });
  }

  logoutOperator(id: string): Promise<{ status: boolean }> {
    return Http.post<{ status: boolean }>("/operator/remove-sessions", { id });
  }
}

export default new ApiOperator();
