import {
  ErrorTrackingService,
  localStorageGet,
  setToLocalStorage,
  type BaseCredentials,
  type LoginData,
} from "@/core";
import { ApiAuth } from "@/shared/api";
import { defineStore } from "pinia";
import AnalystService from "@/core/services/AnalystService";
import { useUserProfileStore } from "@/stores/user-profile-store";
import { environment } from "@/envinronment";

export const useAuthStore = defineStore("auth", {
  state: () => {
    const cache = localStorageGet({
      key: "auth",
      defaultValue: {
        expirationTime: 0,
        token: "",
        appToken: "",
      },
    });

    return {
      ...cache,
      loading: false,
    };
  },
  getters: {
    loggedIn: (state) => !!state.token && !!state.appToken,
    isLoading: (state) => state.loading,
  },
  actions: {
    logout(): void {
      this.expirationTime = 0;
      this.token = "";
      this.appToken = "";
      this.cacheState();
    },
    cacheState({
      expirationTime = 0,
      token = "",
      appToken = "",
    }: {
      expirationTime?: number;
      token?: string;
      appToken?: string;
    } = {}): void {
      this.expirationTime = expirationTime;
      this.token = token;
      this.appToken = appToken;
      setToLocalStorage({ key: "auth", value: this.$state });
    },
    initAuthState(): void {
      this.loading = true;
    },
    async checkAuth() {
      try {
        await ApiAuth.checkAuth();
      } catch (e) {
        this.cacheState();
      }
    },
    auth(options: { email: string; pass: string }): Promise<void> {
      let authFunc: (credentials: BaseCredentials) => Promise<LoginData>;
      if (environment.standAloneMode) {
        authFunc = ApiAuth.authByCredentialsManager;
      } else {
        authFunc = ApiAuth.authByCredentialsAdmin;
      }
      return authFunc(options)
        .then(
          (res: LoginData) => {
            this.cacheState({
              expirationTime: res.expirationTime,
              token: res.token,
              appToken: res.info.token,
            });
            useUserProfileStore().setProfile(res);
            const idAgency = res.info.data?.idAgency.toString();
            const idRegion = res.info.idRegion.toString();
            AnalystService.pushLoginEvent(idAgency);
            AnalystService.initializeUserIdRegion(idRegion);
            localStorage.setItem("idAgency", idAgency);
            ErrorTrackingService.setAgencyMetadata();
          },
          (err: any) => {
            console.error(err);
            this.cacheState({
              expirationTime: new Date().getTime(),
              token: undefined,
              appToken: undefined,
            });
            throw err;
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },
    authByToken(token: string): Promise<void> {
      return ApiAuth.authByToken(token).then(
        (res) => {
          this.cacheState({
            expirationTime: res.expirationTime,
            token: res.token,
            appToken: res.info.token,
          });
          localStorage.setItem("login_data", JSON.stringify(res));
          ErrorTrackingService.setAgencyMetadata();
          this.loading = false;
        },
        (err) => {
          console.error(err);
          this.loading = false;
          this.cacheState({
            expirationTime: new Date().getTime(),
            token: undefined,
            appToken: undefined,
          });
        }
      );
    },
  },
});
