import type { PluginOptions } from "vue-toastification";
import { POSITION } from "vue-toastification";

export const TOAST_OPTIONS: PluginOptions = {
  timeout: 5000,
  position: POSITION.BOTTOM_CENTER,
  transition: "enter",
  hideProgressBar: true,
  toastClassName: "extension-toast",
};
