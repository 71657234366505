<script lang="ts" setup>
import { useWorkShiftStore } from "@/stores/work-shift-store";
import { defineProps, reactive } from "vue";
import type { WorkShiftUI } from "@/stores/types/work-shift-types";
import { useToast } from "vue-toastification";
import type { WorkShiftCrossingRes } from "@/stores/types/work-shift-types";
import { useCrossingOperators } from "@/components/CrossingOperators/crossing-operatrors";
import CrossingOperators from "@/components/CrossingOperators/CrossingOperators.vue";
import AnalystService from "@/core/services/AnalystService";
import { WORK_SHIFTS_ERROR_ACTION } from "@/core/enums/amplitude-event.enum";
import type { UseModalReturnType } from "vue-final-modal";
import type { WorkShiftCrossing } from "@/stores/types/work-shift-types";
import ModalService from "@/core/services/ModalService";

const props = defineProps<{
  operatorId: string;
  alreadyAssign: string;
  close: () => void;
}>();

const toast = useToast();

const {
  crossingOperatorsState,
  hideCrossingOperatorsModal,
  openCrossingOperatorsModal,
} = useCrossingOperators();

const workShiftStore = useWorkShiftStore();

const assignWorkShiftState = reactive<{
  state: boolean;
  loading: boolean;
  workShifts: WorkShiftUI[];
  _workShifts: WorkShiftUI[];
}>({
  state: false,
  loading: false,
  _workShifts: [],
  get workShifts(): WorkShiftUI[] {
    return this._workShifts;
  },
  set workShifts(data: WorkShiftUI[]) {
    this._workShifts = data;
  },
});

const openModal = (): void => {
  assignWorkShiftState.state = true;
  assignWorkShiftState.workShifts = mapWorkShifts();
};

const hideModal = (): void => {
  assignWorkShiftState.state = false;
  assignWorkShiftState.workShifts = [];
  props.close();
};

const onWorkShiftSelect = (index: number): void => {
  resetWorkShiftSelection(index);

  assignWorkShiftState.workShifts[index].selected =
    !assignWorkShiftState.workShifts[index].selected;
};

const isAssignBtnDisabled = (): boolean => {
  return (
    !assignWorkShiftState.workShifts.some(
      (workShift) => !!workShift.selected
    ) || assignWorkShiftState.loading
  );
};

const mapWorkShifts = (): WorkShiftUI[] => {
  return workShiftStore.allAvailable(props.operatorId).map((workShift) => ({
    ...workShift,
    selected: false,
  }));
};

const resetWorkShiftSelection = (index: number): void => {
  assignWorkShiftState.workShifts = assignWorkShiftState.workShifts.map(
    (workShift, i) => {
      if (index === i) {
        return workShift;
      }
      return {
        ...workShift,
        selected: false,
      };
    }
  );
};

const getSelectedWorkShift = (): WorkShiftUI => {
  return assignWorkShiftState.workShifts.find(
    (workShift) => !!workShift.selected
  ) as WorkShiftUI;
};

const assignWorkShift = async (): Promise<void> => {
  assignWorkShiftState.loading = true;
  try {
    const workShift = getSelectedWorkShift();
    const isReassign = props.alreadyAssign !== "--";
    await workShiftStore.assignWorkShift(
      props.operatorId,
      workShift._id,
      isReassign
    );
    assignWorkShiftState.loading = false;
    hideModal();
    toast.success("Work shift was assigned");
  } catch (e) {
    assignWorkShiftState.loading = false;
    const { crossingOperators } = e as WorkShiftCrossingRes;
    if (crossingOperators) {
      AnalystService.pushWorkShiftErrorEvent({
        action: WORK_SHIFTS_ERROR_ACTION.ASSIGN,
      });
      return catchCrossingOperator(crossingOperators);
    }
    toast.error("Something went wrong during assigning work shift");
  }
};

let crossingOperatorModal: UseModalReturnType<any>;

function catchCrossingOperator(crossingOperators: WorkShiftCrossing[]) {
  openCrossingOperatorsModal(crossingOperators);
  crossingOperatorModal = ModalService.init(CrossingOperators, {
    isAssign: true,
    data: crossingOperatorsState.crossingOperators,
    cancel() {
      closeCrossingOperatorModal();
    },
  });
  crossingOperatorModal.open();
}

function closeCrossingOperatorModal() {
  hideCrossingOperatorsModal();
  crossingOperatorModal.close();
}

openModal();
</script>
<template>
  <v-card title="ASSIGN WORK SHIFT" class="assign-work-shift__content">
    <v-card-content>
      <div class="assign-work-shift">
        <template v-if="assignWorkShiftState.workShifts.length">
          <div
            :class="{
              'assign-work-shift__item': true,
              'assign-work-shift__item--selected': workShift.selected,
            }"
            v-for="(workShift, index) in assignWorkShiftState.workShifts"
            :key="workShift._id"
          >
            <div class="assign-work-shift__action-row">
              <div>
                <span style="font-weight: bold">{{ workShift.name }}</span>
                {{ workShift.startTime }} - {{ workShift.endTime }},
                {{ workShift.timeZone }}
              </div>
              <div @click="onWorkShiftSelect(index)">
                <v-checkbox
                  color="primary"
                  class="assign-work-shift__check-box"
                  hide-details="auto"
                  v-model="workShift.selected"
                ></v-checkbox>
              </div>
            </div>
            <v-divider />
          </div>
        </template>
        <template v-if="!assignWorkShiftState.workShifts.length">
          <div class="empty-container__wrapper">
            <div class="empty-container__item">
              No available work shifts found
            </div>
          </div>
        </template>
      </div>
    </v-card-content>
    <v-card-actions class="assign-work-shift__btn-row">
      <v-btn @click="hideModal()">Cancel</v-btn>
      <v-btn
        color="primary"
        :disabled="isAssignBtnDisabled()"
        @click="assignWorkShift"
      >
        Assign Work Shift
        <v-progress-circular
          v-if="assignWorkShiftState.loading"
          indeterminate
        ></v-progress-circular>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<style scoped lang="scss">
.assign-work-shift {
  padding: 0 20px;
  overflow-y: auto;
  max-height: 80vh;

  &__content {
    margin-bottom: 52px;
  }

  &__check-box {
    cursor: pointer;
  }

  &__item {
    margin-bottom: 10px;
    display: flex;
    border-radius: 3px;
    background: #fff;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #e8eaf5;

    &--selected {
      border: 2px solid #5c81dc;
    }
  }

  &__action-row {
    padding: 0 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__btn-row {
    display: flex;
    justify-content: end;
    z-index: 9999;
    bottom: 0;
    background: #ffffff;
    width: 100%;
  }

  &__check-box {
    font-size: 20px;
  }
}
</style>
