<script setup lang="ts">
import type { Operator } from "@/stores/types/users-types";
import { useDeleteUser } from "@/components/UserForm/delete-user";
import { computed, watch } from "vue";
import OperatorCmp from "@/components/OperatorCmp.vue";
import DeleteUnit from "./DeleteUnit.vue";
import {
  PROFILES_CONTAINER_HEADER,
  PROFILES_CONTAINER_HEADER_MODAL,
} from "@/shared/constants/table-header";
import { useUsersStore } from "@/stores/users-store";
import { TU_TYPE_ENUM } from "@/core/enums/tu-type.enum";
import ModalService from "@/core/services/ModalService";

const props = defineProps<{
  operatorIds: Operator["idTU"][];
  parentId?: string;
  parentLabel: string;
  search?: boolean;
  cabinetMode?: boolean;
  structureModal?: boolean;
}>();
const { deleteState, openDeleteModal, onDeleteOperator, hideDeleteModal } =
  useDeleteUser<Operator>();
const usersStore = useUsersStore();
const operators = computed(
  () =>
    props.operatorIds
      .map((id: number) => usersStore.getOperatorByOriginalId(id))
      .filter((item) => item !== undefined) as Operator[]
);
const fetchTUStatus = () => {
  usersStore.fetchTUStatus(
    operators.value
      .filter(
        (tu) =>
          tu.tuType === TU_TYPE_ENUM.ASSIGNED && tu.isDeactivated === undefined
      )
      .map(({ idTU }) => idTU)
  );
};
const headers = props.structureModal
  ? PROFILES_CONTAINER_HEADER_MODAL
  : PROFILES_CONTAINER_HEADER;
const emit = defineEmits(["scroll-end"]);
const deleteOperatorModal = ModalService.init(DeleteUnit, {
  deleteUnit() {
    onDeleteOperator().then(() => closeOperatorDelete());
  },
  deleteStateUser: deleteState,
  hideDeleteModal() {
    closeOperatorDelete();
  },
});

function openOperatorDelete(item: Operator, parentId: string | undefined) {
  openDeleteModal(item, parentId);
  deleteOperatorModal.open();
}

function closeOperatorDelete() {
  deleteOperatorModal.close();
  hideDeleteModal();
}
function isTURemoved(operator: Operator): boolean {
  return operator.age === 0 || operator.isDeactivated === true;
}

function showScrollEnd() {
  emit("scroll-end");
}

watch(
  () => props.operatorIds,
  () => {
    fetchTUStatus();
  }
);
</script>
<template>
  <div :class="{ 'operators-body': true, empty: !props.operatorIds.length }">
    <RecycleScroller
      ref="scroller"
      class="extension-virtual-scroller"
      :items="operators"
      :item-size="48"
      :key-field="'idTU'"
      @scroll-end="showScrollEnd"
    >
      <template #before>
        <div
          v-if="operators.length"
          class="row row-header"
          :class="{ 'row-structure-modal': structureModal }"
        >
          <div
            v-for="header in headers"
            :key="header.display"
            :class="['cell', header.class]"
          >
            {{ header.display }}
          </div>
        </div>
      </template>
      <template #default="{ item }">
        <OperatorCmp
          :key="item._id"
          :item="item"
          :is-tu-removed="isTURemoved"
          :structure-modal="props.structureModal"
        >
          <div v-if="!props.structureModal">
            <v-tooltip text="Delete Profile" location="top">
              <template #activator="tooltip">
                <v-btn
                  v-bind="tooltip.props"
                  v-if="
                    (props.parentId && !props.cabinetMode) ||
                    (!props.parentId && item?.tuType === TU_TYPE_ENUM.EXTERNAL)
                  "
                  size="x-small"
                  @click="openOperatorDelete(item, props.parentId)"
                  :color="isTURemoved(item) ? 'blue-grey' : 'error'"
                  icon="mdi-trash-can"
                ></v-btn>
              </template>
            </v-tooltip>
          </div>
        </OperatorCmp>
      </template>
    </RecycleScroller>
    <div
      v-if="!props.operatorIds.length"
      class="empty-container d-flex flex-column align-center"
    >
      <div v-if="!props.search || usersStore.operators.loading">
        {{ `${props.parentLabel} profiles container` }}
      </div>
      <div v-else-if="props.search && !usersStore.operators.loading">
        No profiles found or profiles do not match search criteria
      </div>
      <v-progress-linear
        v-if="usersStore.operators.loading"
        class="mt-3"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
    </div>
  </div>
</template>

<style lang="scss">
.switch {
  grid-template-areas: initial;
  pointer-events: none;
  grid-template-columns: initial;
  height: 56px;
}

.operators-body {
  &.empty {
    display: block;
    min-height: 60px;
    position: relative;
    border: 1px dashed var(--border-color);

    .empty-container {
      position: absolute;
      left: 0;
      color: var(--border-color);
      top: 0;
      width: 100%;
      height: 100%;
      font-size: 20px;
      text-align: center;
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
<style lang="scss" scoped>
.extension-virtual-scroller {
  max-height: 345px;

  .row {
    grid-template-columns: 1fr 1fr 2.5fr 1fr 1.25fr;
  }
  .row-structure-modal {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>
