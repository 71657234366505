import { createApp } from "vue";
import { createPinia } from "pinia";
import vuetify from "@/plugins/vuetify";
import Bugsnag from "@bugsnag/js";
import Toast from "vue-toastification";
import { createRouter, createWebHistory } from "vue-router";

import AppSPA from "@/AppSPA.vue";
import { useAuthStore } from "@/stores/auth-store";
import { SPA_ROUTES } from "@/core/index";
import type { App as VueApp } from "vue";
import { TOAST_OPTIONS } from "@/core/constants/toast-options";
import VueVirtualScroller from "vue-virtual-scroller";
import SupportChatService from "./services/SupportChatService";
import { createVfm } from "vue-final-modal";

class AppSPAService {
  app: VueApp | null = null;

  async init(): Promise<void> {
    this.main();
    await useAuthStore().checkAuth();
    this.app?.mount("#app");
  }

  private main() {
    this.app = createApp(AppSPA);

    const router = createRouter({
      history: createWebHistory(),
      routes: SPA_ROUTES,
    });
    router.beforeEach(async (to) => {
      if (!useAuthStore().loggedIn && to.path !== "/login") {
        return "/login";
      }
      if (useAuthStore().loggedIn && to.path === "/login") {
        return "/structure";
      }
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    this.app.use(Bugsnag.getPlugin("vue"));
    this.app.use(createPinia());
    this.app.use(router);
    this.app.use(vuetify);
    this.app.use(Toast, TOAST_OPTIONS);
    this.app.use(VueVirtualScroller);
    this.app.use(createVfm());

    SupportChatService.init();
  }
}

export default new AppSPAService();
