import { reactive } from "vue";
import { useCabinetsStore } from "@/stores/cabinets-store";

export function useEditCabinet() {
  const cabinetsStore = useCabinetsStore();

  const editModalState = reactive<{
    state: boolean;
    id: string | null;
  }>({
    state: false,
    id: null,
  });

  const hideEditModal = (): void => {
    editModalState.state = false;
    editModalState.id = null;
  };

  const openEditModal = (id: string): void => {
    editModalState.state = true;
    editModalState.id = id;
  };

  const editCabinet = async (name: string): Promise<void> => {
    const { id } = editModalState;

    await cabinetsStore.editCabinet(name, id as string);

    hideEditModal();
  };

  return {
    editModalState,
    hideEditModal,
    openEditModal,
    editCabinet,
  };
}
