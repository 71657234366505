<script lang="ts" setup>
import { useCurrentPageStore } from "@/stores/current-page-store";

const props = defineProps({ to: { type: String, default: "" } });
const pageStore = useCurrentPageStore();
</script>
<template>
  <router-link
    :class="{ 'is-active': pageStore.isExtensionPage }"
    class="menu-item-link"
    :to="props.to + '?p=' + pageStore.appPage + new Date().getTime()"
  >
    <slot></slot>
  </router-link>
</template>
<style lang="scss">
.menu-item-link {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 8px 0 22px;
  cursor: pointer;
  color: #595959;
  font-size: 14px;
  font-weight: 300;
  line-height: 48px;

  &:hover {
    background: #f5f7ff;
  }

  &.is-active {
    transition: all 0.3s ease;
    color: #333;
    background: #f5f7ff;
  }

  svg {
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-right: 16px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    fill: #595959;
  }
}

.is-small {
  .menu-item-link {
    color: transparent;
  }
}
</style>
