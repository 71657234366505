export type TableHeader = {
  display: string;
  class: string;
};

export const ADD_PROFILES_HEADER: Array<TableHeader> = [
  {
    display: "Select",
    class: "select",
  },
  {
    display: "ID",
    class: "id-tu",
  },
  {
    display: "Avatar",
    class: "avatar",
  },
  {
    display: "Name",
    class: "name",
  },
  {
    display: "Age",
    class: "age",
  },
  {
    display: "Password",
    class: "password",
  },
];

export const PROFILES_CONTAINER_HEADER: Array<TableHeader> = [
  {
    display: "ID",
    class: "id-tu",
  },
  {
    display: "Avatar",
    class: "avatar",
  },
  {
    display: "Name",
    class: "name",
  },
  {
    display: "Age",
    class: "age",
  },
  {
    display: "Actions",
    class: "actions",
  },
];

export const PROFILES_CONTAINER_HEADER_MODAL: Array<TableHeader> = [
  {
    display: "ID",
    class: "id-tu",
  },
  {
    display: "Avatar",
    class: "avatar",
  },
  {
    display: "Name",
    class: "name",
  },
];

export const STRUCTURE_ADMIN_CONTAINER_HEADER: Array<TableHeader> = [
  {
    display: "Manager",
    class: "manager",
  },
  {
    display: "Operator",
    class: "translator",
  },
  {
    display: "Profiles",
    class: "profiles",
  },
  {
    display: "Cabinet",
    class: "cabinet",
  },
  {
    display: "Work shift",
    class: "workShift",
  },
];

export const STRUCTURE_MANAGER_CONTAINER_HEADER: Array<TableHeader> = [
  {
    display: "Operator",
    class: "translator",
  },
  {
    display: "Profiles",
    class: "profiles",
  },
  {
    display: "Cabinet",
    class: "cabinet",
  },
  {
    display: "Work shift",
    class: "workShift",
  },
];
