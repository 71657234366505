import type { UnwrapRef } from "vue";
import { reactive } from "vue";
import type { DeleteState } from "@/components/Operators/types";
import { useToast } from "vue-toastification";
import type { AnyUser, Operator } from "@/stores/types/users-types";
import { useUsersStore } from "@/stores/users-store";
import { useCabinetsStore } from "@/stores/cabinets-store";
import { useCurrentPageStore } from "@/stores/current-page-store";
import { TU_TYPE_ENUM } from "@/core/enums/tu-type.enum";

export function useDeleteUser<T extends AnyUser>() {
  const usersStore = useUsersStore();

  const cabinetsStore = useCabinetsStore();

  const toast = useToast();

  const deleteState = reactive<DeleteState<T>>({
    state: false,
    user: undefined,
    loading: false,
    idOwner: undefined,
  });

  const hideDeleteModal = () => {
    deleteState.user = undefined;
    deleteState.state = false;
    deleteState.idOwner = undefined;
  };

  const validateUser = () => {
    if (!deleteState.user?._id) {
      throw new Error("Set user to deleteState before call delete function");
    }
  };

  const isExternalOperatorDeleting = (): boolean => {
    return (deleteState.user as Operator)?.tuType === TU_TYPE_ENUM.EXTERNAL;
  };

  const isManagersPage = (): boolean => {
    return useCurrentPageStore().isManagersPage;
  };

  const handleErrorNotification = () => {
    toast.error("Something went wrong");
  };

  const handleSuccessNotification = (name: string) => {
    toast.success(`${name} was removed`);
  };

  const openDeleteModal = (user: T, idOwner = "") => {
    deleteState.user = user as UnwrapRef<T>;
    deleteState.state = true;
    deleteState.idOwner = idOwner;
  };

  const deleteTranslator = async (): Promise<void> => {
    validateUser();
    deleteState.loading = true;
    try {
      await usersStore.deleteTranslator(deleteState.user?._id || "");
      handleSuccessNotification("Operator");
    } catch (error) {
      handleErrorNotification();
    }
    deleteState.loading = false;
    hideDeleteModal();
  };

  const deleteManager = async (): Promise<void> => {
    validateUser();
    deleteState.loading = true;
    try {
      await usersStore.deleteManager(deleteState.user?._id || "");
      handleSuccessNotification("Manager");
    } catch (error) {
      handleErrorNotification();
    }
    deleteState.loading = false;
    hideDeleteModal();
  };

  const deleteOperator = async (): Promise<void> => {
    validateUser();
    deleteState.loading = true;
    try {
      await usersStore.deleteOperator(
        deleteState.user?._id || "",
        deleteState.idOwner || ""
      );
      handleSuccessNotification("Profile");
    } catch (error) {
      handleErrorNotification();
    }
    deleteState.loading = false;
    hideDeleteModal();
  };

  const deleteCabinetTU = async (): Promise<void> => {
    validateUser();
    deleteState.loading = true;
    try {
      await cabinetsStore.unAssignCabinetTU(
        (deleteState.user as Operator).idTU,
        deleteState.idOwner || ""
      );
      handleSuccessNotification("Profile");
    } catch (error) {
      handleErrorNotification();
    }
    deleteState.loading = false;
    hideDeleteModal();
  };

  const deleteExternalOperator = async (): Promise<void> => {
    validateUser();
    deleteState.loading = true;
    try {
      await usersStore.deleteExternalOperator(
        (deleteState.user as Operator)._id,
        (deleteState.user as Operator).idTU
      );
      handleSuccessNotification("Profile");
    } catch (error) {
      handleErrorNotification();
    }
    deleteState.loading = false;
    hideDeleteModal();
  };

  const onDeleteOperator = () => {
    if (isManagersPage()) {
      if (isExternalOperatorDeleting()) {
        if (deleteState.idOwner) {
          return deleteOperator();
        }
        return deleteExternalOperator();
      }
      return deleteOperator();
    } else {
      if (deleteState.idOwner) {
        return deleteCabinetTU();
      }
      return deleteExternalOperator();
    }
  };

  return {
    deleteState,
    hideDeleteModal,
    onDeleteOperator,
    deleteTranslator,
    deleteManager,
    openDeleteModal,
  };
}
