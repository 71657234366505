<script setup lang="ts">
import { defineProps, reactive } from "vue";
import { useToast } from "vue-toastification";
import { useCabinetsStore } from "@/stores/cabinets-store";
import type { CabinetUI } from "@/stores/types/cabinets-types";
import { useUsersStore } from "@/stores/users-store";

const props = defineProps<{
  idTranslator: string;
  close: () => void;
}>();

const toast = useToast();

const usersStore = useUsersStore();

const cabinetsStore = useCabinetsStore();

const unassignCabinetState = reactive<{
  state: boolean;
  loading: boolean;
  cabinets: CabinetUI[];
  _cabinets: CabinetUI[];
}>({
  state: false,
  loading: false,
  _cabinets: [],
  get cabinets(): CabinetUI[] {
    return this._cabinets;
  },
  set cabinets(data: CabinetUI[]) {
    this._cabinets = data;
  },
});

const openModal = (): void => {
  unassignCabinetState.state = true;
  unassignCabinetState.cabinets = mapCabinets();
};

const hideModal = (): void => {
  unassignCabinetState.state = false;
  unassignCabinetState.cabinets = [];
  props.close();
};

const onCabinetSelect = (index: number): void => {
  unassignCabinetState.cabinets[index].selected =
    !unassignCabinetState.cabinets[index].selected;
};

const isUnassignBtnDisabled = (): boolean => {
  return (
    !unassignCabinetState.cabinets.some((cabinet) => !!cabinet.selected) ||
    unassignCabinetState.loading
  );
};

const mapCabinets = (): CabinetUI[] => {
  return cabinetsStore
    .getCabinetsByOwnerId(props.idTranslator)
    .map((cabinet) => {
      const tuProfiles = cabinet.idTUs
        .map((id) => {
          return usersStore.getOperatorByOriginalId(id);
        })
        .filter((tu) => !!tu);
      return {
        ...cabinet,
        tuProfiles,
        selected: false,
      } as CabinetUI;
    });
};

const getSelectedCabinet = (): CabinetUI[] => {
  return unassignCabinetState.cabinets.filter((cabinet) => !!cabinet.selected);
};

const unassignCabinet = async (): Promise<void> => {
  unassignCabinetState.loading = true;
  try {
    const cabinets = getSelectedCabinet();
    // const isReassignCabinet = props.alreadyAssign !== "--";
    for (const cabinet of cabinets) {
      await cabinetsStore.unAssignCabinet(
        props.idTranslator,
        cabinet._id
        // isReassignCabinet
      );
    }
    toast.success("Cabinet was unassigned");
  } catch (e) {
    toast.error("Something went wrong during unassigning cabinet");
  } finally {
    unassignCabinetState.loading = false;
    hideModal();
  }
};

openModal();
</script>

<template>
  <div>
    <v-card title="UNASSIGN CABINET" class="unassign-cabinet__content">
      <v-card-content>
        <div class="unassign-cabinet">
          <template v-if="unassignCabinetState.cabinets.length">
            <div
              :class="{
                'unassign-cabinet__item': true,
                'unassign-cabinet__item--selected': cabinet.selected,
              }"
              v-for="(cabinet, index) in unassignCabinetState.cabinets"
              :key="cabinet._id"
            >
              <div class="unassign-cabinet__action-row">
                <div style="font-weight: bold">{{ cabinet.name }}</div>
                <div @click="onCabinetSelect(index)">
                  <v-checkbox
                    color="primary"
                    class="unassign-cabinet__check-box"
                    hide-details="auto"
                    v-model="cabinet.selected"
                  ></v-checkbox>
                </div>
              </div>
            </div>
          </template>
          <template v-if="!unassignCabinetState.cabinets.length">
            <div class="empty-container__wrapper">
              <div class="empty-container__item">
                No available cabinets found
              </div>
            </div>
          </template>
        </div>
      </v-card-content>
      <v-card-actions class="unassign-cabinet__btn-row">
        <v-btn @click="hideModal()">Cancel</v-btn>
        <v-btn
          color="primary"
          @click="unassignCabinet"
          :disabled="isUnassignBtnDisabled()"
        >
          Unassign Cabinets
          <v-progress-circular
            v-if="unassignCabinetState.loading"
            indeterminate
          ></v-progress-circular>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<style scoped lang="scss">
.unassign-cabinet {
  padding: 0 20px;

  &__content {
    margin-bottom: 52px;
  }

  &__check-box {
    cursor: pointer;
  }

  &__table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      text-align: left;
      min-height: 48px;
      font-size: 0.875rem;
      padding: 5px 10px;
      font-weight: normal;
      height: 48px;
    }

    tr.row {
      border-bottom: thin solid
        rgba(var(--v-border-color), var(--v-border-opacity));
    }

    tr.row:hover {
      background: rgba(var(--v-border-color), var(--v-hover-opacity));
    }
  }

  &__item {
    margin-bottom: 10px;
    display: flex;
    border-radius: 3px;
    background: #fff;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #e8eaf5;

    &--selected {
      border: 2px solid #5c81dc;
    }
  }

  &__action-row {
    padding: 0 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__btn-row {
    display: flex;
    justify-content: end;
    z-index: 9999;
    bottom: 0;
    background: #ffffff;
    width: 100%;
  }

  &__check-box {
    font-size: 20px;
  }

  .avatar-wrapper {
    display: table;
    position: relative;
    .external-icon {
      position: absolute;
      bottom: -5px;
      right: -10px;
    }
  }
}
</style>
