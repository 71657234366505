<script setup lang="ts">
import { reactive } from "vue";
import { useToast } from "vue-toastification";

const toast = useToast();

const props = defineProps<{
  refCode: number;
  submit: () => void;
}>();

const state = reactive<{
  loading: boolean;
}>({
  loading: false,
});

async function runSubmit(): Promise<void> {
  state.loading = true;
  try {
    await props.submit();
  } catch (e) {
    toast.error("Something went wrong during updating referral code");
  } finally {
    state.loading = false;
  }
}
</script>
<template>
  <v-card style="min-height: 0; width: 600px">
    <v-card-title> Referral code is deactivated </v-card-title>
    <v-card-text>
      <v-container>
        The current referral code {{ props.refCode }} has been deactivated, a
        new one must be created to continue working. Click "create" to release a
        new one and you will be able to continue working
      </v-container>
    </v-card-text>
    <v-card-actions style="justify-content: flex-end">
      <v-btn style="margin-right: 8px" to="/"> Cancel </v-btn>
      <v-btn @click="runSubmit()" :disabled="state.loading" color="primary"
        >Create
        <v-progress-circular
          v-if="state.loading"
          indeterminate
        ></v-progress-circular>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped></style>
