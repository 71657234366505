<script setup lang="ts">
import type { Rules } from "@/core";
import type { ComboBoxItems } from "@/components/ComboBox/combo-box-items";
import { computed, ref, watch } from "vue";

const props = defineProps<{
  rules?: Rules;
  label?: string;
  placeholder?: string;
  items: ComboBoxItems;
  bindLabel: string;
  bindValue: string;
  modelValue: string | number | unknown[] | undefined;
}>();
const items = computed(() =>
  props.items.map((item) => ({
    title: item[props.bindLabel],
    value: item[props.bindValue],
  }))
);
/**
 * Remove this component after vuetify fix combobox bug
 */
const label = props.modelValue
  ? props.items.find((item) => props.modelValue === item[props.bindValue])[
      props.bindLabel
    ]
  : "";
const value = ref(label);

const emit = defineEmits(["update:modelValue"]);
watch(value, () => {
  const valueObject = props.items.find((item) =>
    item[props.bindLabel].includes(value.value)
  );
  if (valueObject) {
    emit("update:modelValue", valueObject[props.bindValue]);
  }
});
</script>
<template>
  <v-combobox
    :rules="props.rules"
    :label="props.label"
    :items="items"
    item-value="value"
    item-text="label"
    :return-object="false"
    v-model="value"
    :placeholder="props.placeholder"
  ></v-combobox>
</template>

<style scoped></style>
