import axios from "axios";
import { ErrorTrackingService, RefreshTokenService } from "@/core";
import { environment } from "@/envinronment";
import { useAuthStore } from "@/stores/auth-store";
import type { AxiosInstance } from "axios";
import AnalystService from "@/core/services/AnalystService";

class Api {
  client: AxiosInstance;

  constructor() {
    this.client = this.createAxiosClient();
    this.initInterceptors();
  }

  private createAxiosClient(): AxiosInstance {
    return axios.create({
      baseURL: environment.extensionApiUrl as string,
      withCredentials: true,
      timeout: 45000,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  }

  private initInterceptors(): void {
    this.client.interceptors.request.use((config) => {
      const token = useAuthStore().token;

      if (!config.headers) {
        config.headers = {};
      }
      config.headers["Accept"] = "*/*";
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }

      return config;
    });

    this.client.interceptors.response.use(
      (res) => res.data,
      (...rest) =>
        new Promise((resolve, reject) => {
          const message = rest[0].response?.data?.message ?? null;

          if (message === "not_valid_token") {
            RefreshTokenService.checkAuth(resolve, reject, rest[0]);
          } else if (
            message === "Unauthorized" &&
            !rest[0].response.request.url.includes("auth/check")
          ) {
            AnalystService.pushForceLogout();
            useAuthStore().logout();
          } else {
            ErrorTrackingService.notify(rest[0]);
            reject(rest[0]);
          }
        })
    );
  }
}

export default new Api();
