import { reactive } from "vue";
import { useWorkShiftStore } from "@/stores/work-shift-store";
import type { WorkShiftBase } from "@/stores/types/work-shift-types";

export function useEditWorkShift() {
  const workShiftStore = useWorkShiftStore();

  const editModalState = reactive<{
    state: boolean;
    id: string | null;
  }>({
    state: false,
    id: null,
  });

  const hideEditModal = (): void => {
    editModalState.state = false;
    editModalState.id = null;
  };

  const openEditModal = (id: string): void => {
    editModalState.state = true;
    editModalState.id = id;
  };

  const editWorkShift = async (data: WorkShiftBase): Promise<void> => {
    const { id } = editModalState;

    await workShiftStore.editWorkShift(id as string, data);

    hideEditModal();
  };

  return {
    editModalState,
    hideEditModal,
    openEditModal,
    editWorkShift,
  };
}
