<script setup lang="ts">
import { useLogoutUser } from "@/components/LogoutUser/logout-user";
import type { Translator } from "@/stores/types/users-types";
import { defineProps } from "vue";

const props = defineProps<{
  user: Translator;
  close: () => void;
}>();

const { logoutState, hideLogoutModal, logoutTranslator, openLogoutModal } =
  useLogoutUser<Translator>();

function closeModal() {
  hideLogoutModal();
  props.close();
}

openLogoutModal(props.user);
</script>

<template>
  <v-card
    title="Do you want to logout operator?"
    :text="`Are you sure you want to log out a user ${logoutState.user?.label}? Their session will expire in less than 15 minutes. If you do not change the password, they will be able to log in again`"
  >
    <v-card-actions style="justify-content: flex-end">
      <v-btn @click="closeModal()">Cancel</v-btn>
      <v-btn @click="logoutTranslator().then(() => props.close())" color="error"
        >Logout
        <v-progress-circular
          v-if="logoutState.loading"
          indeterminate
        ></v-progress-circular>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped lang="scss"></style>
