import { defineStore } from "pinia";
import type {
  WorkShift,
  WorkShiftBase,
  WorkShiftStore,
} from "@/stores/types/work-shift-types";
import ApiWorkShift from "@/shared/api/ApiWorkShift";
import { useCabinetsStore } from "@/stores/cabinets-store";
import { sortByField } from "@/core/functions/sort-by-field";
import AnalystService from "@/core/services/AnalystService";
import { useUserProfileStore } from "@/stores/user-profile-store";
import { WORK_SHIFT_EVENT_NAME } from "@/core/enums/amplitude-event.enum";

export const useWorkShiftStore = defineStore({
  id: "workShift",
  state: (): WorkShiftStore => {
    return {
      loading: false,
      workShifts: [],
    };
  },
  getters: {
    all: ({ workShifts }) => [...workShifts],
    allSorted: ({ workShifts }): WorkShift[] =>
      sortByField([...workShifts], "name"),
    allAvailable:
      ({ workShifts }) =>
      (id: string) => {
        const filtered = [...workShifts].filter(
          (workShift) => !workShift.idOperators.includes(id)
        );
        return sortByField(filtered, "name");
      },
    getWorkShiftOwnerId:
      ({ workShifts }) =>
      (id: string) =>
        [...workShifts].find((workShift) =>
          workShift.idOperators.includes(id)
        ) as WorkShift,
  },
  actions: {
    async indexWorkShifts(): Promise<void> {
      this.loading = true;

      const response = await ApiWorkShift.getWorkShifts();

      this.loading = false;
      this.workShifts = [...response];
    },

    async createWorkShift(data: WorkShiftBase): Promise<void> {
      const response = await ApiWorkShift.createWorkShift(data);

      AnalystService.pushWorkShiftEvent({
        role: useUserProfileStore().role,
        action: WORK_SHIFT_EVENT_NAME.CREATE,
        idWorkShift: response._id,
      });
      this.workShifts.push(response);
    },

    async editWorkShift(id: string, data: WorkShiftBase): Promise<void> {
      const { result, crossingOperators } = await ApiWorkShift.editWorkShift(
        id,
        data
      );

      if (result) {
        AnalystService.pushWorkShiftEvent({
          role: useUserProfileStore().role,
          action: WORK_SHIFT_EVENT_NAME.EDIT,
          idWorkShift: id,
        });
        this.workShifts = this.workShifts.map((workShift) => {
          if (workShift._id === id) {
            return { ...workShift, ...data };
          }

          return workShift;
        });
      } else {
        throw { crossingOperators };
      }
    },

    async deleteWorkShift(id: string): Promise<void> {
      await ApiWorkShift.deleteWorkShift(id);
      AnalystService.pushWorkShiftEvent({
        role: useUserProfileStore().role,
        action: WORK_SHIFT_EVENT_NAME.DELETE,
        idWorkShift: id,
      });

      await useCabinetsStore().indexCabinets();

      this.workShifts = this.workShifts.filter(
        (workShift) => workShift._id !== id
      );
    },

    async assignWorkShift(
      idOperator: string,
      idWorkShift: string,
      isReassign = false
    ): Promise<void> {
      const { result, crossingOperators } = await ApiWorkShift.assignWorkShift(
        idWorkShift,
        idOperator
      );
      if (result) {
        if (isReassign) {
          AnalystService.pushWorkShiftEvent({
            role: useUserProfileStore().role,
            action: WORK_SHIFT_EVENT_NAME.REASSIGN,
            idWorkShift,
          });
        } else {
          AnalystService.pushWorkShiftEvent({
            role: useUserProfileStore().role,
            action: WORK_SHIFT_EVENT_NAME.ASSIGN,
            idWorkShift: idWorkShift,
          });
        }
        await this.indexWorkShifts();
      } else {
        throw { crossingOperators };
      }
    },

    async unAssignWorkShift(
      idOperator: string,
      idWorkShift: string
    ): Promise<void> {
      await ApiWorkShift.unAssignWorkShift(idWorkShift, idOperator);

      AnalystService.pushWorkShiftEvent({
        role: useUserProfileStore().role,
        action: WORK_SHIFT_EVENT_NAME.UNASSIGN,
        idWorkShift: idWorkShift,
      });

      await this.indexWorkShifts();
    },
  },
});
