import { entries } from "lodash-es";

type UrlMatchCallback = (url: string) => boolean;
type UrlReplaceCallback = (url: string) => string;
type HeadersCallback = () => { [key: string]: string };
type Patches = {
  url?: string | UrlReplaceCallback;
  type?: string;
  params?: { [key: string]: string };
  body?: (body?: string) => string | undefined;
  headers?: { [key: string]: string } | HeadersCallback;
  response?: CallableFunction;
};
type InterceptOptions = { url: string | UrlMatchCallback; patches: Patches };
declare global {
  interface XMLHttpRequest {
    patches?: Patches;
    patchesHeadersStatus: boolean;

    setRequestHeader(name: string, value: string, isPatch?: boolean): void;
  }
}

const responseTextAccessor = Object.getOwnPropertyDescriptor(
  XMLHttpRequest.prototype,
  "responseText"
);

const responseAccessor = Object.getOwnPropertyDescriptor(
  XMLHttpRequest.prototype,
  "response"
);

const open = window.XMLHttpRequest.prototype.open;
const send = window.XMLHttpRequest.prototype.send;
const setRequestHeader = window.XMLHttpRequest.prototype.setRequestHeader;
const options: InterceptOptions[] = [];
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.XMLHttpRequest.prototype.open = function (
  method: string,
  url: string | URL,
  async: boolean,
  username: undefined | null | string,
  password: undefined | null | string
): void {
  const result = options.find((option) =>
    typeof option.url === "function"
      ? option.url(url === "string" ? url : url.toString())
      : option.url === url
  );
  this.patches = result?.patches;
  if (result?.patches.response) {
    Object.defineProperty(this, "responseText", {
      get: function () {
        const text = responseTextAccessor?.get?.call(this);
        if (result.patches.response) {
          return result.patches.response(text);
        }
        return text;
      },
      configurable: true,
    });
    Object.defineProperty(this, "response", {
      get: function () {
        const text = responseAccessor?.get?.call(this);
        if (result.patches.response) {
          result.patches.response(text);
        }
        return text;
      },
      configurable: true,
    });
  }
  const patchedUrl =
    typeof this.patches?.url === "function"
      ? this.patches.url(typeof url === "string" ? url : url.toString())
      : this.patches?.url;

  open.apply(this, [
    this.patches?.type || method,
    patchedUrl || url,
    async,
    username,
    password,
  ]);
};

window.XMLHttpRequest.prototype.send = function (body?: string) {
  if (this.patches?.body) {
    body = this.patches.body(body);
  }
  send.apply(this, [body]);
};

window.XMLHttpRequest.prototype.setRequestHeader = function (name, value) {
  const patchesHeaders =
    typeof this.patches?.headers === "function"
      ? this.patches.headers()
      : this.patches?.headers || {};
  if (!this.patchesHeadersStatus) {
    this.patchesHeadersStatus = true;
    entries(patchesHeaders).forEach(([key, value]) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setRequestHeader.apply(this, [key, value]);
    });
  }
  if (!(name in patchesHeaders)) {
    setRequestHeader.apply(this, [name, value]);
  }
};
