import { reactive } from "vue";
import { useCabinetsStore } from "@/stores/cabinets-store";
import { useToast } from "vue-toastification";

export function useDeleteCabinet() {
  const cabinetsStore = useCabinetsStore();

  const toast = useToast();

  const deleteModalState = reactive<{
    state: boolean;
    id: string | null;
    loading: boolean;
  }>({
    state: false,
    id: null,
    loading: false,
  });

  const hideDeleteModal = (): void => {
    deleteModalState.state = false;
    deleteModalState.id = null;
  };

  const openDeleteModal = (id: string): void => {
    deleteModalState.state = true;
    deleteModalState.id = id;
  };

  const deleteCabinet = async (): Promise<void> => {
    deleteModalState.loading = true;

    try {
      await cabinetsStore.deleteCabinet(deleteModalState.id as string);
      toast.success("Cabinet was deleted");
    } catch (e) {
      toast.error("Something went wrong during deleting cabinet");
    } finally {
      deleteModalState.loading = false;
      hideDeleteModal();
    }
  };

  return {
    deleteModalState,
    openDeleteModal,
    hideDeleteModal,
    deleteCabinet,
  };
}
