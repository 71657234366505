import type { AxiosResponse } from "axios";
import { useAuthStore } from "@/stores/auth-store";
import { Http } from "@/core/api";
import { ApiAuth } from "@/shared/api";
import AnalystService from "@/core/services/AnalystService";

export type RefreshToken = {
  token: string;
  appToken: string;
  expirationTime: number;
};

class RefreshTokenService {
  pendingNewToken: Promise<boolean> | null = null;

  async checkAuth(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resolve: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reject: any,
    rest: AxiosResponse
  ): Promise<void> {
    const authStore = useAuthStore();

    if (authStore.loggedIn) {
      const result: boolean =
        this.pendingNewToken === null
          ? await this.getNewToken()
          : await this.pendingNewToken;

      if (result) {
        Http.request(rest.config).then(resolve).catch(reject);
      } else {
        AnalystService.pushForceLogout();
        authStore.logout();
      }
    } else {
      AnalystService.pushForceLogout();
      authStore.logout();
    }
  }

  getNewToken(): Promise<boolean> {
    const authStore = useAuthStore();

    return ApiAuth.getNewToken()
      .then(async (res) => {
        authStore.cacheState({
          token: res.token,
          appToken: res.appToken,
          expirationTime: res.expirationTime,
        });
        this.pendingNewToken = null;

        return !!res.token && !!res.appToken;
      })
      .catch(() => {
        this.pendingNewToken = null;

        return false;
      });
  }
}

export default new RefreshTokenService();
