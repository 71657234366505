import Api from "@/core/api/Api";
import type { AxiosRequestConfig } from "axios";

class Http {
  get<T = any, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>
  ): Promise<T> {
    return Api.client.get<T, T>(url, config);
  }

  post<T = any, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<T> {
    return Api.client.post<T, T>(url, data, config);
  }

  request<T = any, D = any>(config: AxiosRequestConfig<D>): Promise<T> {
    return Api.client.request<T, T>(config);
  }
}

export default new Http();
