import { Http } from "@/core/api";
import type {
  CustomWorkShiftRes,
  WorkShift,
  WorkShiftBase,
} from "@/stores/types/work-shift-types";

class ApiWorkShift {
  getWorkShifts(): Promise<WorkShift[]> {
    return Http.get<WorkShift[]>("/work-shift/all");
  }

  createWorkShift(data: WorkShiftBase): Promise<WorkShift> {
    return Http.post<WorkShift>("/work-shift/create", data);
  }

  editWorkShift(id: string, data: WorkShiftBase): Promise<CustomWorkShiftRes> {
    return Http.post<CustomWorkShiftRes>("/work-shift/edit", { data, id });
  }

  deleteWorkShift(id: string): Promise<boolean> {
    return Http.post<boolean>("/work-shift/remove", { id });
  }

  assignWorkShift(id: string, idOperator: string): Promise<CustomWorkShiftRes> {
    return Http.post<CustomWorkShiftRes>("/work-shift/operator/assign", {
      id,
      idOperator,
    });
  }

  unAssignWorkShift(id: string, idOperator: string): Promise<boolean> {
    return Http.post<boolean>("/work-shift/operator/remove ", {
      id,
      idOperator,
    });
  }
}

export default new ApiWorkShift();
