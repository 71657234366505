<script lang="ts" setup>
function actionLogOut() {
  window.location.href = "/auth";
}
</script>
<template>
  <div class="d-flex justify-center" style="padding: 32px">
    <v-card elevation="1" outlined style="min-width: 250px">
      <v-card-title>Auth problem</v-card-title>
      <v-card-text>Need re-login.</v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="actionLogOut"> Log Out </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
