import { Http } from "@/core/api";

class ApiRefCode {
  check(refCode: number): Promise<void> {
    return Http.post<void>("/ref-code/check", { refCode: refCode.toString() });
  }

  create(id: string): Promise<{ referralCode: number }> {
    return Http.post<{ referralCode: number }>("/ref-code/create", { id });
  }
}

export default new ApiRefCode();
