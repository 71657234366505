<script setup lang="ts">
import { reactive, ref } from "vue";
import { useToast } from "vue-toastification";
import ComboBox from "@/components/ComboBox/ComboBox.vue";
import { TIME_SLOTS } from "@/components/WorkShift/constants/time-slots";
import { workShiftFormRules } from "@/components/WorkShift/work-shift-form-rules";
import { TINE_ZONES } from "@/components/WorkShift/constants/time-zones";
import type {
  WorkShift,
  WorkShiftBase,
  WorkShiftCrossing,
} from "@/stores/types/work-shift-types";
import { getWorkShiftErrorMessage } from "@/core";
import type { ServerError } from "@/core";
import type { WorkShiftFormState } from "@/components/WorkShift/work-shift-form-types";
import type { AxiosError } from "axios";
import { useCrossingOperators } from "@/components/CrossingOperators/crossing-operatrors";
import type { WorkShiftCrossingRes } from "@/stores/types/work-shift-types";
import CrossingOperators from "@/components/CrossingOperators/CrossingOperators.vue";
import AnalystService from "@/core/services/AnalystService";
import { WORK_SHIFTS_ERROR_ACTION } from "@/core/enums/amplitude-event.enum";
import type { UseModalReturnType } from "vue-final-modal";
import ModalService from "@/core/services/ModalService";

const props = defineProps<{
  state?: WorkShift;
  submit: (data: WorkShiftBase) => Promise<void>;
  cancel: () => void;
}>();

const {
  crossingOperatorsState,
  hideCrossingOperatorsModal,
  openCrossingOperatorsModal,
} = useCrossingOperators();

const formRef = ref();

const toast = useToast();

const timeSlots = TIME_SLOTS;

const timeZones = TINE_ZONES;

const validationRules = workShiftFormRules(props.state?.name || "");

const state = reactive<{
  valid: boolean;
  loading: boolean;
}>({
  loading: false,
  valid: false,
});

const formState = reactive<WorkShiftFormState>({
  name: props.state?.name || "",
  timeZone: props.state?.timeZone || "",
  startTime: props.state?.startTime || "",
  endTime: props.state?.endTime || "",
});

async function runSubmit(): Promise<void> {
  await formRef.value?.validate();
  if (!state.valid) {
    return;
  }
  state.loading = true;
  try {
    const { name, timeZone, startTime, endTime } = formState;
    await props.submit({ name: name.trim(), timeZone, endTime, startTime });
    handleSuccessNotification();
  } catch (e) {
    const { crossingOperators } = e as WorkShiftCrossingRes;
    if (crossingOperators) {
      AnalystService.pushWorkShiftErrorEvent({
        action: WORK_SHIFTS_ERROR_ACTION.EDIT,
      });
      return catchCrossingOperator(crossingOperators);
    }
    toast.error(
      getWorkShiftErrorMessage(e as AxiosError<ServerError>, !!props.state?._id)
    );
    throw e;
  } finally {
    state.loading = false;
  }
}

function getModalHeaderText(): string {
  if (props.state?._id) {
    return `Edit Work Shift "${props.state?.name}"`;
  }
  return "Create Work Shift";
}

function getActionTypeText(): string {
  if (props.state?._id) {
    return "Edit Work Shift";
  }
  return "Create Work Shift";
}

const handleSuccessNotification = () => {
  const actionText = props.state?._id ? "edited" : "created";

  toast.success(`Work shift was ${actionText}`);
};

let crossingOperatorModal: UseModalReturnType<any>;

function catchCrossingOperator(crossingOperators: WorkShiftCrossing[]) {
  openCrossingOperatorsModal(crossingOperators);
  crossingOperatorModal = ModalService.init(CrossingOperators, {
    style: "width: 700px",
    isAssign: false,
    data: crossingOperatorsState.crossingOperators,
    cancel() {
      closeCrossingOperatorModal();
    },
  });
  crossingOperatorModal.open();
}

function closeCrossingOperatorModal() {
  hideCrossingOperatorsModal();
  crossingOperatorModal.close();
}
</script>
<template>
  <v-card style="min-height: 0; width: 600px">
    <v-card-title>{{ getModalHeaderText() }}</v-card-title>
    <v-card-text>
      <v-container>
        <v-form ref="formRef" v-model="state.valid">
          <v-row>
            <v-col>
              <v-text-field
                :rules="validationRules.name"
                aria-autocomplete="none"
                v-model="formState.name"
                type="text"
                variant="underlined"
                label="Name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <combo-box
                label="Select Time Zone"
                placeholder="Select Time Zone"
                :items="timeZones"
                :rules="validationRules.timeZone"
                bind-label="display"
                bind-value="value"
                v-model="formState.timeZone"
              ></combo-box>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-combobox
                label="Select Start Time"
                placeholder="Select Start Time"
                :rules="validationRules.startTime"
                :items="timeSlots"
                v-model="formState.startTime"
              ></v-combobox>
            </v-col>
            <v-col>
              <v-combobox
                label="Select End Time"
                placeholder="Select End Time"
                :rules="validationRules.endTime"
                :items="timeSlots"
                v-model="formState.endTime"
              ></v-combobox>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions style="justify-content: flex-end">
      <v-btn style="margin-right: 8px" @click="props.cancel()">Cancel</v-btn>
      <v-btn @click="runSubmit()" color="primary" :disabled="state.loading">
        {{ getActionTypeText() }}
        <v-progress-circular
          v-if="state.loading"
          indeterminate
        ></v-progress-circular>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style lang="scss" scoped></style>
