<script lang="ts" setup>
import { computed, onMounted, reactive, ref, watch } from "vue";
import type { OperatorExtended } from "@/components/Operators/add-operators";
import type { Operator } from "@/stores/types/users-types";
import type { ServerError } from "@/core";
import type { AxiosError } from "axios";
import {
  getValidationPassErrorMessage,
  getValidationPassSuccessMessage,
} from "@/core";
import { useToast } from "vue-toastification";
import { ADD_PROFILES_HEADER } from "@/shared/constants/table-header";
import { useUsersStore } from "@/stores/users-store";
import { TU_TYPE_ENUM } from "@/core/enums/tu-type.enum";

const props = defineProps<{
  operatorIds: {
    alreadySelect: OperatorExtended[];
    unselect: Operator["idTU"][];
  };
}>();

const scroller = ref();

const toast = useToast();

const emit = defineEmits<{
  (e: "operatorChanged", operator: OperatorExtended): void;
  (e: "scroll-end"): void;
}>();

function emitScrollEnd() {
  emit("scroll-end");
}

const headers = ADD_PROFILES_HEADER;

const usersStore = useUsersStore();

const unselectedOperatorsIds = computed(() =>
  props.operatorIds.unselect.map((id: number) => id)
);

const state = reactive<{
  operators: OperatorExtended[];
  _operators: OperatorExtended[];
}>({
  _operators: [],
  get operators() {
    return [
      ...props.operatorIds.alreadySelect,
      ...this._operators
        .filter((operator) => {
          return unselectedOperatorsIds.value.includes(operator.operator?.idTU);
        })
        .sort(
          (
            operatorA: OperatorExtended,
            operatorB: OperatorExtended
          ): number => {
            return Number(operatorB.selected) - Number(operatorA.selected);
          }
        ),
    ];
  },
  set operators(value: OperatorExtended[]) {
    this._operators = value;
  },
});

const setInitialOperators = () => {
  state.operators = [
    ...(props.operatorIds.unselect
      .map((id: number) => usersStore.getOperatorByOriginalId(id))
      .map((operator) => ({
        operator,
        idTU: operator?.idTU,
        selected: false,
        password: "",
        showPass: false,
        invalidPassword: false,
        isPasswordValidated: false,
      })) as OperatorExtended[]),
  ];
};

const validatePassword = async (operator: OperatorExtended) => {
  const {
    password,
    operator: { idTU },
  } = operator;
  if (!password) {
    return;
  }
  try {
    await usersStore.validateOperatorPassword(idTU, password);
    operator.invalidPassword = false;
    const message = getValidationPassSuccessMessage(
      operator.operator,
      password
    );
    toast.success(message, { timeout: 2000 });
  } catch (e) {
    operator.invalidPassword = true;
    const errorMessage = getValidationPassErrorMessage(
      operator.operator,
      password,
      e as AxiosError<ServerError>
    );
    toast.error(errorMessage, { timeout: 2000 });
  } finally {
    operator.isPasswordValidated = true;
  }
};

const onPasswordInput = (operator: OperatorExtended) => {
  operator.invalidPassword = false;
  operator.isPasswordValidated =
    isOperatorHasPassword(operator.operator._id) && !operator.password;
};

const onProfileSelected = (index: number, operator: OperatorExtended): void => {
  emit("operatorChanged", operator);
  state.operators[index].selected = !state.operators[index].selected;
};

const setPasswordType = (operator: OperatorExtended): string => {
  return operator.showPass ? "text" : "password";
};

const setPasswordIcon = (operator: OperatorExtended): string => {
  return operator.showPass ? "mdi-eye" : "mdi-eye-off";
};

const togglePasswordIcon = (index: number): void => {
  state.operators[index].showPass = !state.operators[index].showPass;
};

const isOperatorHasPassword = (id: string): boolean => {
  return useUsersStore().checkOperatorPasswordById(id);
};

const buildPasswordLabel = (id: string): string => {
  return isOperatorHasPassword(id) ? "password(optional)" : "password";
};

onMounted(() => {
  setInitialOperators();
});

watch(
  () => props.operatorIds,
  () => {
    setInitialOperators();
  }
);
</script>

<template>
  <RecycleScroller
    ref="scroller"
    class="extension-virtual-scroller"
    :items="state.operators"
    :item-size="77"
    :key-field="'idTU'"
    @scroll-end="emitScrollEnd"
  >
    <template #before>
      <div v-if="state.operators.length" class="row row-header">
        <div
          :key="header.display"
          :class="['cell', header.class]"
          v-for="header in headers"
        >
          {{ header.display }}
        </div>
      </div>
      <div v-else-if="!state.operators.length" class="empty-operators">
        No profiles found or profiles do not match search criteria
      </div>
    </template>
    <template #default="{ item, index }">
      <div class="row row-body" :key="item.operator.idTU">
        <div class="cell">
          <div
            class="check-box-wrapper"
            @click="onProfileSelected(index, item)"
          >
            <v-checkbox
              color="primary"
              class="add-profiles-check-box"
              hide-details="auto"
              v-model="item.selected"
            ></v-checkbox>
          </div>
        </div>
        <div class="cell">
          {{ item.operator.idTU }}
        </div>
        <div class="cell">
          <div class="avatar-wrapper">
            <v-avatar>
              <img :alt="item?.operator?.name" :src="item?.operator?.avatar" />
            </v-avatar>
            <v-icon
              v-if="item?.tuType === TU_TYPE_ENUM.EXTERNAL"
              size="20px"
              class="external-icon"
              color="primary"
              icon="mdi-cloud-upload"
            ></v-icon>
          </div>
        </div>
        <div class="cell">
          {{ item?.operator?.name }}
        </div>
        <div class="cell">
          {{ item?.operator?.age }}
        </div>
        <div class="cell">
          <v-form
            class="add-profiles-form"
            v-if="item.operator.tuType !== TU_TYPE_ENUM.EXTERNAL"
          >
            <v-text-field
              :label="buildPasswordLabel(item.operator._id)"
              :class="{
                'add-profiles-input': true,
                'invalid-pass':
                  item.invalidPassword && item.isPasswordValidated,
              }"
              placeholder="password"
              hide-details="auto"
              :append-icon="setPasswordIcon(item)"
              :type="setPasswordType(item)"
              @input="onPasswordInput(item)"
              @blur="validatePassword(item)"
              @click:append="togglePasswordIcon(index)"
              v-model="item.password"
            >
            </v-text-field>
          </v-form>
        </div>
      </div>
    </template>
  </RecycleScroller>
</template>

<style lang="scss" scoped>
.extension-virtual-scroller {
  max-height: 500px;
  .row {
    grid-template-columns: 1fr 1.5fr 1fr 2.5fr 1fr 3fr;
  }
  .add-profiles-check-box {
    font-size: 20px;
    line-height: 0;
  }
  .add-profiles-form {
    width: 100%;
    .add-profiles-input {
      margin: 10px 0;
    }
    .invalid-pass::v-deep {
      .v-input__control {
        outline: 1px solid rgba(var(--v-theme-error));
      }
      .v-field__outline {
        color: rgba(var(--v-theme-error));
      }
    }
  }
  .check-box-wrapper {
    cursor: pointer;
  }
  .avatar-wrapper {
    display: table;
    position: relative;
    .external-icon {
      position: absolute;
      bottom: -5px;
      right: -10px;
    }
  }
  .empty-operators {
    color: var(--border-color);
    font-size: 20px;
    text-align: center;
    padding: 10px 0;
  }
}

@media (max-height: 799px) {
  .extension-virtual-scroller {
    max-height: 400px;
  }
}

@media (max-height: 699px) {
  .extension-virtual-scroller {
    max-height: 300px;
  }
}
</style>
