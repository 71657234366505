import type { UnwrapRef } from "vue";
import { reactive } from "vue";
import type { LogoutState } from "@/components/LogoutUser/types";
import { useToast } from "vue-toastification";
import type { AnyUser } from "@/stores/types/users-types";
import { useUsersStore } from "@/stores/users-store";

export function useLogoutUser<T extends AnyUser>() {
  const toast = useToast();

  const usersStore = useUsersStore();

  const logoutState = reactive<LogoutState<T>>({
    state: false,
    user: undefined,
    loading: false,
  });

  const hideLogoutModal = () => {
    logoutState.user = undefined;
    logoutState.state = false;
  };

  const handleErrorNotification = () => {
    toast.error("Something went wrong");
  };

  const handleSuccessNotification = (name: string) => {
    toast.success(`${name} was logout`);
  };

  const openLogoutModal = (user: T) => {
    logoutState.user = user as UnwrapRef<T>;
    logoutState.state = true;
  };

  const logoutTranslator = async (): Promise<void> => {
    logoutState.loading = true;
    try {
      await usersStore.logoutTranslator(logoutState.user?._id || "");
      handleSuccessNotification("Operator");
    } catch (error) {
      handleErrorNotification();
    }
    logoutState.loading = false;
    hideLogoutModal();
  };

  return {
    logoutState,
    hideLogoutModal,
    logoutTranslator,
    openLogoutModal,
  };
}
