import amplitude from "amplitude-js";
import {
  AMPLITUDE_EVENT_NAME,
  ANALYTICS_EVENT_TU_ACTION,
  CABINET_ACTIONS_NAME,
  EVENT_CABINET_MODAL_ACTION,
  ANALYTICS_EVENT_MODAL_ACTION,
  WORK_SHIFT_EVENT_NAME,
  WORK_SHIFTS_ERROR_ACTION,
  ANALYTICS_EVENT_PROFILE_ACTION,
  ANALYTICS_EVENT_ROLE_NAME,
} from "@/core/enums/amplitude-event.enum";
import { environment } from "@/envinronment";
import type { AmplitudeClient } from "amplitude-js";

class AnalystService {
  private amplitude: AmplitudeClient;

  constructor() {
    this.amplitude = amplitude.getInstance();
    this.init();
  }

  private init(): void {
    this.amplitude.init(environment.amplitudeKey, undefined, {
      apiEndpoint: "api2.amplitude.com",
    });
    this.amplitude.setVersionName(environment.extensionVersion.toString());
  }

  initializeUserInformation(
    mail: string,
    idAgency: string,
    referralCode: number,
    role: string,
    userId: string
  ): void {
    this.amplitude.setUserProperties({
      mail,
      idAgency,
      referralCode,
      role,
      userId,
      version: environment.extensionVersion,
    });
  }

  initializeUserIdRegion(idRegion: string): void {
    this.amplitude.setUserProperties({ idRegion });
  }

  pushLoginEvent(idAgency: string): void {
    this.amplitude.setUserId(idAgency);
    this.amplitude.logEvent(AMPLITUDE_EVENT_NAME.EXT_USER_LOGIN);
  }

  pushExternalProfileAddedEvent(data: { idProfile: number }): void {
    this.amplitude.logEvent(
      AMPLITUDE_EVENT_NAME.EXT_EXTERNAL_PROFILE_ADDED,
      data
    );
  }

  pushLoadHomePage(): void {
    this.amplitude.logEvent(AMPLITUDE_EVENT_NAME.EXT_LOAD_HOME_PAGE, {});
  }

  pushTUEvent(data: {
    action: ANALYTICS_EVENT_TU_ACTION;
    idCabinet?: string;
    idParent?: string;
  }): void {
    this.amplitude.logEvent(AMPLITUDE_EVENT_NAME.EXT_TU_EVENT, data);
  }

  pushCabinetModalEvent(data: { action: EVENT_CABINET_MODAL_ACTION }): void {
    this.amplitude.logEvent(
      AMPLITUDE_EVENT_NAME.EXT_CABINET_MODAL_ACTION,
      data
    );
  }

  pushCabinetEvent(data: {
    role: string;
    action: CABINET_ACTIONS_NAME;
    idCabinet: string;
  }): void {
    this.amplitude.logEvent(AMPLITUDE_EVENT_NAME.EXT_CABINET_ACTIONS, data);
  }

  pushWorkShiftModalEvent(data: {
    action: ANALYTICS_EVENT_MODAL_ACTION;
  }): void {
    this.amplitude.logEvent(
      AMPLITUDE_EVENT_NAME.EXT_WORK_SHIFTS_MODAL_ACTION,
      data
    );
  }

  pushWorkShiftEvent(data: {
    role: string;
    action: WORK_SHIFT_EVENT_NAME;
    idWorkShift: string;
  }): void {
    this.amplitude.logEvent(AMPLITUDE_EVENT_NAME.EXT_WORK_SHIFTS_ACTION, data);
  }

  pushWorkShiftErrorEvent(data: { action: WORK_SHIFTS_ERROR_ACTION }): void {
    this.amplitude.logEvent(
      AMPLITUDE_EVENT_NAME.EXT_WORK_SHIFTS_CROSSING_ERROR,
      data
    );
  }

  pushProfileModalEvent(data: {
    modal: ANALYTICS_EVENT_ROLE_NAME;
    action: ANALYTICS_EVENT_MODAL_ACTION;
  }): void {
    this.amplitude.logEvent(
      AMPLITUDE_EVENT_NAME.EXT_PROFILE_MODAL_ACTION,
      data
    );
  }

  pushProfileEvent(data: {
    role: ANALYTICS_EVENT_ROLE_NAME;
    action: ANALYTICS_EVENT_PROFILE_ACTION;
    email: string;
  }): void {
    this.amplitude.logEvent(AMPLITUDE_EVENT_NAME.EXT_PROFILE_ACTIONS, data);
  }

  pushForceLogout(): void {
    this.amplitude.logEvent(AMPLITUDE_EVENT_NAME.EXT_FORCE_LOGOUT);
  }

  pushRefCodeDeactivate(data: { refCode: number }): void {
    this.amplitude.logEvent(AMPLITUDE_EVENT_NAME.EXT_REF_CODE_DEACTIVATE, data);
  }
}

export default new AnalystService();
