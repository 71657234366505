import "vue-toastification/dist/index.css";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import "./assets/base.scss";
import "./assets/toast.scss";
import "./assets/virtual-scroll-custom-table.scss";
import "vue-final-modal/style.css";

import { ErrorTrackingService, InitAppSPAService, SPA_ROUTES } from "@/core";
import { useAuthStore } from "@/stores/auth-store";
import { listenRouteChanges } from "@/core";

ErrorTrackingService.init();
InitAppSPAService.init();

listenRouteChanges(SPA_ROUTES, true);
(window as any).authAsToken = (token: string): Promise<void> => {
  return useAuthStore().authByToken(token);
};
