import { useCabinetsStore } from "@/stores/cabinets-store";

export function cabinetFormRules() {
  const cabinetsStore = useCabinetsStore();

  return {
    name: [
      (v?: string) => !!v?.trim() || "Name is Required",
      (v: string) => {
        return (
          !cabinetsStore.all.find(
            (cabinet) => cabinet.name.trim() === v.trim()
          ) || "This name is already used"
        );
      },
    ],
  };
}
