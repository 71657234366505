import { Http } from "@/core/api";
import type { FEATURE_FLAG_NANE } from "@/stores/types/app-store-types";

class ApiShared {
  getCodeVersion(): Promise<number> {
    return Http.get<number>("/system/code-version");
  }

  getFeatureFlagList(): Promise<FEATURE_FLAG_NANE[]> {
    return Http.get<FEATURE_FLAG_NANE[]>("/feature-flag/list");
  }
}

export default new ApiShared();
