<script lang="ts" setup>
import { useEditUser } from "@/components/UserForm/edit-user";
import type { Translator } from "@/stores/types/users-types";
import { defineProps, reactive } from "vue";
import UserForm from "@/components/UserForm/UserForm.vue";
import { useDeleteUser } from "@/components/UserForm/delete-user";
import { useUsersStore } from "@/stores/users-store";
import { useCabinetsStore } from "@/stores/cabinets-store";
import type { Cabinet } from "@/stores/types/cabinets-types";
import AssignCabinet from "@/components/Cabinet/AssignCabinet.vue";
import type { WorkShift } from "@/stores/types/work-shift-types";
import { useWorkShiftStore } from "@/stores/work-shift-store";
import AssignWorkShift from "@/components/WorkShift/AssignWorkShift.vue";
import UnassignCabinet from "@/components/Cabinet/UnassignCabinet.vue";
import { useUserProfileStore } from "@/stores/user-profile-store";
import type { UseModalReturnType } from "vue-final-modal";
import type { UserFormState } from "@/components/UserForm/user-form-types";
import type { UserRole } from "@/stores/types/users-types";
import DeleteUnit from "@/components/Operators/DeleteUnit.vue";
import AddOperators from "@/components/Operators/AddOperators.vue";
import CreateRefCode from "@/components/RefCode/CreateRefCode.vue";
import UnassignWorkShift from "@/components/WorkShift/UnassіgnWorkShift.vue";
import LogoutUser from "@/components/LogoutUser/LogoutUser.vue";
import ModalService from "@/core/services/ModalService";

const { openEditModal, updateTranslator } = useEditUser<Translator>();

const { deleteState, hideDeleteModal, deleteTranslator, openDeleteModal } =
  useDeleteUser<Translator>();

const usersStore = useUsersStore();

const cabinetsStore = useCabinetsStore();

const workShiftStore = useWorkShiftStore();

const props = defineProps<{
  idTranslator: string;
}>();

const state = reactive<{
  translator: Translator;
  cabinets: Cabinet[];
  workShift: WorkShift;
}>({
  get translator(): Translator {
    return usersStore.getTranslatorById(props.idTranslator);
  },
  get cabinets(): Cabinet[] {
    return cabinetsStore.getCabinetsByOwnerId(this.translator._id);
  },
  get workShift(): WorkShift {
    return workShiftStore.getWorkShiftOwnerId(this.translator._id);
  },
});

const getWorkShiftInfo = (): string => {
  const { workShift } = state;

  if (workShift?._id) {
    return `${workShift.name} ${workShift.startTime} - ${workShift.endTime}, ${workShift.timeZone}`;
  }
  return "--";
};

const getManagerInfo = (): string => {
  return usersStore.getManagerById(state.translator.idParent)?.label;
};

const isAdminRole = useUserProfileStore().isAdminRole;

const availableAction = () => {
  const label = { label: "Actions", action: "action" };
  const workShiftActions = state.cabinets?.length
    ? state.workShift
      ? [
          { label: "Assign work shift", action: "assignWorkShift" },
          { label: "Unassign work shift", action: "unAssignWorkShift" },
        ]
      : [{ label: "Assign work shift", action: "assignWorkShift" }]
    : [];
  const cabinetActions = state.cabinets?.length
    ? [
        { label: "Assign cabinet", action: "assignCabinet" },
        { label: "Unassign cabinet", action: "unAssignCabinet" },
      ]
    : [{ label: "Assign cabinet", action: "assignCabinet" }];
  const stableAction = [
    { label: "Edit", action: "edit" },
    { label: "Create referral code", action: "refcode" },
    { label: "Delete", action: "delete" },
    { label: "Logout", action: "logout" },
  ];
  return [label, ...workShiftActions, ...cabinetActions, ...stableAction];
};

let userModal: UseModalReturnType<any>;

function openEditTranslator() {
  openEditModal(state.translator);
  userModal = ModalService.init(UserForm, {
    isEdit: true,
    async submit(data: UserFormState): Promise<void> {
      updateTranslator(data).then(() => closeUserModal());
    },
    cancelEdit() {
      closeUserModal();
    },
    role: "translator" as UserRole,
    state: state.translator,
  });
  userModal.open();
}

function closeUserModal() {
  userModal.close();
}

const deleteTranslatorModal = ModalService.init(DeleteUnit, {
  deleteStateUser: deleteState,
  async deleteUnit() {
    deleteTranslator().then(() => closeDeleteTranslatorModal());
  },
  hideDeleteModal() {
    closeDeleteTranslatorModal();
  },
});

function closeDeleteTranslatorModal() {
  hideDeleteModal();
  deleteTranslatorModal.close();
}

function openDeleteTranslatorModal(translator: Translator) {
  openDeleteModal(translator);
  deleteTranslatorModal.open();
}

const unassignCabinetModal = ModalService.init(UnassignCabinet, {
  style: "width: 700px",
  idTranslator: state.translator._id,
  close() {
    closeUnassignCabinetModal();
  },
});

function closeUnassignCabinetModal() {
  unassignCabinetModal.close();
}

const assignCabinetModal = ModalService.init(AssignCabinet, {
  style: "width: 700px",
  idTranslator: state.translator._id,
  close() {
    closeAssignCabinetModal();
  },
});

function closeAssignCabinetModal() {
  assignCabinetModal.close();
}

const assignOperatorModal = ModalService.init(AddOperators, {
  style: "width: 700px",
  parentId: state.translator._id,
  close() {
    closeAssignOperatorsModal();
  },
});

function closeAssignOperatorsModal() {
  assignOperatorModal.close();
}

const assignWorkShiftModal = ModalService.init(AssignWorkShift, {
  style: "width: 700px",
  operatorId: state.translator._id,
  alreadyAssign: getWorkShiftInfo(),
  close() {
    closeAssignWorkShiftModal();
  },
});

function closeAssignWorkShiftModal() {
  assignWorkShiftModal.close();
}

const createRefCodeModal = ModalService.init(CreateRefCode, {
  style: "width: 600px",
  user: state.translator,
  close() {
    closeCreateRefCodeModal();
  },
});

function closeCreateRefCodeModal() {
  createRefCodeModal.close();
}

const unassignWorkShiftModal = ModalService.init(UnassignWorkShift, {
  style: "width: 600px",
  workShiftName: state.workShift?.name,
  translatorName:
    state.translator?.firstName + " " + state.translator?.lastName,
  workShiftId: state.workShift?._id,
  operatorId: state.translator?._id,
  close() {
    closeUnassignWorkShiftModal();
  },
});

function closeUnassignWorkShiftModal() {
  unassignWorkShiftModal.close();
}

const logoutTranslatorModal = ModalService.init(LogoutUser, {
  style: "width: 600px",
  user: state.translator,
  close() {
    closeLogoutTranslatorModal();
  },
});

function closeLogoutTranslatorModal() {
  logoutTranslatorModal.close();
}

function selectAction(event: string) {
  switch (event) {
    case "assignWorkShift":
      assignWorkShiftModal.open();
      return;
    case "unAssignWorkShift":
      unassignWorkShiftModal.open();
      return;
    case "assignCabinet":
      assignCabinetModal.open();
      return;
    case "unAssignCabinet":
      unassignCabinetModal.open();
      return;
    case "edit":
      openEditTranslator();
      return;
    case "refcode":
      createRefCodeModal.open();
      return;
    case "delete":
      openDeleteTranslatorModal(state.translator);
      return;
    case "logout":
      logoutTranslatorModal.open();
      return;
    default:
      return;
  }
}
</script>

<template>
  <div class="translator">
    <div>
      <div class="translator__title">
        <h3 class="text-h6 translator__header">Operator</h3>
        <span
          >{{ state.translator.firstName }}
          {{ state.translator.lastName }}</span
        >
      </div>
      <div style="margin-top: 5px" v-if="isAdminRole">
        <span class="translator__label--info"> Head: </span>
        {{ getManagerInfo() }}
      </div>
      <div
        v-if="cabinetsStore.getCabinetsByOwnerId(state.translator._id).length"
        style="margin-top: 5px"
      >
        <span class="translator__label--info"> Work Shift: </span>
        {{ getWorkShiftInfo() }}
      </div>
    </div>
    <div style="margin-inline-start: auto"></div>
    <div>
      <v-select
        v-model="availableAction()[0]"
        item-title="label"
        item-value="action"
        :items="availableAction()"
        variant="outlined"
        density="compact"
        hide-details
        hide-selected
        @update:model-value="selectAction($event)"
      >
      </v-select>
    </div>
  </div>
</template>

<style scoped lang="scss">
.translator {
  display: flex;
  column-gap: var(--indent-m);

  &__title {
    display: flex;
    align-items: baseline;
  }

  &__header {
    margin-right: 10px;
  }

  &__actions {
    margin-inline-start: var(--indent-xl);
    display: flex;
    column-gap: var(--indent-m);
    align-items: center;
  }

  &__label {
    &--info {
      color: #b3b3b3;
    }
  }
}
</style>
