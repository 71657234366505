<script setup lang="ts">
import PageHeader from "@/components/PageHeader.vue";
import OperatorsSearch from "@/components/Operators/OperatorsSearch.vue";
import PageContent from "@/components/PageContent.vue";
import type { UserRole } from "@/stores/types/users-types";
import AnalystService from "@/core/services/AnalystService";
import {
  ANALYTICS_EVENT_MODAL_ACTION,
  ANALYTICS_EVENT_ROLE_NAME,
  EVENT_CABINET_MODAL_ACTION,
} from "@/core/enums/amplitude-event.enum";
import ModalService from "@/core/services/ModalService";
import UserForm from "@/components/UserForm/UserForm.vue";
import type { UserFormState } from "@/components/UserForm/user-form-types";
import type { UseModalReturnType } from "vue-final-modal";
import RefCode from "@/components/RefCode/RefCode.vue";
import { useUsersStore } from "@/stores/users-store";
import { useUserProfileStore } from "@/stores/user-profile-store";
import WorkShiftForm from "@/components/WorkShift/WorkShiftForm.vue";
import type { WorkShiftBase } from "@/stores/types/work-shift-types";
import CabinetForm from "@/components/Cabinet/CabinetForm.vue";
import { useCabinetsStore } from "@/stores/cabinets-store";
import { useWorkShiftStore } from "@/stores/work-shift-store";
import WorkShiftList from "@/components/WorkShiftList.vue";
import CabinetsList from "@/components/CabinetsList.vue";
import { reactive } from "vue";
import TranslatorsList from "@/components/TranslatorsList.vue";

const usersStore = useUsersStore();
const cabinetsStore = useCabinetsStore();
const workShiftStore = useWorkShiftStore();
const userProfileStore = useUserProfileStore();

const tab = reactive<{ state: string }>({
  state: "operators",
});

cabinetsStore.indexCabinets();
usersStore.index();
workShiftStore.indexWorkShifts();

let modal: UseModalReturnType<any>;
function openUserModal(role: UserRole): void {
  AnalystService.pushProfileModalEvent({
    modal: ANALYTICS_EVENT_ROLE_NAME.OPERATOR,
    action: ANALYTICS_EVENT_MODAL_ACTION.OPEN,
  });
  modal = ModalService.init(UserForm, {
    role,
    cancelEdit() {
      AnalystService.pushProfileModalEvent({
        modal: ANALYTICS_EVENT_ROLE_NAME.OPERATOR,
        action: ANALYTICS_EVENT_MODAL_ACTION.CANCEL,
      });
      modal.close();
    },
    submit(data: UserFormState): Promise<void> {
      return createUser(data);
    },
    openRefCodeModal() {
      openRefCodeModal();
    },
  });
  modal.open();
}

function openRefCodeModal(): void {
  modal.close();
  refCodeModal.open();
}

const refCodeModal = ModalService.init(RefCode, {
  refCode: userProfileStore.referralCode,
  submit() {
    generateRefCode();
  },
});

async function generateRefCode(): Promise<void> {
  await userProfileStore.generateRefCode();
  await refCodeModal.close();
}

async function createUser(data: UserFormState): Promise<void> {
  if (!data.idParent) {
    data.idParent = useUserProfileStore().userId;
  }
  await usersStore.createTranslator(data);
  await modal.close();
}

function openWorkShiftModal(): void {
  AnalystService.pushWorkShiftModalEvent({
    action: ANALYTICS_EVENT_MODAL_ACTION.OPEN,
  });
  modal = ModalService.init(WorkShiftForm, {
    async submit(data: WorkShiftBase) {
      await workShiftStore.createWorkShift(data).then(() => modal.close());
    },
    cancel() {
      AnalystService.pushWorkShiftModalEvent({
        action: ANALYTICS_EVENT_MODAL_ACTION.CANCEL,
      });
      modal.close();
    },
  });
  modal.open();
}

function openCabinetModal(): void {
  AnalystService.pushCabinetModalEvent({
    action: EVENT_CABINET_MODAL_ACTION.OPEN,
  });
  modal = ModalService.init(CabinetForm, {
    async submit(name: string) {
      await cabinetsStore.createCabinet(name.trim()).then(() => modal.close());
    },
    cancel() {
      AnalystService.pushCabinetModalEvent({
        action: EVENT_CABINET_MODAL_ACTION.CANCEL,
      });
      modal.close();
    },
  });
  modal.open();
}

function isCoreDataLoading(): boolean {
  return workShiftStore.loading && cabinetsStore.loading && usersStore.loading;
}
</script>
<script lang="ts">
export default {};
</script>

<template>
  <page-header>
    <h1 class="text-h5">Operators</h1>
    <template #actions>
      <v-btn @click="openUserModal('translator')" color="primary">
        Create Operator
      </v-btn>
      <v-btn
        @click="openWorkShiftModal"
        :disabled="workShiftStore.loading"
        color="primary"
        >Create Work Shift</v-btn
      >
      <v-btn
        @click="openCabinetModal"
        :disabled="cabinetsStore.loading"
        color="primary"
        >Create Cabinet</v-btn
      >
    </template>
  </page-header>
  <page-content class="content">
    <operators-search></operators-search>
  </page-content>
  <page-content class="cabinet-content">
    <template v-if="!isCoreDataLoading()">
      <v-tabs v-model="tab.state">
        <v-tab value="operators">Operators</v-tab>
        <v-tab value="cabinets">Cabinets</v-tab>
        <v-tab value="work shifts">Work Shifts</v-tab>
      </v-tabs>
      <v-window v-model="tab.state">
        <v-window-item value="operators">
          <translators-list
            :translators="usersStore.allTranslators"
            :operators-tab="true"
          ></translators-list>
        </v-window-item>
        <v-window-item value="cabinets">
          <cabinets-list :cabinets="cabinetsStore.allSorted"></cabinets-list>
        </v-window-item>
        <v-window-item value="work shifts">
          <work-shift-list
            :work-shifts="workShiftStore.allSorted"
          ></work-shift-list>
        </v-window-item>
      </v-window>
    </template>
    <div class="cabinet-content__loader" v-if="isCoreDataLoading()">
      <v-progress-circular size="50" :width="5" color="primary" indeterminate />
    </div>
  </page-content>
</template>

<style scoped lang="scss"></style>
