import { defineStore } from "pinia";
import type { Cabinet, CabinetStore } from "@/stores/types/cabinets-types";
import ApiCabinet from "@/shared/api/ApiCabinet";
import { useUsersStore } from "@/stores/users-store";
import type { Operator } from "@/stores/types/users-types";
import AnalystService from "@/core/services/AnalystService";
import { ANALYTICS_EVENT_TU_ACTION } from "@/core/enums/amplitude-event.enum";
import { CABINET_ACTIONS_NAME } from "@/core/enums/amplitude-event.enum";
import { useUserProfileStore } from "@/stores/user-profile-store";
import { sortByField } from "@/core/functions/sort-by-field";
import { useWorkShiftStore } from "@/stores/work-shift-store";

export const useCabinetsStore = defineStore({
  id: "cabinets",
  state: (): CabinetStore => {
    return {
      loading: false,
      cabinets: [],
    };
  },
  getters: {
    all: ({ cabinets }): Cabinet[] => [...cabinets],
    allSorted: ({ cabinets }): Cabinet[] => sortByField([...cabinets], "name"),
    allAvailable:
      ({ cabinets }) =>
      (id: string) => {
        const filtered = [...cabinets].filter(
          (cabinet) => !cabinet.idOperators.includes(id)
        );
        return sortByField(filtered, "name");
      },
    getCabinetById:
      ({ cabinets }) =>
      (id: string) =>
        [...cabinets].find((cabinet) => id === cabinet._id),
    getCabinetsByOwnerId:
      ({ cabinets }) =>
      (id: string) =>
        [...cabinets].filter((cabinet) => cabinet.idOperators.includes(id)),
  },
  actions: {
    async indexCabinets(): Promise<void> {
      this.loading = true;

      const response = await ApiCabinet.getCabinets();

      this.loading = false;
      this.cabinets = [...response];
    },
    async createCabinet(name: string): Promise<void> {
      const response = await ApiCabinet.createCabinet(name);

      AnalystService.pushCabinetEvent({
        role: useUserProfileStore().role,
        action: CABINET_ACTIONS_NAME.CREATE,
        idCabinet: response._id,
      });
      this.cabinets.push(response);
    },
    async editCabinet(name: string, id: string): Promise<void> {
      await ApiCabinet.editCabinet(name, id);
      AnalystService.pushCabinetEvent({
        role: useUserProfileStore().role,
        action: CABINET_ACTIONS_NAME.EDIT,
        idCabinet: id,
      });

      this.cabinets = this.cabinets.map((cabinet) => {
        if (cabinet._id === id) {
          return { ...cabinet, name };
        }

        return cabinet;
      });
    },
    async deleteCabinet(id: string): Promise<void> {
      await ApiCabinet.deleteCabinet(id);

      const cabinet = this.getCabinetById(id);
      if (cabinet && cabinet.idTUs.length) {
        await useUsersStore().indexOperators();
      }

      AnalystService.pushCabinetEvent({
        role: useUserProfileStore().role,
        action: CABINET_ACTIONS_NAME.DELETE,
        idCabinet: id,
      });

      this.cabinets = this.cabinets.filter((cabinet) => cabinet._id !== id);
    },
    async assignCabinetTU(
      operator: Operator,
      password: string,
      id: string
    ): Promise<void> {
      const response = await ApiCabinet.assignTU(operator.idTU, password, id);

      if (response) {
        AnalystService.pushTUEvent({
          action: ANALYTICS_EVENT_TU_ACTION.ADD_TO_CABINET,
          idCabinet: id,
        });
        useUsersStore().handleAssignResponse(response as string, operator);
        const cabinet = this.getCabinetById(id);

        if (cabinet) {
          cabinet.idTUs = [...cabinet.idTUs, operator.idTU];
        }
      }
    },
    async unAssignCabinetTU(idTU: number, id: string): Promise<void> {
      await ApiCabinet.unAssignTU(idTU, id);

      const cabinet = this.getCabinetById(id);

      if (cabinet) {
        AnalystService.pushTUEvent({
          action: ANALYTICS_EVENT_TU_ACTION.REMOVE_FROM_CABINET,
          idCabinet: id,
        });
        cabinet.idTUs = cabinet.idTUs.filter((id) => id !== idTU);

        await useUsersStore().indexOperators();
      }
    },
    async assignCabinet(idOperator: string, id: string): Promise<void> {
      await ApiCabinet.assignCabinet(idOperator, id);

      const postResultPromises: Promise<void>[] = [this.indexCabinets()];
      const userStore = useUsersStore();
      const operator = userStore.getTranslatorById(idOperator);

      if (operator && operator.childrenTU.length) {
        userStore.resetOperatorChildren(idOperator);
        postResultPromises.push(userStore.indexOperators());
      }
      AnalystService.pushCabinetEvent({
        role: useUserProfileStore().role,
        action: CABINET_ACTIONS_NAME.ASSIGN,
        idCabinet: id,
      });

      await Promise.all(postResultPromises);
    },
    async unAssignCabinet(idOperator: string, id: string): Promise<void> {
      await ApiCabinet.unAssignCabinet(idOperator, id);

      const workShiftStore = useWorkShiftStore();
      const workShift = workShiftStore.getWorkShiftOwnerId(idOperator);

      if (workShift) await workShiftStore.indexWorkShifts();

      AnalystService.pushCabinetEvent({
        role: useUserProfileStore().role,
        action: CABINET_ACTIONS_NAME.UNASSIGN,
        idCabinet: id,
      });
      this.cabinets = this.cabinets.map((cabinet) => {
        if (cabinet._id === id) {
          return {
            ...cabinet,
            idOperators: cabinet.idOperators.filter((id) => id !== idOperator),
          };
        }

        return cabinet;
      });
    },
  },
});
