import { useCurrentPageStore } from "@/stores/current-page-store";
import { debounce, replace } from "lodash-es";
import "url-change-event";
import type { RouteRecordRaw } from "vue-router";

export const MENU_CONTAINER_ID = "ext-menu-container";

export const pageContainer = () =>
  document.querySelector(".page") || document.querySelector(".main-content");
export const pageSubContainer = () =>
  pageContainer()?.querySelector(".container") ||
  pageContainer()?.querySelector(".statistics") ||
  pageContainer()?.querySelector(".settings") ||
  pageContainer()?.querySelector(".profile-woman");

export const createAppContainer = (): void => {
  const appElement = document.createElement("div");
  appElement.id = "app";
  pageContainer()?.appendChild(appElement);
};
const showExtensionPage = () => {
  pageContainer()?.classList.add("ext-page");
  pageSubContainer()?.classList.add("hide");
  document.querySelector("#app")?.classList.remove("hide");
};
const hideExtensionPage = () => {
  pageContainer()?.classList.remove("ext-page");
  pageSubContainer()?.classList.remove("hide");
  document.querySelector("#app")?.classList.add("hide");
};
export const createMenuContainer = () => {
  const container = document.createElement("div");
  container.id = MENU_CONTAINER_ID;
  document.querySelector(".sidebar__btn")?.after(container);
};
export const removeAppActiveLink = () => {
  document
    .querySelectorAll(".sidebar__menu > a")
    .forEach((item) => item.classList.remove("is-active"));
};

export const listenRouteChanges = (
  routes: RouteRecordRaw[],
  isSpa?: boolean
) => {
  document.addEventListener("DOMContentLoaded", () => {
    extensionRoute(window.location.pathname);
  });
  window.addEventListener("urlchangeevent", function ($e) {
    extensionRoute($e.newURL?.pathname);
  });

  const extensionRoute = debounce(_extensionRoute, 50);

  function _extensionRoute(url: string | undefined): void {
    const currentPageStore = useCurrentPageStore();
    if (!url) return;
    if (isSpa) {
      currentPageStore.set(url);
      return;
    }

    const activeRoute = routes.find((route) =>
      compareUrl(url || "", route.path)
    );

    currentPageStore.set(url);
    if (activeRoute) {
      showExtensionPage();
      removeAppActiveLink();
      currentPageStore.setAppPage("");
    } else {
      currentPageStore.setAppPage(url);
      hideExtensionPage();
    }
  }
};

const compareUrl = (currentUrl: string, url: string): boolean => {
  if (url === "/:pathMatch(.*)*") {
    return false;
  }
  currentUrl = replace(currentUrl, "#", "");
  if (url === currentUrl) {
    return true;
  }
  const splitUrl = url.split("/");
  const splitCurrentUrl = currentUrl.split("/");
  const parameters: { [key: string]: string } = {};
  if (splitCurrentUrl.length !== splitUrl.length) {
    return false;
  }

  for (let i = 0; i < splitUrl.length; i++) {
    const urlPath = splitUrl[i];
    const currentUrlPath = splitCurrentUrl[i];

    if (urlPath.includes(":")) {
      parameters[urlPath.replace(":", "")] = currentUrlPath;
      continue;
    }

    if (urlPath !== currentUrlPath) {
      return false;
    }
  }

  return Boolean(Object.keys(parameters).length);
};
