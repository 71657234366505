<script setup lang="ts">
import AssignOperatorList from "@/components/AssignOperatorList.vue";
import type { Manager as ManagerType } from "@/stores/types/users-types";
import type { Cabinet as CabinetType } from "@/stores/types/cabinets-types";

const props = defineProps<{
  source: CabinetType | ManagerType;
}>();

const getOperatorsCount = (): number => {
  const { role } = props.source as ManagerType;

  if (role === "manager") {
    return (props.source as ManagerType).childrenTU.length;
  }

  return (props.source as CabinetType).idTUs.length;
};
</script>
<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-title>
        <div
          style="
            display: flex;
            align-items: center;
            column-gap: var(--indent-m);
          "
        >
          <h4>{{ getOperatorsCount() }} Profiles</h4>
        </div>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <assign-operator-list :source="props.source"></assign-operator-list>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<style scoped lang="scss"></style>
