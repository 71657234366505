import type {
  Manager,
  ResponseUser,
  Translator,
  User,
} from "@/stores/types/users-types";
import { factory } from "@/core";

export const managerFactory = (manager: Partial<Manager>): Manager =>
  factory(manager, {
    _id: "",
    idParent: "",
    email: "",
    translators: [],
    firstName: "",
    lastName: "",
    role: "manager",
    label: "",
    childrenTU: [],
    referralCode: 0,
  });
export const translatorFactory = (
  translator: Partial<Translator>
): Translator =>
  factory(translator, {
    _id: "",
    idParent: "",
    email: "",
    firstName: "",
    lastName: "",
    role: "translator",
    label: "",
    childrenTU: [],
    referralCode: 0,
  });
type TranslatorOrManager = Translator | Manager;

export function responseUserToUser(
  user: ResponseUser & { pass?: string }
): User {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pass, ...userWithoutPass } = user;
  return {
    ...userWithoutPass,
    label: `${user.firstName} ${user.lastName} (${user.email})`,
  };
}

export function userFactory(
  user: Pick<TranslatorOrManager, "role"> & Partial<TranslatorOrManager>
): TranslatorOrManager {
  if (user.role === "manager") {
    return managerFactory(user);
  }
  return translatorFactory(user);
}
