import { defineStore } from "pinia";
import { environment } from "@/envinronment";
import { ApiShared } from "@/shared/api";
import type { AppStore } from "@/stores/types/app-store-types";

export const useAppStore = defineStore("app", {
  state: (): AppStore => {
    return {
      codeVersion: 1,
      standAlone: environment.standAloneMode,
      extensionApiUrl: environment.extensionApiUrl,
      featureFlags: [],
    };
  },
  getters: {
    isCodeCompatibility: (state) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      environment.codeCompatibilityVersion >= state.codeVersion,
    isStandAloneMode: ({ standAlone }) => standAlone,
    isDemoPointed: ({ extensionApiUrl }) =>
      extensionApiUrl?.toString().includes("localhost:3000/api") ||
      extensionApiUrl?.toString().includes("api.demo.chatterly.app"),
  },
  actions: {
    async getCodeVersion(): Promise<void> {
      this.codeVersion = await ApiShared.getCodeVersion();
    },

    async getFeatureFlagList(): Promise<void> {
      const list = await ApiShared.getFeatureFlagList();

      this.featureFlags = [...list];
    },
  },
});
