import { RESPONSE_ERROR_TYPE } from "@/core/enums/response-error-type";
import { DISPLAY_ROLE_NAME } from "@/core/enums/display-role.enum";
import type { AxiosError } from "axios";
import type { ServerError } from "@/core";
import type { Operator } from "@/stores/types/users-types";

export function getChangePassErrorMessage(
  operator: Operator,
  pass: string,
  e: AxiosError<ServerError>
): string {
  if (e?.response?.data?.message === RESPONSE_ERROR_TYPE.BAD_PASSWORD) {
    return `${pass} is not the correct password for ${name}'s profile with id:${operator.idTU}. Please enter the password from your ${name} profile that you use to log in to platform`;
  }

  return `Something went wrong during updating password for ${operator.name}'s profile with id:${operator.idTU}`;
}

export function getValidationPassErrorMessage(
  operator: Operator,
  pass: string,
  e: AxiosError<ServerError>
): string {
  if (e?.response?.data?.message === RESPONSE_ERROR_TYPE.BAD_PASSWORD) {
    return `${pass} is not the correct password for ${name}'s profile with id:${operator.idTU}. Please enter the password from your ${name} profile that you use to log in to platform`;
  }

  return `Something went wrong during validating password for ${operator.name}'s profile with id:${operator.idTU}`;
}

export function getValidationPassSuccessMessage(
  operator: Operator,
  pass: string
): string {
  return `${pass} is the correct password for ${operator.name}'s profile with id:${operator.idTU}`;
}

export function getAssignOperatorErrorMessage(operator: Operator): string {
  return `Something went wrong during assigning ${operator.name}'s profile with id:${operator.idTU}`;
}

export function getServerErrorMessage(e: AxiosError<ServerError>) {
  return e?.response?.data?.message;
}

export function getCreateExternalErrorMessage(
  e: AxiosError<ServerError>
): string {
  if (getServerErrorMessage(e) === RESPONSE_ERROR_TYPE.INVALID_CREDENTIAL) {
    return "Invalid credentials or user does not exist";
  }

  return "Something went wrong";
}

export function getCreateUpdateUserErrorMessage(
  role: DISPLAY_ROLE_NAME,
  e: AxiosError<ServerError>
): string {
  const roleText =
    role === DISPLAY_ROLE_NAME.MANAGER ? `A ${role}` : `An ${role}`;
  if (getServerErrorMessage(e) === RESPONSE_ERROR_TYPE.DUPLICATE_EMAIL) {
    return `${roleText} already exists at this email address. Please use another email address.`;
  }
  if (getServerErrorMessage(e) === RESPONSE_ERROR_TYPE.REFERRAL_CODE) {
    return "Error generating referral code. Contact support to resolve this issue.";
  }
  if (getServerErrorMessage(e) === RESPONSE_ERROR_TYPE.EMAIL_ALREADY_EXISTS) {
    return "Email already exist";
  }

  return "Something went wrong";
}

export function getWorkShiftErrorMessage(
  e: AxiosError<ServerError>,
  isEdit = false
): string {
  if (
    getServerErrorMessage(e as AxiosError<ServerError>) ===
    RESPONSE_ERROR_TYPE.START_TIME
  ) {
    return "Start time could not be greater than end time, please select other one";
  }

  const actionText = isEdit ? "editing" : "creating";

  return `Something went wrong during work shift ${actionText}`;
}
