import { Http } from "@/core/api";
import type { BaseCredentials } from "@/core";
import type { Operator } from "@/stores/types/users-types";

class ApiTU {
  getExternalTU(): Promise<Operator[]> {
    return Http.get("tu/external/all");
  }

  getUnassignedTU(data: {
    cursor: string;
    limit: number;
    filter: { firstName: string; idTU: string };
  }): Promise<{ TUs: Operator[]; cursor: string }> {
    return Http.post("tu/unassigned/all", data);
  }

  getAssignTU(): Promise<Operator[]> {
    return Http.get("tu/assigned/all");
  }

  checkTUsDeactivate(
    idTUs: number[]
  ): Promise<{ idTU: number; isDeactivated: boolean }[]> {
    return Http.post("tu/deactivated/all", { idTUs });
  }

  checkPasswordByIds(ids: string[]): Promise<{ [key: string]: boolean }> {
    return Http.post<{ [key: string]: boolean }>("/tu/check-pass", {
      ids,
    });
  }

  createExternalTU(data: BaseCredentials): Promise<Operator> {
    return Http.post<Operator>("/tu/add-external-profile", data);
  }

  changePassword(data: { id: string; pass: string }): Promise<boolean> {
    return Http.post<boolean>("/tu/change-pass", data);
  }

  changeEmail(data: { id: string; email: string }): Promise<boolean> {
    return Http.post<boolean>("/tu/change-email", data);
  }

  assignTU(
    operator: Operator,
    idOwner: string
  ): Promise<Operator["_id"] | false> {
    return Http.post<Operator["_id"] | false>("/tu/assign", {
      idTU: `${operator.idTU}`,
      email: operator.email ? operator.email : "",
      avatar: operator.avatar,
      name: operator.name,
      age: `${operator?.age}`,
      idOwner,
    });
  }

  deleteTU(idTU: number, idOwner: string): Promise<boolean> {
    return Http.post<boolean>("/tu/remove", { idTU, idOwner });
  }

  deleteExternalTU(idTU: number): Promise<boolean> {
    return Http.post<boolean>("/tu/remove-external", { idTU });
  }

  validatePassword(idTU: number, pass: string): Promise<boolean> {
    return Http.post<boolean>("/tu/check-valid-pass", { idTU, pass });
  }
}

export default new ApiTU();
