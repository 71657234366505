import { useUsersStore } from "@/stores/users-store";

type UserFormRulesOptions = {
  email?: string;
  isTranslator: boolean;
  isEdit: boolean;
};

export function userFormRules({
  isTranslator,
  email,
  isEdit,
}: UserFormRulesOptions) {
  const usersStore = useUsersStore();
  return {
    email: [
      (v?: string) => !!v || "Email is Required",
      (v: string) => /.+@.+/.test(v) || "Not Valid Email",
      (v: string) => {
        return (
          email === v ||
          !usersStore.all.find((user) => user.email === v) ||
          "This email used"
        );
      },
    ],
    firstName: [(v?: string) => !!v || "First Name is required"],
    lastName: [(v?: string) => !!v || "Last Name is Required"],
    password: getPasswordRules(isEdit),
    idParent: [(v?: string) => !isTranslator || !!v || "Manager is required"],
  };

  function getPasswordRules(isEdit: boolean) {
    if (isEdit) {
      return [
        (v: string) =>
          !v || v.length >= 6 || "Password length must be more than 6 chars",
      ];
    }
    return [
      (v?: string) => !!v || "Password is required",
      (v: string) =>
        v.length >= 6 || "Password length must be more than 6 chars",
    ];
  }
}
