import { defineStore } from "pinia";
import type {
  AnyUser,
  Manager,
  Operator,
  OperatorsGroup,
  Translator,
  UpdateUser,
  User,
} from "@/stores/types/users-types";
import type { BaseCredentials } from "@/core";
import type { UserFormState } from "@/components/UserForm/user-form-types";
import {
  managerFactory,
  responseUserToUser,
  translatorFactory,
} from "@/stores/helpers/users-helper";
import { ApiManager, ApiOperator, ApiTU, ApiRefCode } from "@/shared/api";
import AnalystService from "@/core/services/AnalystService";
import {
  ANALYTICS_EVENT_PROFILE_ACTION,
  ANALYTICS_EVENT_ROLE_NAME,
  ANALYTICS_EVENT_TU_ACTION,
} from "@/core/enums/amplitude-event.enum";
import { TU_TYPE_ENUM } from "@/core/enums/tu-type.enum";
import { useUserProfileStore } from "@/stores/user-profile-store";

type UsersState = {
  loading: boolean;
  managers: User[];
  translators: User[];
  operators: OperatorsGroup;
  limit: number;
  cursor: string;
  allTULoaded: boolean;
};

export const useUsersStore = defineStore({
  id: "users",
  state: (): UsersState => {
    return {
      loading: false,
      managers: [],
      translators: [],
      operators: {
        unassigned: [],
        assigned: [],
        external: [],
        passwordMap: {},
        loading: true,
      },
      limit: 20,
      cursor: "",
      allTULoaded: false,
    };
  },
  getters: {
    all: ({ operators, managers, translators }): AnyUser[] => [
      ...operators.unassigned,
      ...operators.assigned,
      ...operators.external,
      ...managers,
      ...translators,
    ],
    allOperators: ({ operators }): Operator[] => [
      ...operators.assigned,
      ...operators.external,
      ...operators.unassigned,
    ],
    allTranslators: ({ translators }): Translator[] =>
      translators.map((translator) => ({
        ...translator,
        role: "translator",
      })),
    managersTree: ({ managers, translators }): Manager[] =>
      managers.map((manager) => ({
        ...manager,
        role: "manager",
        translators: translators
          .filter((translator) => translator.idParent === manager._id)
          .map((translator) => ({
            ...translator,
            role: "translator",
          })),
      })),

    getUserById:
      ({ managers, translators }) =>
      (id: string) =>
        [...managers, ...translators].find(({ _id }) => _id === id),

    getTranslatorById:
      ({ translators }) =>
      (translatorId: string): Translator => {
        const translator = translators.find(({ _id }) => _id === translatorId);
        return {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ...translator!,
          role: "translator",
        };
      },

    getTranslatorsByIds:
      ({ translators }) =>
      (ids: string[]): Translator[] =>
        [...translators]
          .filter((translator) => ids.includes(translator._id))
          .map((translator) => ({ ...translator, role: "translator" })),

    getOperatorByOriginalId:
      ({ operators: { assigned, unassigned, external } }) =>
      (id: number): Operator | undefined =>
        [...assigned, ...unassigned, ...external].find(
          ({ idTU }) => idTU === id
        ),

    getManagerById:
      ({ managers, translators }) =>
      (managerId: string): Manager => {
        const manager = managers.find(({ _id }) => _id === managerId);
        return {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ...manager!,
          role: "manager",
          translators: translators
            .filter((translator) => translator.idParent === manager?._id)
            .map((translator) => ({
              ...translator,
              role: "translator",
            })),
        };
      },
    getAssignedOperatorsIds: ({ operators }): string[] => {
      return operators.assigned.map((o) => o._id);
    },
    checkOperatorPasswordById:
      ({ operators: { passwordMap } }) =>
      (id: string): boolean =>
        !!id && passwordMap[id],
  },
  actions: {
    async indexManagers(): Promise<User[]> {
      const response = await ApiManager.getManagers();
      this.managers = response.map((user) => responseUserToUser(user));
      return this.managers;
    },

    async indexTranslators(): Promise<User[]> {
      const response = await ApiOperator.getOperators();
      this.translators = response.map((user) => responseUserToUser(user));
      return this.translators;
    },

    async indexOperators(): Promise<void> {
      this.operators.loading = true;
      this.cursor = "";
      if (useUserProfileStore().isAdminRole) {
        const [unassignedOperator, externalOperator, assignedOperator] =
          await Promise.all([
            this.loadUnassignedTUs(),
            ApiTU.getExternalTU().then((tus) => {
              tus.forEach((tu) => {
                tu.tuType = TU_TYPE_ENUM.EXTERNAL;
              });
              return tus;
            }),
            ApiTU.getAssignTU().then((tus) => {
              tus.forEach((tu) => {
                tu.tuType = TU_TYPE_ENUM.ASSIGNED;
              });
              return tus;
            }),
          ]);
        const newOperators: {
          external: Operator[];
          assigned: Operator[];
          unassigned: Operator[];
        } = {
          assigned: assignedOperator,
          external: externalOperator,
          unassigned: unassignedOperator,
        };
        this.operators = {
          ...this.operators,
          ...newOperators,
        };
      } else {
        const [unassignedOperator, assignedOperator, externalOperator] =
          await Promise.all([
            ApiManager.getUnassignedManagerTU().then((tus) => {
              tus.forEach((tu) => {
                tu.tuType = TU_TYPE_ENUM.UNASSIGNED;
              });
              return tus;
            }),
            ApiManager.getAssignManagerTU().then((tus) => {
              tus.forEach((tu) => {
                tu.tuType = TU_TYPE_ENUM.ASSIGNED;
              });
              return tus;
            }),
            ApiManager.getExternalManagerTU().then((tus) => {
              tus.forEach((tu) => {
                tu.tuType = TU_TYPE_ENUM.EXTERNAL;
              });
              return tus;
            }),
          ]);
        const newOperators: {
          assigned: Operator[];
          unassigned: Operator[];
          external: Operator[];
        } = {
          assigned: assignedOperator,
          unassigned: unassignedOperator,
          external: externalOperator,
        };
        this.operators = {
          ...this.operators,
          ...newOperators,
        };
      }
      await this.checkOperatorsPassword();
      this.operators.loading = false;
    },

    async checkOperatorsPassword(): Promise<void> {
      if (useUserProfileStore().isAdminRole) {
        if (this.operators.assigned.length) {
          const ids = this.getAssignedOperatorsIds;
          this.operators.passwordMap = await ApiTU.checkPasswordByIds(ids);
        }
      } else {
        if (
          this.operators.assigned.length ||
          this.operators.unassigned.length
        ) {
          const assignedIds = this.getAssignedOperatorsIds;
          const unassignedIds = this.operators.unassigned.map((o) => o._id);
          const ids = [...assignedIds, ...unassignedIds];
          this.operators.passwordMap = await ApiTU.checkPasswordByIds(ids);
        }
      }
    },

    async index(): Promise<void> {
      await this.indexManagers();
      await this.indexTranslators();
      await this.indexOperators();
      AnalystService.pushLoadHomePage();
    },

    async createTranslator(data: UserFormState): Promise<Translator> {
      this.loading = true;

      try {
        const res = await ApiOperator.createOperators(data);
        this.loading = false;

        const translator = translatorFactory({
          ...data,
          role: "translator",
          _id: res._id,
          idParent: res.idParent || data.idParent,
          label: `${data.firstName} ${data.lastName} (${data.email})`,
        });
        AnalystService.pushProfileEvent({
          role: ANALYTICS_EVENT_ROLE_NAME.OPERATOR,
          action: ANALYTICS_EVENT_PROFILE_ACTION.CREATE,
          email: data.email,
        });
        const translatorEmail = useUsersStore().getUserById(
          data.idParent || res.idParent
        )?.email;
        AnalystService.pushProfileEvent({
          role: ANALYTICS_EVENT_ROLE_NAME.MANAGER,
          action: ANALYTICS_EVENT_PROFILE_ACTION.ASSIGN,
          email: translatorEmail || "",
        });

        this.translators.push(translator);

        return translator;
      } catch (e) {
        this.loading = false;
        throw e;
      }
    },

    async createManager(data: UserFormState): Promise<Manager> {
      this.loading = true;

      try {
        const res = await ApiManager.createManager(data);
        this.loading = false;

        const manager = managerFactory({
          role: "manager",
          _id: res._id,
          idParent: data.idParent || res.idParent,
          label: `${data.firstName} ${data.lastName} (${data.email})`,
          ...data,
        });
        AnalystService.pushProfileEvent({
          role: ANALYTICS_EVENT_ROLE_NAME.MANAGER,
          action: ANALYTICS_EVENT_PROFILE_ACTION.CREATE,
          email: data.email,
        });
        this.managers.push(manager);

        return manager;
      } catch (e) {
        this.loading = false;
        throw e;
      }
    },

    async createExternalOperator(data: BaseCredentials): Promise<Operator> {
      this.loading = true;

      try {
        const externalOperator = await ApiTU.createExternalTU(data);
        externalOperator.tuType = TU_TYPE_ENUM.EXTERNAL;
        AnalystService.pushExternalProfileAddedEvent({
          idProfile: externalOperator.idTU,
        });
        this.loading = false;
        this.operators.external.push(externalOperator);

        return externalOperator;
      } catch (e) {
        this.loading = false;
        throw e;
      }
    },

    async updateTranslator(
      translator: Translator,
      data: UpdateUser
    ): Promise<Translator> {
      this.loading = true;
      const id = translator._id;
      this.translators = this.translators.map((user) =>
        user._id === id ? translator : user
      );
      AnalystService.pushProfileEvent({
        role: ANALYTICS_EVENT_ROLE_NAME.OPERATOR,
        action: ANALYTICS_EVENT_PROFILE_ACTION.EDIT,
        email: data.email || "",
      });

      if (data?.idParent !== translator.idParent) {
        AnalystService.pushProfileEvent({
          role: ANALYTICS_EVENT_ROLE_NAME.MANAGER,
          action: ANALYTICS_EVENT_PROFILE_ACTION.REASSIGN,
          email: useUsersStore().getManagerById(<string>data.idParent).email,
        });
      }

      await ApiOperator.updateOperator(id, data);
      this.loading = false;

      return translator;
    },

    async updateManager(manager: Manager, data: UpdateUser): Promise<Manager> {
      const id = manager._id;
      this.managers = this.managers.map((m) => {
        if (m._id === id) {
          return { ...m, ...data };
        }
        return m;
      });
      AnalystService.pushProfileEvent({
        role: ANALYTICS_EVENT_ROLE_NAME.MANAGER,
        action: ANALYTICS_EVENT_PROFILE_ACTION.EDIT,
        email: data.email || "",
      });

      await ApiManager.updateManager(id, data);

      return { ...manager, ...data };
    },

    async changeOperatorPassword(data: { id: string; pass: string }) {
      await ApiTU.changePassword(data);
    },

    async changeOperatorEmail(data: { id: string; email: string }) {
      await ApiTU.changeEmail(data);
    },

    async validateOperatorPassword(
      idTU: number,
      pass: string
    ): Promise<boolean> {
      return await ApiTU.validatePassword(idTU, pass);
    },

    async assignOperatorRequest(operator: Operator, idOwner: string) {
      const result: Operator["_id"] | false = await ApiTU.assignTU(
        operator,
        idOwner
      );

      if (result) {
        this.handleAssignResponse(result, operator);
      } else {
        throw new Error(`Error assign operator ${operator.idTU}`);
      }
    },

    async deleteOperator(id: string, idOwner: string): Promise<void> {
      const operator = [
        ...this.operators.assigned,
        ...this.operators.external,
      ].find((operator) => operator._id === id);

      if (operator) {
        await ApiTU.deleteTU(operator?.idTU, idOwner);

        [...this.managers, ...this.translators].forEach((user) => {
          if (user._id == idOwner) {
            user.childrenTU = user.childrenTU.filter(
              (tu) => tu !== operator.idTU
            );
          }
        });
        AnalystService.pushTUEvent({
          action: ANALYTICS_EVENT_TU_ACTION.REMOVE_FROM_OPERATOR,
          idParent: idOwner,
        });

        await this.indexOperators();
      }
    },

    async deleteExternalOperator(id: string, idTU: number): Promise<void> {
      await ApiTU.deleteExternalTU(idTU);

      const index = this.operators.external.findIndex(
        (item) => item._id === id
      );

      this.operators.external.splice(index, 1);

      [...this.managers, ...this.translators].forEach((user) => {
        user.childrenTU = user.childrenTU.filter((tu) => tu !== idTU);
      });
      AnalystService.pushTUEvent({
        action: ANALYTICS_EVENT_TU_ACTION.REMOVE_FROM_OPERATOR,
      });
    },

    async assignOperator({
      operator,
      parentId,
    }: {
      operator: Operator;
      parentId: string;
    }): Promise<void> {
      await this.assignOperatorRequest(operator, parentId);
      AnalystService.pushTUEvent({
        action: ANALYTICS_EVENT_TU_ACTION.ADD_TO_OPERATOR,
        idParent: parentId,
      });
      const user = useUsersStore().getUserById(parentId)!;
      user.childrenTU = [...new Set([...user.childrenTU, operator.idTU])];
    },

    async deleteManager(id: string): Promise<void> {
      await ApiManager.deleteManager(id);
      const manager = this.getManagerById(id);
      AnalystService.pushProfileEvent({
        role: ANALYTICS_EVENT_ROLE_NAME.MANAGER,
        action: ANALYTICS_EVENT_PROFILE_ACTION.DELETE,
        email: manager.email,
      });
      this.managers = this.managers.filter((user) => user._id !== id);
      await this.indexTranslators();
      await this.indexOperators();
    },

    async deleteTranslator(id: string): Promise<void> {
      await ApiOperator.deleteOperator(id);
      const translator = this.getTranslatorById(id);
      AnalystService.pushProfileEvent({
        role: ANALYTICS_EVENT_ROLE_NAME.OPERATOR,
        action: ANALYTICS_EVENT_PROFILE_ACTION.DELETE,
        email: translator.email,
      });
      this.translators = this.translators.filter((user) => user._id !== id);
      await this.indexOperators();
    },

    handleAssignResponse(id: string, operator: Operator): void {
      if (operator.tuType === TU_TYPE_ENUM.UNASSIGNED) {
        const index = this.operators.unassigned.findIndex(
          (item) => item.idTU === operator.idTU
        );
        const tu = this.operators.unassigned[index];

        if (tu) {
          this.operators.unassigned.splice(index, 1);
        }
        operator._id = id;
        operator.tuType = TU_TYPE_ENUM.ASSIGNED;
        this.operators.assigned.push(operator);
      }
    },

    resetOperatorChildren(idOperator: string): void {
      this.translators = this.translators.map((translator) => {
        if (translator._id === idOperator) {
          return {
            ...translator,
            childrenTU: [],
          };
        }

        return translator;
      });
    },

    resetUsersStore(): void {
      this.$reset();
    },

    async updateReferralCode(
      user: Manager | Translator
    ): Promise<Manager | Translator> {
      const { referralCode } = await ApiRefCode.create(user._id);
      AnalystService.pushProfileEvent({
        role:
          user.role === "manager"
            ? ANALYTICS_EVENT_ROLE_NAME.MANAGER
            : ANALYTICS_EVENT_ROLE_NAME.OPERATOR,
        action: ANALYTICS_EVENT_PROFILE_ACTION.CHANGE_REF_CODE,
        email: user.email || "",
      });
      if (user.role === "manager") {
        this.managers.map((manager) => {
          if (manager._id === user._id) {
            manager.referralCode = referralCode;
          }
          return manager;
        });
      } else {
        this.translators.map((translator) => {
          if (translator._id === user._id) {
            translator.referralCode = referralCode;
          }
          return translator;
        });
      }
      return user;
    },

    async fetchTUStatus(idTUs: number[]): Promise<void> {
      if (!idTUs.length) return;
      return await ApiTU.checkTUsDeactivate(idTUs).then((checkedTUs) => {
        checkedTUs.forEach((tu) => {
          const index = this.operators.assigned.findIndex(
            ({ idTU }) => idTU === tu.idTU
          );
          if (index >= 0)
            this.operators.assigned[index].isDeactivated = tu.isDeactivated;
        });
      });
    },

    async loadUnassignedTUs(
      searchByIdTU = "",
      searchByNameTU = ""
    ): Promise<Operator[]> {
      const dataUnassignedOperator = {
        cursor: this.cursor,
        limit: this.limit,
        filter: { firstName: searchByNameTU, idTU: searchByIdTU },
      };
      return ApiTU.getUnassignedTU(dataUnassignedOperator).then(
        ({ TUs, cursor }) => {
          this.cursor = cursor;
          if (TUs.length < this.limit) {
            this.allTULoaded = true;
          }
          TUs.forEach((tu) => {
            tu.tuType = TU_TYPE_ENUM.UNASSIGNED;
          });
          return TUs;
        }
      );
    },

    async loadAdditionalUnassignedTUs(
      searchByIdTU: string,
      searchByNameTU: string
    ): Promise<void> {
      const additionalTUs = await this.loadUnassignedTUs(
        searchByIdTU,
        searchByNameTU
      );
      this.operators.unassigned.push(...additionalTUs);
    },

    async loadUnassignedTUsWithNewSearch(
      searchByIdTU: string,
      searchByNameTU: string
    ) {
      this.cursor = "";
      this.allTULoaded = false;
      this.operators.unassigned = [];
      this.operators.unassigned = await this.loadUnassignedTUs(
        searchByIdTU,
        searchByNameTU
      );
    },

    async logoutTranslator(id: string): Promise<void> {
      await ApiOperator.logoutOperator(id);
    },
  },
});
