import { useWorkShiftStore } from "@/stores/work-shift-store";

export function workShiftFormRules(name: string) {
  const workShiftStore = useWorkShiftStore();

  return {
    name: [
      (v?: string) => !!v?.trim() || "Name is Required",
      (v: string) => {
        return (
          name.trim() === v.trim() ||
          !workShiftStore.all.find(
            (cabinet) => cabinet.name.trim() === v.trim()
          ) ||
          "This name is already used"
        );
      },
    ],
    timeZone: [(v?: string) => !!v?.trim() || "Time Zone is Required"],
    startTime: [(v?: string) => !!v?.trim() || "Start Time is Required"],
    endTime: [(v?: string) => !!v?.trim() || "End Time is Required"],
  };
}
