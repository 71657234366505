import type { Component } from "vue";
import { useModal, useModalSlot, VueFinalModal } from "vue-final-modal";

class ModalService {
  // eslint-disable-next-line @typescript-eslint/ban-types
  init<ModalProps extends Object>(component: Component, props?: ModalProps) {
    const modal = useModal({
      component: VueFinalModal,
      attrs: {
        class: "d-flex justify-center align-center",
        contentTransition: "vfm-fade",
        overlayTransition: "vfm-fade",
      },
      slots: {
        default: useModalSlot({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          component,
          attrs: {
            ...props,
            modalInstance() {
              return modal;
            },
          },
        }),
      },
    });

    return modal;
  }
}

export default new ModalService();
