<script setup lang="ts">
import { reactive, ref } from "vue";
import { useToast } from "vue-toastification";
import { cabinetFormRules } from "@/components/Cabinet/cabinet-form-rules";

const formRef = ref();

const toast = useToast();

const props = defineProps<{
  name?: string;
  submit: (name: string) => Promise<void>;
  cancel: () => void;
}>();

const validationRules = cabinetFormRules();

const state = reactive<{
  valid: boolean;
  loading: boolean;
}>({
  loading: false,
  valid: false,
});

const formState = reactive<{ name: string }>({
  name: props?.name || "",
});

async function runSubmit(): Promise<void> {
  await formRef.value?.validate();
  if (!state.valid) {
    return;
  }
  state.loading = true;
  try {
    await props.submit(formState.name);
    handleSuccessNotification();
  } catch (e) {
    handleErrorNotification();
    throw e;
  } finally {
    state.loading = false;
  }
}

function getModalHeaderText(): string {
  const actionText = getActionTypeText();

  if (props?.name) {
    return `${actionText} cabinet "${props?.name}"`;
  }

  return `${actionText} Cabinet`;
}

function getActionTypeText(): string {
  if (props?.name) {
    return "Edit";
  }
  return "Create";
}

function getSuccessActionTypeText(): string {
  if (props?.name) {
    return "edited";
  }
  return "created";
}

function getErrorActionTypeText(): string {
  if (props?.name) {
    return "editing";
  }
  return "creating";
}

const handleSuccessNotification = () => {
  const actionText = getSuccessActionTypeText();

  toast.success(`Cabinet was ${actionText}`);
};

const handleErrorNotification = () => {
  const actionText = getErrorActionTypeText();

  toast.error(`Something went wrong during cabinet ${actionText}`);
};
</script>
<template>
  <v-card style="min-height: 0; width: 600px">
    <v-card-title>
      {{ getModalHeaderText() }}
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-form ref="formRef" v-model="state.valid">
          <v-row>
            <v-col>
              <v-text-field
                :rules="validationRules.name"
                aria-autocomplete="none"
                v-model="formState.name"
                type="text"
                variant="underlined"
                label="Name"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions style="justify-content: flex-end">
      <v-btn style="margin-right: 8px" @click="props.cancel()">Cancel</v-btn>
      <v-btn @click="runSubmit()" color="primary" :disabled="state.loading">
        {{ getActionTypeText() }} {{ "cabinet" }}
        <v-progress-circular
          v-if="state.loading"
          indeterminate
        ></v-progress-circular>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped>
form {
  max-width: 600px;
  width: 100%;
  margin: var(--indent-m) auto 0;
}
</style>
