<script setup lang="ts">
import type { Cabinet } from "@/stores/types/cabinets-types";
import GetCabinet from "./Cabinet/Cabinet.vue";
import AssignExpandedPanel from "@/components/AssignExpandedPanel.vue";
import { reactive } from "vue";

const props = defineProps<{ cabinets: Cabinet[] }>();

const cabinetState = reactive<{
  cabinets: Cabinet[];
  search: string;
}>({
  get cabinets(): Cabinet[] {
    return props.cabinets.filter(({ name, idTUs }: Cabinet): boolean => {
      const searchValue = this.search.toLowerCase();
      return (
        name.toLowerCase().includes(searchValue) ||
        idTUs.some((id): boolean => id.toString().includes(searchValue))
      );
    });
  },
  search: "",
});
</script>
<template>
  <template v-if="props.cabinets.length">
    <v-text-field
      label="search by cabinet name/profile ID"
      placeholder="search by cabinet name/profile ID"
      v-model="cabinetState.search"
      class="profiles-search-input"
      hide-details="auto"
      type="text"
    >
    </v-text-field>
    <div
      :class="{
        'cabinet-list': true,
      }"
      v-for="cabinet in cabinetState.cabinets"
      :key="cabinet._id"
    >
      <get-cabinet :cabinet="cabinet"></get-cabinet>
      <v-divider />
      <assign-expanded-panel :source="cabinet"></assign-expanded-panel>
    </div>
  </template>
  <template v-else-if="!props.cabinets.length">
    <div class="empty-container__wrapper">
      <div class="empty-container__item">No available cabinets found</div>
    </div>
  </template>
</template>

<style scoped lang="scss">
.cabinet-list {
  min-height: 80px;
  display: flex;
  padding: var(--indent-m);
  border-radius: 6px;
  margin: 0 0 var(--indent-l);
  background: #fff;
  flex-direction: column;
  justify-content: center;
  row-gap: var(--indent-m);
  border: 1px solid #e8eaf5;
}
</style>
