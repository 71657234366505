<script setup lang="ts">
import type {
  Manager as ManagerType,
  Translator as TranslatorType,
} from "@/stores/types/users-types";
import type { Cabinet as CabinetType } from "@/stores/types/cabinets-types";

import OperatorsContainer from "@/components/Operators/OperatorsContainer.vue";
import { computed, reactive } from "vue";
import type { Operator } from "@/stores/types/users-types";
import { useUsersStore } from "@/stores/users-store";
import { useCabinetsStore } from "@/stores/cabinets-store";

const props = defineProps<{
  source: TranslatorType | CabinetType | ManagerType;
  structureModal?: boolean;
}>();

const combinedOperators = computed(() =>
  getOperatorsIds()
    .map((id: number) => usersStore.getOperatorByOriginalId(id) as Operator)
    .filter((item) => !!item)
    .map((operator) => ({
      idTU: operator?.idTU,
      name: operator?.name,
    }))
);

const getOperatorsIds = (): number[] => {
  const { role } = props.source as TranslatorType;

  if (role === "translator") {
    if ((props.source as TranslatorType)?.childrenTU) {
      return (props.source as TranslatorType).childrenTU;
    }
  } else if (role === "manager") {
    return (props.source as ManagerType).childrenTU;
  }

  return (props.source as CabinetType).idTUs;
};

const isProfileCabinetMode = (): boolean => {
  const { _id, role } = props.source as TranslatorType;

  if (role === "translator") {
    const cabinet = useCabinetsStore().getCabinetsByOwnerId(_id);

    if (cabinet.length) {
      return true;
    }
  }

  return false;
};

const getLabel = () => {
  return (
    (props.source as TranslatorType)?.label ||
    (props.source as CabinetType)?.name ||
    ""
  );
};

const usersStore = useUsersStore();

const assignOperatorState = reactive<{
  operatorsIds: number[];
  search: string;
}>({
  get operatorsIds(): number[] {
    return combinedOperators.value
      .filter(({ idTU, name }): boolean => {
        const searchedValue = this.search.toLowerCase();
        return (
          name.toLowerCase().includes(searchedValue) ||
          idTU.toString().toLowerCase().includes(searchedValue)
        );
      })
      .map(({ idTU }) => idTU);
  },
  search: "",
});
</script>

<template>
  <div>
    <v-text-field
      label="search by ID/Name"
      placeholder="search by ID/Name"
      hide-details="auto"
      v-model="assignOperatorState.search"
      type="text"
    >
    </v-text-field>
    <operators-container
      :search="true"
      :cabinet-mode="isProfileCabinetMode()"
      :parent-label="getLabel()"
      :parent-id="source._id"
      :operator-ids="assignOperatorState.operatorsIds"
      :structure-modal="props.structureModal"
    ></operators-container>
  </div>
</template>

<style scoped lang="scss"></style>
