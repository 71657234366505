<script lang="ts" setup>
import { useAddProfiles } from "@/components/Operators/add-operators";
import AddOperatorList from "@/components/Operators/AddOperatorList.vue";
import { useUsersStore } from "@/stores/users-store";
import { reactive } from "vue";
import { useCabinetsStore } from "@/stores/cabinets-store";
import type { Operator } from "@/stores/types/users-types";
import { useUserProfileStore } from "@/stores/user-profile-store";

const props = withDefaults(
  defineProps<{
    parentId?: string;
    cabinetId?: string;
    close: () => void;
  }>(),
  {
    parentId: "",
    cabinetId: "",
  }
);

const usersStore = useUsersStore();

const {
  tab,
  addProfilesState,
  openAddProfilesModal,
  hideAddProfilesModal,
  addProfiles,
  onOperatorChanged,
} = useAddProfiles();

const loadMoreProfile = reactive<{ state: boolean }>({
  state: false,
});
const isAdminRole = useUserProfileStore().isAdminRole;
let timer: number | undefined;
async function searchTU() {
  if (isAdminRole) {
    clearTimeout(timer);
    timer = setTimeout(async () => {
      usersStore.operators.unassigned = [];
      loadMoreProfile.state = true;
      await usersStore.loadUnassignedTUsWithNewSearch(
        addProfilesState.searchByIdTU,
        addProfilesState.searchByNameTU
      );
      refreshOperatorList();
      loadMoreProfile.state = false;
    }, 300);
  }
}

async function scrollEndInAddOperator(): Promise<void> {
  if (loadMoreProfile.state || usersStore.allTULoaded || !isAdminRole) return;
  loadMoreProfile.state = true;
  await usersStore.loadAdditionalUnassignedTUs(
    addProfilesState.searchByIdTU,
    addProfilesState.searchByNameTU
  );
  refreshOperatorList();
  loadMoreProfile.state = false;
  addProfilesState.state = true;
}

function refreshOperatorList(): void {
  const originalSource = props.parentId
    ? useUsersStore().getUserById(props.parentId)?.childrenTU
    : useCabinetsStore().getCabinetById(props.cabinetId)?.idTUs;

  if (!originalSource) return;

  addProfilesState.allOperators = [...useUsersStore().allOperators].filter(
    (item) => !originalSource.includes(item.idTU)
  ) as Operator[];
}

function goToHideModal(): void {
  if (
    addProfilesState.searchByIdTU !== "" ||
    addProfilesState.searchByNameTU !== ""
  ) {
    addProfilesState.searchByIdTU = "";
    addProfilesState.searchByNameTU = "";
    searchTU();
  }
  hideAddProfilesModal();
  props.close();
}

openAddProfilesModal(props.parentId, props.cabinetId);
</script>

<template>
  <v-card title="ADD PROFILE">
    <v-card-content>
      <v-text-field
        v-if="tab.state === 'external'"
        label="search by ID/Name"
        placeholder="search by ID/Name"
        v-model="addProfilesState.search"
        hide-details="auto"
        type="text"
      >
      </v-text-field>
      <div v-if="tab.state === 'unassigned'" class="unassigned-search">
        <v-text-field
          label="search by ID"
          placeholder="search by ID"
          v-model="addProfilesState.searchByIdTU"
          @input="searchTU"
          class="profiles-search-input"
          hide-details="auto"
          type="text"
        >
        </v-text-field>
        <div class="divider"></div>
        <v-text-field
          label="search by name"
          placeholder="search by name"
          v-model="addProfilesState.searchByNameTU"
          @input="searchTU"
          class="profiles-search-input"
          hide-details="auto"
          type="text"
        >
        </v-text-field>
      </div>
      <v-tabs v-model="tab.state">
        <v-tab value="unassigned">Unassigned profiles</v-tab>
        <v-tab value="external"
          >Unassigned external profiles
          <v-icon
            size="20px"
            class="external-icon"
            color="primary"
            icon="mdi-cloud-upload"
          ></v-icon>
        </v-tab>
      </v-tabs>
      <v-window v-model="tab.state">
        <v-window-item value="unassigned">
          <AddOperatorList
            v-if="!loadMoreProfile.state"
            :operator-ids="addProfilesState.operatorsIds"
            @operator-changed="onOperatorChanged"
            @scroll-end="scrollEndInAddOperator"
          ></AddOperatorList>
          <v-progress-linear
            v-if="loadMoreProfile.state"
            class="mt-3"
            color="deep-purple accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-window-item>
        <v-window-item value="external">
          <AddOperatorList
            :operator-ids="addProfilesState.externalOperatorsIds"
            @operator-changed="onOperatorChanged"
          ></AddOperatorList>
        </v-window-item>
      </v-window>
    </v-card-content>
    <v-card-actions style="justify-content: flex-end">
      <v-btn @click="goToHideModal()">Cancel</v-btn>
      <v-btn
        color="primary"
        :disabled="!addProfilesState.isAddButtonEnabled"
        @click="
          addProfiles(props.parentId, props.cabinetId).then(() => props.close())
        "
      >
        add {{ addProfilesState.selectedOperatorsCount }} profiles
        <v-progress-circular
          v-if="addProfilesState.loading"
          indeterminate
        ></v-progress-circular>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<style scoped lang="scss">
.external-icon {
  margin-left: 5px;
}
.unassigned-search {
  display: flex;
}
.divider {
  margin: 0 5px 0 5px;
}
</style>
