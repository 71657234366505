<script setup lang="ts">
import AssignOperatorList from "@/components/AssignOperatorList.vue";
import type { Cabinet } from "@/stores/types/cabinets-types";

const props = defineProps<{
  cabinet: Cabinet;
  structureModal: boolean;
  close: () => void;
}>();

function closeModal() {
  props.close();
}
</script>
<script lang="ts">
export default {};
</script>

<template>
  <v-card class="body">
    <assign-operator-list
      :source="props.cabinet"
      :structure-modal="props.structureModal"
    ></assign-operator-list>
    <v-card-actions style="justify-content: flex-end">
      <v-btn @click="closeModal()">Close</v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped lang="scss">
.body {
  width: 600px;
  max-height: 80vh;
  overflow-y: auto;
}
</style>
