import { Http } from "@/core/api";
import type { UserFormState } from "@/components/UserForm/user-form-types";
import type {
  ResponseUser,
  UpdateUser,
  Operator,
} from "@/stores/types/users-types";

class ApiManager {
  getManagers(): Promise<ResponseUser[]> {
    return Http.get<ResponseUser[]>("/manager/all");
  }

  createManager(
    data: UserFormState
  ): Promise<{ _id: string; idParent: string }> {
    return Http.post<{ _id: string; idParent: string }>(
      "/manager/create",
      data
    );
  }

  updateManager(id: string, data: UpdateUser): Promise<boolean> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { email, ...dataWithoutEmail } = data;
    return Http.post<boolean>("/manager/update", {
      id,
      ...dataWithoutEmail,
    });
  }

  deleteManager(id: string): Promise<boolean> {
    return Http.post<boolean>("/manager/remove", { id });
  }

  getUnassignedManagerTU(): Promise<Operator[]> {
    return Http.get("manager/tu/unassigned/all");
  }

  getAssignManagerTU(): Promise<Operator[]> {
    return Http.get("manager/tu/assigned/all");
  }

  getExternalManagerTU(): Promise<Operator[]> {
    return Http.get("manager/tu/external/all");
  }
}

export default new ApiManager();
