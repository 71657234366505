<script setup lang="ts">
import { useAuthStore } from "@/stores/auth-store";
import { useRouter } from "vue-router";
import { reactive } from "vue";
import { useToast } from "vue-toastification";

const formState = reactive<{
  email: string;
  pass: string;
  showPass: boolean;
  valid: boolean;
}>({
  email: "",
  pass: "",
  showPass: false,
  valid: false,
});

const toast = useToast();

const router = useRouter();

async function login() {
  if (!formState.valid) return;
  useAuthStore().initAuthState();
  try {
    await useAuthStore().auth({
      email: formState.email,
      pass: formState.pass,
    });
    await router.push({ path: "/structure" });
  } catch (e: any) {
    if (e.response?.data?.message) {
      toast.error(e.response?.data?.message);
    } else {
      toast.error("Something went wrong");
    }
  }
}
</script>

<template>
  <div class="loader-wrapper" v-if="useAuthStore().isLoading">
    <v-progress-circular size="50" :width="5" color="primary" indeterminate />
  </div>
  <div class="d-flex align-center justify-center" style="margin-top: 80px">
    <v-sheet width="400" class="mx-auto">
      <v-form fast-fail @submit.prevent="login" v-model="formState.valid">
        <v-text-field
          :rules="[
            (v) => !!v.trim() || 'Email is required',
            (v) => /.+@.+/.test(v) || 'Not Valid Email',
          ]"
          v-model="formState.email"
          label="Email"
        ></v-text-field>
        <v-text-field
          :append-icon="formState.showPass ? 'mdi-eye' : 'mdi-eye-off'"
          :type="formState.showPass ? 'text' : 'password'"
          v-model="formState.pass"
          label="Password"
          :rules="[(v) => !!v.trim() || 'Password is required']"
          @click:append="formState.showPass = !formState.showPass"
        ></v-text-field>
        <v-btn type="submit" color="primary" block class="mt-2">Sign in</v-btn>
      </v-form>
    </v-sheet>
  </div>
</template>

<style scoped lang="scss"></style>
