<script setup lang="ts">
import { defineProps, reactive } from "vue";
import type { Manager } from "@/stores/types/users-types";
import { useUsersStore } from "@/stores/users-store";
import ModalService from "@/core/services/ModalService";
import UserForm from "@/components/UserForm/UserForm.vue";
import type { UserRole } from "@/stores/types/users-types";
import type { UserFormState } from "@/components/UserForm/user-form-types";
import { useEditUser } from "@/components/UserForm/edit-user";
import type { UseModalReturnType } from "vue-final-modal";
import CreateRefCode from "@/components/RefCode/CreateRefCode.vue";
import DeleteUnit from "@/components/Operators/DeleteUnit.vue";
import { useDeleteUser } from "@/components/UserForm/delete-user";
import AddOperators from "@/components/Operators/AddOperators.vue";

const usersStore = useUsersStore();

const { openEditModal, updateManager } = useEditUser<Manager>();

const { deleteState, deleteManager, openDeleteModal } =
  useDeleteUser<Manager>();

const props = defineProps<{
  idManager: string;
}>();

const state = reactive<{
  name: string;
  manager: Manager;
}>({
  name: "",
  get manager(): Manager {
    return usersStore.getManagerById(props.idManager);
  },
});

const availableAction = () => {
  return [
    { label: "Actions", action: "action" },
    { label: "Assign profiles", action: "assign" },
    { label: "Edit", action: "edit" },
    { label: "Create referral code", action: "refcode" },
    { label: "Delete", action: "delete" },
  ];
};

let modal: UseModalReturnType<any>;

function selectAction(event: string) {
  switch (event) {
    case "assign":
      modal = ModalService.init(AddOperators, {
        style: "width: 700px",
        parentId: props.idManager,
        close() {
          modal.close();
        },
      });
      modal.open();
      return;
    case "edit":
      openEditModal(state.manager);
      modal = ModalService.init(UserForm, {
        isEdit: true,
        role: "manager" as UserRole,
        state: state.manager,
        cancelEdit() {
          modal.close();
        },
        async submit(data: UserFormState): Promise<void> {
          await updateManager(data).then(() => modal.close());
        },
      });
      modal.open();
      return;
    case "refcode":
      modal = ModalService.init(CreateRefCode, {
        style: "width: 600px",
        user: state.manager,
        close() {
          modal.close();
        },
      });
      modal.open();
      return;
    case "delete":
      openDeleteModal(state.manager);
      modal = ModalService.init(DeleteUnit, {
        deleteStateUser: deleteState,
        hideDeleteModal() {
          modal.close();
        },
        async deleteUnit() {
          deleteManager().then(() => modal.close());
        },
      });
      modal.open();
      return;
    default:
      return;
  }
}
</script>
<script lang="ts"></script>

<template>
  <div class="manager-info">
    <div class="manager__title">
      <h3 class="manager-prop text-h6">Manager</h3>
      {{ state?.manager?.firstName }} {{ state?.manager?.lastName }}
      <span></span>
    </div>
    <div class="info">
      <div>
        <span class="info-label">Profiles:</span>
        {{ state?.manager.childrenTU.length }}
      </div>
    </div>
    <div>
      <v-select
        v-model="availableAction()[0]"
        item-title="label"
        item-value="action"
        :items="availableAction()"
        variant="outlined"
        density="compact"
        hide-details
        hide-selected
        @update:model-value="selectAction($event)"
      >
      </v-select>
    </div>
  </div>
</template>

<style scoped lang="scss">
.manager {
  min-height: 80px;
  display: flex;
  padding: var(--indent-m);
  border-radius: 6px;
  margin: 0 0 var(--indent-l);
  background: #fff;
  flex-direction: column;
  justify-content: center;
  row-gap: var(--indent-m);
  border: 1px solid #e8eaf5;

  &__title {
    display: flex;
    align-items: baseline;
    h3 {
      margin-right: 10px;
    }
  }

  .manager-info {
    display: flex;
    align-items: center;
    column-gap: var(--indent-m);

    .actions {
      margin-inline-start: var(--indent-xl);
      display: flex;
      column-gap: var(--indent-m);
    }
  }

  .info {
    margin-inline-start: auto;
    display: flex;
    column-gap: var(--indent-m);
  }

  .info-label {
    color: #b3b3b3;
  }
}
</style>
