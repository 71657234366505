<script setup lang="ts">
import PageContent from "@/components/PageContent.vue";
import OperatorsSearch from "@/components/Operators/OperatorsSearch.vue";
import { useUsersStore } from "@/stores/users-store";
import { useCabinetsStore } from "@/stores/cabinets-store";
import { useWorkShiftStore } from "@/stores/work-shift-store";
import {
  STRUCTURE_ADMIN_CONTAINER_HEADER,
  STRUCTURE_MANAGER_CONTAINER_HEADER,
} from "@/shared/constants/table-header";
import type { Manager, Translator } from "@/stores/types/users-types";
import type { Cabinet } from "@/stores/types/cabinets-types";
import type { WorkShift } from "@/stores/types/work-shift-types";
import { reactive } from "vue";
import { useUserProfileStore } from "@/stores/user-profile-store";
import ModalService from "@/core/services/ModalService";
import AreYouSure from "@/components/AreYouSureModal/AreYouSure.vue";
import type { UseModalReturnType } from "vue-final-modal";
import AnalystService from "@/core/services/AnalystService";
import { WORK_SHIFTS_ERROR_ACTION } from "@/core/enums/amplitude-event.enum";
import type {
  WorkShiftCrossing,
  WorkShiftCrossingRes,
} from "@/stores/types/work-shift-types";
import CrossingOperators from "@/components/CrossingOperators/CrossingOperators.vue";
import { useToast } from "vue-toastification";
import modalService from "@/core/services/ModalService";
import AssignedProfilesModal from "@/components/AssignedProfilesModal/AssignedProfilesModal.vue";

const usersStore = useUsersStore();

const cabinetsStore = useCabinetsStore();

const workShiftStore = useWorkShiftStore();

const isAdminRole = useUserProfileStore().isAdminRole;

usersStore.index();
cabinetsStore.indexCabinets();
workShiftStore.indexWorkShifts();

const headers = isAdminRole
  ? STRUCTURE_ADMIN_CONTAINER_HEADER
  : STRUCTURE_MANAGER_CONTAINER_HEADER;

const structureState = reactive<{
  structure: {
    manager?: Manager;
    translator?: Translator;
    cabinet?: Cabinet;
    workShift?: WorkShift;
  }[];
  sort: {
    field1: string;
    field2: string;
    flow: "AtoZ" | "ZtoA";
  };
  search: { group: string; value: string };
}>({
  get structure() {
    const structure: {
      manager?: Manager;
      translator?: Translator;
      cabinet?: Cabinet;
      workShift?: WorkShift;
    }[] = [];
    usersStore.allTranslators.forEach((translator) => {
      const translatorCabinets = cabinetsStore.getCabinetsByOwnerId(
        translator._id
      );
      if (translatorCabinets.length === 0) {
        structure.push({ translator: translator as Translator });
      } else {
        translatorCabinets.forEach((cabinet) => {
          structure.push({
            translator: translator as Translator,
            cabinet: cabinet,
          });
        });
      }
    });
    structure.forEach((item) => {
      const workShift = workShiftStore.getWorkShiftOwnerId(
        item.translator!._id
      );
      if (workShift) {
        item.workShift = workShift;
      }
      item.manager = usersStore.getManagerById(item.translator!.idParent);
      if (!item.manager._id) {
        item.manager.label = "Not found";
      }
    });
    usersStore.managersTree.forEach((manager) => {
      if (manager.translators.length < 1) {
        structure.push({
          manager: manager,
        });
      }
    });
    const sortedArr = sortByInnerField(
      structure,
      this.sort.field1,
      this.sort.field2,
      this.sort.flow
    );
    return filteringArr(sortedArr, this.search.group, this.search.value);
  },
  sort: { field1: "manager", field2: "label", flow: "AtoZ" },
  search: { group: "translator", value: "" },
});

function sortByInnerField(arr, field1, field2, flow = "AtoZ") {
  if (field1 === "profiles") field1 = "cabinet";
  const undefElements = arr.filter((elem) => elem[field1] === undefined);
  const availableElements = arr.filter((elem) => elem[field1] !== undefined);
  const sortArr = availableElements.sort((a, b) => {
    if (field2 == "count") {
      const aValue = a[field1].idTUs.length;
      const bValue = b[field1].idTUs.length;
      if (flow === "AtoZ") {
        if (aValue < bValue) return -1;
        if (aValue > bValue) return 1;
        return 0;
      } else {
        if (aValue > bValue) return -1;
        if (aValue < bValue) return 1;
        return 0;
      }
    } else {
      const aValue = a[field1][field2]?.toLowerCase();
      const bValue = b[field1][field2]?.toLowerCase();
      if (flow === "AtoZ") {
        if (aValue < bValue) return -1;
        if (aValue > bValue) return 1;
        return 0;
      } else {
        if (aValue > bValue) return -1;
        if (aValue < bValue) return 1;
        return 0;
      }
    }
  });
  return [...sortArr, ...undefElements];
}

function sortList(target: string) {
  switch (target) {
    case "manager":
    case "translator":
      if (
        structureState.sort.field1 === target &&
        structureState.sort.flow === "AtoZ"
      ) {
        structureState.sort = { field1: target, field2: "label", flow: "ZtoA" };
        return;
      }
      structureState.sort = { field1: target, field2: "label", flow: "AtoZ" };
      return;
    case "cabinet":
    case "workShift":
      if (
        structureState.sort.field1 === target &&
        structureState.sort.flow === "AtoZ"
      ) {
        structureState.sort = { field1: target, field2: "name", flow: "ZtoA" };
        return;
      }
      structureState.sort = { field1: target, field2: "name", flow: "AtoZ" };
      return;
    case "profiles":
      if (
        structureState.sort.field2 === "count" &&
        structureState.sort.flow === "AtoZ"
      ) {
        structureState.sort = { field1: target, field2: "count", flow: "ZtoA" };
        return;
      }
      structureState.sort = { field1: target, field2: "count", flow: "AtoZ" };
      return;
    default:
      return;
  }
}

function filteringArr(
  arr: {
    manager?: Manager;
    translator?: Translator;
    cabinet?: Cabinet;
    workShift?: WorkShift;
  }[],
  group: string,
  value: string
) {
  if (value.length < 1) {
    return arr;
  }
  const searchValue = value.toLowerCase();
  if (group === "profile") {
    const availableElements = arr.filter(
      (element) => element.cabinet !== undefined
    );
    return availableElements.filter(({ cabinet }) =>
      cabinet!.idTUs.some((id) => id.toString().includes(searchValue))
    );
  }
  const availableElements = arr.filter(
    (element) => element[group] !== undefined
  );
  if (group === "cabinet" || group === "workShift") {
    return availableElements.filter((item) =>
      item[group]!.name.toLowerCase().includes(searchValue)
    );
  }
  return availableElements.filter(
    (item) =>
      item[group]!.firstName.toLowerCase().includes(searchValue) ||
      item[group]!.lastName.toLowerCase().includes(searchValue)
  );
}

const toast = useToast();

let modal: UseModalReturnType<any>;

function isCoreDataLoading(): boolean {
  return workShiftStore.loading || cabinetsStore.loading || usersStore.loading;
}

async function changeWs(
  workShift: WorkShift,
  translator: Translator,
  reassign: boolean
) {
  const workShiftName = workShift.name;
  const translatorLabel = translator.label;
  let text: string;
  let buttonLabel: string;
  if (reassign) {
    buttonLabel = "Change";
    text = `Are you sure to change the work shift for ${translatorLabel} to "${workShiftName}"?`;
  } else {
    text = `Are you sure to assign work shift "${workShiftName}" to ${translatorLabel}?`;
    buttonLabel = "Assign";
  }
  modal = ModalService.init(AreYouSure, {
    buttonLabel,
    text,
    close() {
      modal.close();
    },
    submit() {
      workShiftStore
        .assignWorkShift(translator._id, workShift._id, reassign)
        .then(() => {
          toast.success("Work shift was assigned");
          modal.close();
        })
        .catch((e) => {
          const { crossingOperators } = e as WorkShiftCrossingRes;
          if (crossingOperators) {
            const { crossingOperators } = e as WorkShiftCrossingRes;
            if (crossingOperators) {
              AnalystService.pushWorkShiftErrorEvent({
                action: WORK_SHIFTS_ERROR_ACTION.ASSIGN,
              });
              return catchCrossingOperator(crossingOperators);
            }
            toast.error("Something went wrong during assigning work shift");
            modal.close();
          }
        });
    },
  });
  await modal.open();
}
function catchCrossingOperator(crossingOperators: WorkShiftCrossing[]) {
  const crossingOperatorModal = ModalService.init(CrossingOperators, {
    isAssign: true,
    data: crossingOperators,
    cancel() {
      crossingOperatorModal.close();
      modal.close();
    },
  });
  crossingOperatorModal.open();
}

async function changeCabinet(
  translator: Translator,
  previousCabinet: Cabinet | undefined,
  cabinet: Cabinet
) {
  let buttonLabel: string;
  let text: string;
  if (previousCabinet) {
    buttonLabel = "Change";
    text = `Are you sure to change the cabinet for ${translator.label} to "${cabinet.name}"?`;
  } else {
    buttonLabel = "Assign";
    text = `Are you sure to assign cabinet "${cabinet.name}" to ${translator.label}?`;
  }
  modal = modalService.init(AreYouSure, {
    buttonLabel,
    text,
    close() {
      modal.close();
    },
    submit() {
      cabinetsStore
        .assignCabinet(translator._id, cabinet._id)
        .then(async () => {
          toast.success("New cabinet was assigned");
          if (previousCabinet) {
            await cabinetsStore.unAssignCabinet(
              translator._id,
              previousCabinet._id
            );
          }
        })
        .then(() => {
          if (previousCabinet) {
            toast.success("Old cabinet was unassigned");
          }
          modal.close();
        });
    },
  });
  await modal.open();
}

async function changeManager(translator: Translator, manager: Manager) {
  const buttonLabel = "Change";
  const text = `Are you sure to change the manager for ${translator.label} to ${manager.label}?`;
  modal = ModalService.init(AreYouSure, {
    buttonLabel,
    text,
    close() {
      modal.close();
    },
    submit() {
      const data = {
        firstName: translator.firstName,
        lastName: translator.lastName,
        id: translator._id,
        idParent: manager._id,
        pass: "",
      };
      usersStore.updateTranslator(translator, data).then(() => {
        toast.success("Manager was changed");
        modal.close();
        usersStore.indexTranslators();
      });
    },
  });
  await modal.open();
}

function notAssignedTranslators(manager: Manager): Translator[] {
  return usersStore.allTranslators.filter(
    (translator) => translator.idParent !== manager._id
  );
}

function openProfileModal(cabinet: Cabinet): void {
  modal = ModalService.init(AssignedProfilesModal, {
    cabinet: cabinet,
    structureModal: true,
    close() {
      modal.close();
    },
  });
  modal.open();
}

function notAssignedCabinets(
  idTranslator: Translator["_id"],
  idManager: Manager["_id"] = useUserProfileStore().userId
) {
  const notAssignedCabinet = cabinetsStore.all.filter(
    (cabinet) => !cabinet.idOperators.includes(idTranslator)
  );
  return notAssignedCabinet.filter(
    (cabinet) =>
      cabinet.idCreator === idManager ||
      cabinet.idCreator === useUserProfileStore().userId
  );
}
</script>

<template>
  <page-content class="content">
    <h2 v-if="isAdminRole">
      {{ useUserProfileStore().firstName }} {{ useUserProfileStore().lastName }}
    </h2>
    <h2 v-else>{{ usersStore.managersTree[0]?.label }}</h2>
  </page-content>
  <page-content class="content">
    <operators-search></operators-search>
  </page-content>
  <page-content class="content">
    <template v-if="isCoreDataLoading()">
      <div class="content__loader">
        <v-progress-circular
          size="50"
          :width="5"
          color="primary"
          indeterminate
        />
      </div>
    </template>
    <template v-else>
      <v-radio-group inline hide-details v-model="structureState.search.group">
        <v-radio
          v-if="isAdminRole"
          label="Manager's name"
          value="manager"
        ></v-radio>
        <v-radio label="Operator's name" value="translator"></v-radio>
        <v-radio label="Cabinet's name" value="cabinet"></v-radio>
        <v-radio label="Work shift's name" value="workShift"></v-radio>
        <v-radio label="ID Profile" value="profile"></v-radio>
      </v-radio-group>
      <v-text-field
        label="Search"
        class="profiles-search-input"
        hide-details="auto"
        type="text"
        v-model="structureState.search.value"
      >
      </v-text-field>
      <div class="extension-virtual-scroller">
        <div
          class="row row-header"
          :class="isAdminRole ? 'row-admin' : 'row-manager'"
        >
          <div
            v-for="header in headers"
            :key="header.display"
            :class="['cell', header.class, 'cursor']"
            @click="sortList(header.class)"
          >
            {{ header.display }}
            <template v-if="structureState.sort.field1 === header.class">
              <v-icon
                v-if="structureState.sort.flow === 'AtoZ'"
                size="20px"
                color="primary"
                icon="mdi-sort-alphabetical-ascending"
              ></v-icon>
              <v-icon
                v-else
                size="20px"
                color="primary"
                icon="mdi-sort-alphabetical-descending"
              ></v-icon>
            </template>
          </div>
        </div>
        <template
          v-for="(structureElement, index) in structureState.structure"
          :key="index"
        >
          <div
            class="row row-body"
            :class="isAdminRole ? 'row-admin' : 'row-manager'"
          >
            <div class="cell" v-if="isAdminRole">
              <v-select
                :model-value="structureElement.manager"
                item-title="label"
                item-value="_id"
                :items="usersStore.managersTree"
                return-object
                hide-selected
                hide-details
                :readonly="!structureElement.translator"
                @update:model-value="
                  changeManager(structureElement.translator!, $event)
                "
              >
              </v-select>
              <v-tooltip
                v-if="!structureElement.translator"
                activator="parent"
                location="end"
              >
                Manager have no operator
              </v-tooltip>
            </div>
            <div class="cell">
              <v-select
                :model-value="structureElement.translator"
                item-title="label"
                item-value="_id"
                :items="notAssignedTranslators(structureElement.manager!)"
                return-object
                hide-details
                hide-selected
                :readonly="!structureElement.manager?._id"
                @update:model-value="
                  changeManager($event, structureElement.manager!)
                "
              >
              </v-select>
              <v-tooltip
                v-if="!structureElement.manager?._id"
                activator="parent"
                location="end"
              >
                Select manager first
              </v-tooltip>
            </div>
            <div class="cell">
              <template v-if="structureElement.cabinet?.idTUs.length">
                <v-btn
                  append-icon="mdi-notebook-outline"
                  variant="outlined"
                  @click="openProfileModal(structureElement.cabinet!)"
                >
                  <span>{{ structureElement.cabinet?.idTUs.length }}</span>
                </v-btn>
              </template>
            </div>
            <div class="cell">
              <template
                v-if="structureElement.translator && cabinetsStore.all.length"
              >
                <v-select
                  :model-value="structureElement.cabinet"
                  item-title="name"
                  item-value="_id"
                  :items="notAssignedCabinets(structureElement.translator!._id, structureElement.manager?._id)"
                  return-object
                  hide-details
                  hide-selected
                  @update:model-value="
                    changeCabinet(
                      structureElement.translator!,
                      structureElement.cabinet,
                      $event
                    )
                  "
                >
                </v-select>
              </template>
            </div>
            <div class="cell">
              <template
                v-if="structureElement.cabinet && workShiftStore.all.length"
              >
                <v-select
                  v-if="structureElement.workShift"
                  :model-value="structureElement.workShift"
                  item-title="name"
                  item-value="_id"
                  :items="workShiftStore.all"
                  @update:model-value="
                    changeWs($event, structureElement.translator!, true)
                  "
                  return-object
                  hide-details
                  hide-selected
                >
                </v-select>
                <v-select
                  v-else
                  :model-value="structureElement.workShift"
                  item-title="name"
                  item-value="_id"
                  :items="workShiftStore.all"
                  @update:model-value="
                    changeWs($event, structureElement.translator!, false)
                  "
                  return-object
                  hide-details
                  hide-selected
                >
                </v-select>
              </template>
            </div>
          </div>
        </template>
      </div>
    </template>
  </page-content>
</template>

<style scoped lang="scss">
.content {
  background-color: transparent;
  padding: 0;
  margin-top: 16px;
  min-height: auto;

  &__loader {
    display: flex;
    justify-content: center;
  }
}
.external-icon {
  margin-left: 5px;
}

.row-admin {
  grid-template-columns: 2fr 2fr 0.4fr 0.75fr 0.75fr;
}

.row-manager {
  grid-template-columns: 2fr 0.4fr 0.75fr 0.75fr;
}

.row-header {
  justify-items: center;
  font-weight: 600;
  font-size: 18px;

  .cell {
    width: 100%;
    justify-content: center;
  }
}

.row-body .cell {
  padding: 0 2px;
  justify-content: center;
}

.cursor {
  cursor: pointer;
}

.add-external-profile {
  display: flex;
  justify-content: flex-end;
  padding: var(--indent-m) var(--indent-xl);
}
</style>
