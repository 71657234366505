<script setup lang="ts">
import { computed, reactive, ref } from "vue";
import { useUsersStore } from "@/stores/users-store";
import type { UserRole } from "@/stores/types/users-types";
import { userFormRules } from "@/components/UserForm/user-form-rules";
import ComboBox from "@/components/ComboBox/ComboBox.vue";
import type {
  UserFormState,
  SubmitCallback,
} from "@/components/UserForm/user-form-types";
import { getServerErrorMessage, type ServerError } from "@/core";
import type { AxiosError } from "axios";
import { DISPLAY_ROLE_NAME } from "@/core/enums/display-role.enum";
import { useToast } from "vue-toastification";
import { getCreateUpdateUserErrorMessage } from "@/core";
import { RESPONSE_ERROR_TYPE } from "@/core/enums/response-error-type";
import { useUserProfileStore } from "@/stores/user-profile-store";

const usersStore = useUsersStore();
const formRef = ref();
const toast = useToast();
const props = defineProps<{
  role: UserRole;
  state?: Omit<UserFormState, "pass">;
  submit: SubmitCallback;
  cancelEdit: () => void;
  openRefCodeModal?: () => void;
  isEdit?: true;
}>();
const state = reactive<{
  valid: boolean;
  loading: boolean;
  showPass: boolean;
}>({
  loading: false,
  valid: false,
  showPass: false,
});
const formState = reactive<UserFormState>({
  email: props.state?.email || "",
  pass: "",
  firstName: props.state?.firstName || "",
  lastName: props.state?.lastName || "",
  idParent: props.state?.idParent || "",
});
const isTranslator = computed(() => props.role === "translator");
const rules = userFormRules({
  isTranslator: isTranslator.value,
  email: props.state?.email,
  isEdit: props.isEdit || false,
});
const isAdminRole = useUserProfileStore().isAdminRole;

async function runSubmit(): Promise<void> {
  await formRef.value.validate();
  if (!state.valid) {
    return;
  }
  state.loading = true;
  try {
    await props.submit(formState);
    handleSuccessNotification();
  } catch (e) {
    const message = getCreateUpdateUserErrorMessage(
      getDisplayRoleName(),
      e as AxiosError<ServerError>
    );
    toast.error(message);
    if (
      getServerErrorMessage(e as AxiosError<ServerError>) ===
      RESPONSE_ERROR_TYPE.REFERRAL_CODE
    ) {
      if (props.openRefCodeModal) {
        props.openRefCodeModal();
      }
    }
  } finally {
    state.loading = false;
  }
}

const togglePasswordIcon = (): void => {
  state.showPass = !state.showPass;
};

const setPasswordType = (): string => {
  return state.showPass ? "text" : "password";
};

const setPasswordIcon = (): string => {
  return state.showPass ? "mdi-eye" : "mdi-eye-off";
};

const getDisplayRoleName = (): DISPLAY_ROLE_NAME => {
  return props.role === "translator"
    ? DISPLAY_ROLE_NAME.TRANSLATOR
    : DISPLAY_ROLE_NAME.MANAGER;
};

const handleSuccessNotification = () => {
  const actionText = props.isEdit ? "updated" : "created";
  toast.success(`${getDisplayRoleName()} was ${actionText}`);
};
</script>
<template>
  <v-card style="min-height: 0; width: 600px">
    <v-card-title
      >{{ props.isEdit ? "Update" : "Create" }}
      {{ getDisplayRoleName() }}</v-card-title
    >
    <v-card-text>
      <v-container>
        <v-form ref="formRef" v-model="state.valid">
          <v-row>
            <v-col>
              <v-text-field
                :disabled="isEdit"
                :rules="rules.email"
                aria-autocomplete="none"
                v-model="formState.email"
                type="email"
                variant="underlined"
                label="Email"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                aria-autocomplete="none"
                v-model="formState.pass"
                label="Password"
                :rules="rules.password"
                :append-icon="setPasswordIcon()"
                :type="setPasswordType()"
                @click:append="togglePasswordIcon()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="formState.firstName"
                :rules="rules.firstName"
                variant="underlined"
                label="First Name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="formState.lastName"
                :rules="rules.lastName"
                variant="underlined"
                label="Last Name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="isTranslator && isAdminRole">
            <v-col>
              <combo-box
                label="Select Manager"
                placeholder="Select Manager"
                :items="usersStore.managers"
                :rules="rules.idParent"
                bind-label="label"
                bind-value="_id"
                item-value="_id"
                v-model="formState.idParent"
              ></combo-box>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions style="justify-content: flex-end">
      <v-btn style="margin-right: 8px" @click="props.cancelEdit()"
        >Cancel</v-btn
      >
      <v-btn @click="runSubmit()" color="primary"
        >{{ props.isEdit ? "Update" : "Create" }} {{ getDisplayRoleName() }}
        <v-progress-circular
          v-if="state.loading"
          indeterminate
        ></v-progress-circular>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped>
form {
  max-width: 600px;
  width: 100%;
  margin: var(--indent-m) auto 0;
}
</style>
