<script setup lang="ts">
import { useCreateRefCode } from "@/components/RefCode/create-ref-code";
import type { Manager, Translator } from "@/stores/types/users-types";
import { defineProps } from "vue";

const props = defineProps<{
  user: Manager | Translator;
  close: () => void;
}>();

const {
  createRefCodeState,
  hideCreateRefCodeModal,
  createRefCode,
  openCreateRefCodeModalModal,
} = useCreateRefCode<Manager | Translator>();

function closeModal() {
  hideCreateRefCodeModal();
  props.close();
}
openCreateRefCodeModalModal(props.user);
</script>

<template>
  <v-card
    title="Do you want to create a new referral code?"
    :subtitle="'The old one will be deactivated'"
  >
    <v-card-actions style="justify-content: flex-end">
      <v-btn @click="closeModal()">Cancel</v-btn>
      <v-btn @click="createRefCode().then(() => props.close())" color="info"
        >Create
        <v-progress-circular
          v-if="createRefCodeState.loading"
          indeterminate
        ></v-progress-circular>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped lang="scss"></style>
