<script lang="ts" setup>
import { useAuthStore } from "@/stores/auth-store";
import { useAppStore } from "@/stores/app-store";
import { useCurrentPageStore } from "@/stores/current-page-store";
import Login from "@/pages/Login.vue";
import { ModalsContainer } from "vue-final-modal";

const authStore = useAuthStore();
const appStore = useAppStore();
const pageStore = useCurrentPageStore();

appStore.getCodeVersion();
</script>

<template>
  <div class="loader-wrapper" v-if="authStore.isLoading">
    <v-progress-circular size="50" :width="5" color="primary" indeterminate />
  </div>
  <div v-else>
    <v-toolbar :color="'primary'" class="toolbar-padding">
      <div class="sub-navigation" v-if="authStore.loggedIn">
        <v-btn
          :variant="pageStore.isStructurePage ? 'outlined' : 'text'"
          :to="'/structure'"
        >
          Structure
        </v-btn>
        <v-btn
          :variant="pageStore.isOperatorsPage ? 'outlined' : 'text'"
          :to="'/operators'"
        >
          Operators
        </v-btn>
      </div>
      <v-btn
        v-if="authStore.loggedIn"
        :variant="'outlined'"
        style="margin-right: 20px"
        @click="useAuthStore().logout()"
        :to="'/login'"
      >
        Log out
      </v-btn>
      <h1 class="text-h5" v-if="appStore.isDemoPointed">DEMO</h1>
    </v-toolbar>
    <router-view v-if="authStore.loggedIn"></router-view>
    <Login v-else />
  </div>
  <ModalsContainer />
</template>

<style lang="scss">
.loader-wrapper {
  display: flex;
  justify-content: center;
  padding: 30%;
}
.toolbar-padding {
  padding: 0 16px 0 16px;
}

.sub-navigation {
  display: flex;
  gap: 16px;
  width: 100%;
}

button.v-btn:focus {
  outline: none;
}
</style>
