<script lang="ts" setup>
import type { Operator } from "@/stores/types/users-types";
import { defineProps } from "vue";
import { useAppStore } from "@/stores/app-store";
import { TU_TYPE_ENUM } from "@/core/enums/tu-type.enum";

const props = defineProps<{
  item: Operator;
  isTuRemoved: (operator: Operator) => boolean;
  structureModal?: boolean;
}>();
const appStore = useAppStore();
</script>

<template>
  <div
    :class="{
      'row-body': true,
      row: true,
      'row-structure-modal': props.structureModal,
      removed: props.isTuRemoved(props.item),
    }"
    :key="props.item.idTU"
  >
    <div class="cell">
      {{ props.item.idTU }}
    </div>
    <div class="cell">
      <div class="avatar-wrapper">
        <v-avatar>
          <img :alt="props.item?.name" :src="props.item?.avatar" />
        </v-avatar>
        <v-icon
          v-if="props.item?.tuType === TU_TYPE_ENUM.EXTERNAL"
          size="20px"
          class="external-icon"
          color="primary"
          icon="mdi-cloud-upload"
        ></v-icon>
      </div>
    </div>
    <div class="cell">
      <v-btn
        class="custom-ellipsis"
        v-if="
          props.item?.tuType === TU_TYPE_ENUM.EXTERNAL ||
          appStore.isStandAloneMode
        "
        variant="text"
        style="text-transform: capitalize"
      >
        {{ props.item.name }}
      </v-btn>
      <v-btn
        class="custom-ellipsis"
        v-else
        style="text-transform: capitalize"
        :href="`/profile-trusted/${props.item.idTU}`"
        variant="text"
      >
        {{ props.item?.name }}
      </v-btn>
    </div>
    <div class="cell" v-if="!props.structureModal">
      {{ props.item?.age }}
    </div>
    <div class="cell" v-if="!props.structureModal">
      <div class="actions">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
::v-deep .custom-ellipsis {
  .v-btn__content {
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.actions {
  display: flex;
  column-gap: var(--indent-m);
}

.icon-more-horizontal {
  cursor: move;
}

.removed,
.removed:hover {
  background: #b00020 !important;
  color: white;
}

.avatar-wrapper {
  display: table;
  position: relative;
  .external-icon {
    position: absolute;
    bottom: -5px;
    right: -10px;
  }
}
.row-structure-modal {
  grid-template-columns: 1fr 1fr 1fr;
}
</style>
