export type LocalStorageGetOptions<T> = {
  key: string;
  defaultValue: T;
};
export type LocalStorageSetOptions = {
  key: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: { [key: string]: any };
};

export function localStorageGet<T>({
  key,
  defaultValue,
}: LocalStorageGetOptions<T>): T {
  const stringValue = localStorage.getItem(key);
  return stringValue ? JSON.parse(stringValue) : defaultValue;
}

export function setToLocalStorage({ key, value }: LocalStorageSetOptions) {
  localStorage.setItem(key, JSON.stringify(value));
}
