<script setup lang="ts">
import { reactive, ref } from "vue";
import { externalOperatorFormRules } from "@/components/Operators/external-operator-form-rules";
import { getCreateExternalErrorMessage } from "@/core";
import { useToast } from "vue-toastification";
import type { AxiosError } from "axios";
import type { BaseCredentials, ServerError } from "@/core";

const formRef = ref();

const toast = useToast();

const props = defineProps<{
  submit: (data: BaseCredentials) => Promise<void>;
  close: () => void;
}>();

const state = reactive<{
  valid: boolean;
  loading: boolean;
  showPass: boolean;
}>({
  loading: false,
  valid: false,
  showPass: false,
});

const formState = reactive<BaseCredentials>({
  email: "",
  pass: "",
});

const rules = externalOperatorFormRules();

async function onSubmit(): Promise<void> {
  await formRef.value.validate();
  if (!state.valid) {
    return;
  }
  state.loading = true;
  try {
    await props.submit(formState);
    toast.success("Profile was added");
  } catch (e) {
    const message = getCreateExternalErrorMessage(e as AxiosError<ServerError>);
    toast.error(message);
  } finally {
    state.loading = false;
  }
}

const togglePasswordIcon = (): void => {
  state.showPass = !state.showPass;
};

const setPasswordType = (): string => {
  return state.showPass ? "text" : "password";
};

const setPasswordIcon = (): string => {
  return state.showPass ? "mdi-eye" : "mdi-eye-off";
};
</script>

<template>
  <v-card style="min-height: 0; width: 600px">
    <v-card-title>Add profile</v-card-title>
    <v-card-text>
      <v-container>
        <v-form ref="formRef" v-model="state.valid">
          <v-row>
            <v-col>
              <v-text-field
                v-model="formState.email"
                :rules="rules.email"
                aria-autocomplete="none"
                type="email"
                variant="underlined"
                label="Email"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="formState.pass"
                :rules="rules.password"
                aria-autocomplete="none"
                label="Password"
                :append-icon="setPasswordIcon()"
                :type="setPasswordType()"
                @click:append="togglePasswordIcon()"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions style="justify-content: flex-end">
      <v-btn style="margin-right: 8px" @click="props.close()">Cancel</v-btn>
      <v-btn @click="onSubmit()" :disabled="state.loading" color="primary"
        >Add
        <v-progress-circular
          v-if="state.loading"
          indeterminate
        ></v-progress-circular>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped></style>
